import { Announcement } from '../Announcement';

export function AnnouncementShowcase() {
  return (
    <>
      <div className="showcase-label">Announcement</div>
      <div className="feedbackShowcase">
        <Announcement
          icon="star"
          message="Congratulations your application was approved for funding! Upload your contract and payment details to receive 50% payment."
        />
        <Announcement
          icon="star"
          hasButton={true}
          buttonText="Button Text"
          message="Congratulations your application was approved for funding! Upload your contract and payment details to receive 50% payment."
        />
      </div>
    </>
  );
}
