import { Button, ButtonProps } from './Button';

import styles from './Tab.module.scss';

export function Tab(props: Omit<ButtonProps, 'variant' | 'size'>) {
  return (
    <div className={styles.tab}>
      <Button
        buttonClassName={styles.button}
        disabledClassName={styles.buttonDisabled}
        {...props}
        variant="primary"
        size="small"
      />
    </div>
  );
}
