import { Block } from '../../../components/Block';
import { Breadcrumbs } from '../../../components/Breadcrumbs';
import { AccountCard } from '../../../components/Dashboard/AccountCard';
import { Loading } from '../../../components/Loading';
import { PageContainer } from '../../../components/PageContainer';
import { PagePagination } from '../../../components/PagePagination';
import { SortBy } from '../../../components/SortBy';
import { TextInput } from '../../../components/TextInput';
import { useWindowSize } from '../../../hooks/useWindowSize';
import {
  AccountsMachineSender,
  AccountsMachineState,
  SortMode,
} from '../../../machines/Admin/AccountsMachine';
import { ErrorPage } from '../../ErrorPage';

import styles from './Accounts.module.scss';

interface AccountsViewProps {
  state: AccountsMachineState;
  send: AccountsMachineSender;
}

const sortByOptions: Array<{ label: string; value: SortMode }> = [
  { label: 'Alphabetical (A-Z)', value: 'a-z' },
  {
    label: 'Alphabetical (Z-A)',
    value: 'z-a',
  },
  { label: 'Latest Modified', value: 'modified' },
];

export function AccountsView({ state, send }: AccountsViewProps) {
  const { screen } = useWindowSize((size) => {
    if (size === 'mobile') {
      send({ type: 'SET_LIMIT', limit: 10 });
    } else {
      send({ type: 'SET_LIMIT', limit: 20 });
    }
  });
  const isMobile = screen === 'mobile';

  if (state.matches('init')) {
    return <Loading />;
  }

  if (state.matches('error')) {
    return <ErrorPage />;
  }

  const numberOfPages = Math.ceil(
    state.context.totalNumberOfAccounts / (isMobile ? 10 : 20)
  );

  return (
    <PageContainer innerClassName={styles.container}>
      <div className={styles.headerRow}>
        <Breadcrumbs
          breadcrumbs={[{ text: 'Admin Dashboard', to: '/admin' }]}
        />
        <div className={styles.input}>
          <TextInput
            id="search"
            placeholder="Search by account or email"
            icon="menuItems"
            value={state.context.searchTerm}
            onChange={(searchTerm) =>
              send({ type: 'SET_SEARCH_TERM', searchTerm })
            }
          />
        </div>
      </div>
      <div className={styles.row}>
        <p className={styles.heading}>Accounts</p>
        <SortBy
          options={sortByOptions}
          value={state.context.sortMode}
          onSelect={(sortMode) => send({ type: 'CHANGE_SORT', sortMode })}
        />
      </div>
      <Block>
        {state.context.accounts.map((account) => (
          <AccountCard
            key={account.email}
            {...account}
            type="full"
            buttonLinkTo={`/account/${account.id}`}
            buttonText="View"
            buttonVariant="secondary"
            isYou={false}
            isOwner={true}
          />
        ))}
      </Block>
      {numberOfPages > 1 && (
        <PagePagination
          active={state.context.currentPage}
          numberOfPages={numberOfPages}
          onPageClick={(page) => send({ type: 'SET_PAGE', page })}
        />
      )}
    </PageContainer>
  );
}
