import { Tooltip } from '../Tooltip';

import styles from './TooltipShowcase.module.scss';

export function TooltipShowcase() {
  return (
    <div id="Tooltips" className={styles.container}>
      <div className="showcase-label">Tooltips</div>
      <div className={styles.content}>
        <Tooltip icon={'help'} status="default" label="Why are we asking?">
          <p>default</p>
        </Tooltip>
        <Tooltip icon={'help'} status="hover" label="Why are we asking?">
          <p>hover</p>
        </Tooltip>
      </div>
      <div className={styles.content}>
        <Tooltip icon={'help'} status="focus" label="Why are we asking?">
          <p>focus</p>
        </Tooltip>
        <Tooltip icon={'help'} status="active" label="Why are we asking?">
          <p>
            <p>
              <b>
                The Llama is funny, like moose and Nixon, and fish of any kind.
              </b>
            </p>
            Large møøse on the left hand side of the screen, in the third scene
            from the end, given a thorough grounding in Latin, French and O
            Level Geography by Bo Benn.
          </p>
        </Tooltip>
      </div>
    </div>
  );
}
