import { useState } from 'react';
import { Button } from '../Button';
import { Feedback } from '../Feedback';

import styles from './FeedbackShowcase.module.scss';

export function FeedbackShowcase() {
  const [pulse, setPulse] = useState(false);
  return (
    <>
      <div id="Feedback" className="showcase-label">
        Feedback
      </div>
      <div className={styles.container}>
        <Feedback type="neutral">Message contents</Feedback>
        <Feedback type="neutral">
          Message contents that are long enough to wrap on three lines and
          extend the height of the container.
        </Feedback>
        <Feedback title="Message contents" type="neutral">
          Message contents
        </Feedback>
        <Feedback title="Message contents" type="neutral">
          Message contents
          <Button variant="neutral" label="Proceed Anyway" />
        </Feedback>
        <Feedback type="warning">Message contents</Feedback>
        <Feedback type="warning">
          Message contents that are long enough to wrap on three lines and
          extend the height of the container.
        </Feedback>
        <Feedback title="Message contents" type="warning">
          Message contents
        </Feedback>
        <Feedback title="Message contents" type="warning">
          Message contents
          <Button variant="primary" label="Proceed Anyway" />
        </Feedback>
        <Feedback
          title="This message can be pulsed"
          type="warning"
          pulse={pulse}
        >
          Use the button below to toggle the feedback pulse.
          <Button
            variant="primary"
            label={pulse ? 'Turn Pulse Off' : 'Turn Pulse On'}
            onClick={() => setPulse(!pulse)}
          />
        </Feedback>
        <Feedback type="positive">Message contents</Feedback>
        <Feedback type="positive">
          Message contents that are long enough to wrap on three lines and
          extend the height of the container.
        </Feedback>
        <Feedback title="Message contents" type="positive">
          Message contents
        </Feedback>
        <Feedback title="Message contents" type="positive">
          Message contents
          <Button variant="primary" label="Proceed Anyway" />
        </Feedback>
        <Feedback title="Multiple Buttons" type="positive">
          Here we have a few buttons!
          <Button variant="primary" label="Proceed Anyway" />
          <Button variant="primary" label="Proceed Anyway" />
        </Feedback>
        <Feedback title="Multiple Buttons" type="positive">
          Here we have a few buttons!
          <div style={{ display: 'flex', gap: '1rem' }}>
            <Button variant="primary" label="Proceed" size="small" />
            <Button variant="primary" label="Proceed" size="small" />
          </div>
        </Feedback>
        <Feedback type="neutral" size="compact">
          Message contents
        </Feedback>
        <Feedback type="positive" size="compact">
          Message contents
        </Feedback>
        <Feedback type="warning" size="compact">
          Message contents
        </Feedback>
        <Feedback type="warning" size="compact">
          Message contents that are long enough to wrap on multiple lines and
          extend the height of the container.
        </Feedback>
      </div>
    </>
  );
}
