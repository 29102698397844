import { Select } from '../Select';
import { useState } from 'react';

import styles from './SelectShowcase.module.scss';
import { DatePicker } from '../DatePicker/DatePicker';
import { parseCalendarDate } from '../../utils/parseCalendarDate';

interface SelectInput {
  id: string;
  value: string | null;
  error?: boolean;
  disabled?: boolean;
}

export function SelectShowcase() {
  const [selectedDate, setSelectedDate] = useState<string>('');
  const [selectValues, setSelectValues] = useState<SelectInput[]>([
    {
      id: 'date1',
      value: null,
    },
    {
      id: 'date2',
      value: null,
      disabled: true,
    },
    {
      id: 'date3',
      value: null,
      error: true,
    },
  ]);

  const options = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];

  return (
    <div className={styles.container}>
      <span className="showcase-label">Selects</span>

      {selectValues.map((select, index) => (
        <Select
          key={select.id}
          id={select.id}
          disabled={select.disabled}
          error={select.error}
          label="Please select an option"
          items={options}
          itemToKey={(item) => item}
          getItemText={(item) => item}
          selectedItem={null}
          onChange={(item) =>
            setSelectValues(
              selectValues.map((el, i) => ({
                ...el,
                value: i === index ? item : el.value,
              }))
            )
          }
        />
      ))}
      <span className="showcase-label">Date Picker</span>
      <DatePicker
        aria-label="a"
        value={parseCalendarDate(selectedDate)}
        onChange={(date) => setSelectedDate(date.toString())}
        onBlur={() => null}
      />
      <DatePicker
        aria-label="a"
        value={parseCalendarDate(selectedDate)}
        onChange={() => null}
      />
      <DatePicker
        aria-label="a"
        value={parseCalendarDate(selectedDate)}
        onChange={(date) => setSelectedDate(date.toString())}
      />
    </div>
  );
}
