import cn from 'classnames';
import usePagination from '@mui/material/usePagination';

import styles from './PagePagination.module.scss';
import { match } from 'ts-pattern';

interface PagePaginationProps {
  numberOfPages: number;
  active: number;
  onPageClick: (page: number) => void;
  isMobile?: boolean;
}

export function PagePagination({
  numberOfPages,
  active,
  onPageClick,
  isMobile = false,
}: PagePaginationProps) {
  const { items } = usePagination({
    page: active,
    count: numberOfPages,
    siblingCount: isMobile ? 0 : 1,
    boundaryCount: isMobile ? 1 : 2,
  });

  return (
    <ul className={styles.container}>
      {items.map((item) => {
        const key = `${item.type}-${item.page ?? 0}`;
        return match(item.type)
          .with('previous', () => (
            <li key={key} className={styles.endLabel}>
              <button
                type="button"
                disabled={item.disabled}
                onClick={() => onPageClick(active - 1)}
              >
                Prev
              </button>
            </li>
          ))
          .with('next', () => (
            <li key={key} className={styles.endLabel}>
              <button
                type="button"
                disabled={item.disabled}
                onClick={() => onPageClick(active + 1)}
              >
                Next
              </button>
            </li>
          ))
          .with('start-ellipsis', () => (
            <li key={key} className={cn(styles.page, styles.ellipsis)}>
              <button type="button" disabled={true} className={styles.number}>
                ...
              </button>
            </li>
          ))
          .with('end-ellipsis', () => (
            <li key={key} className={cn(styles.page, styles.ellipsis)}>
              <button type="button" disabled={true} className={styles.number}>
                ...
              </button>
            </li>
          ))
          .with('page', () => (
            <li
              key={key}
              className={cn(styles.page, {
                [styles.active]: item.selected,
              })}
            >
              <button
                type="button"
                className={styles.number}
                onClick={() => item.page && onPageClick(item.page)}
              >
                {item.page}
              </button>
            </li>
          ))
          .otherwise(() => null);
      })}
    </ul>
  );
}
