import cn from 'classnames';
import { useCombobox } from 'downshift';

import styles from './ComboBox.module.scss';
import { useState } from 'react';
import { Icon } from './Icon';

type Props<Item> = {
  id: string;
  item: Item;
  items: Item[];
  selectedItem: Item | null;
  label?: string;
  error?: boolean;
  disabled?: boolean;
  initialSelectedItem?: Item;
  onChange: (option: Item) => void;
  getItemText: (option: Item) => string | null;
  className?: string;
  formFieldStatus?: 'default' | 'text' | 'error';
  filterFunction: (inputValue: string) => (item: Item) => boolean;
};

export function ComboBox<Item>(props: Props<Item>) {
  const [filteredItems, setFilteredItems] = useState(props.items);
  const {
    id,
    error = false,
    disabled = false,
    selectedItem,
    item,
    items,
    onChange,
    getItemText,
    className,
    formFieldStatus = 'default',
    filterFunction,
  } = props;
  const {
    isOpen,
    getMenuProps,
    getInputProps,
    highlightedIndex,
    getItemProps,
  } = useCombobox({
    id,
    labelId: `${id}-label`,
    items: filteredItems,
    selectedItem,
    onInputValueChange({ inputValue }) {
      if (inputValue !== undefined) {
        setFilteredItems(items.filter(filterFunction(inputValue)));
      }
    },
    onSelectedItemChange: (changes) => {
      if (changes.selectedItem) {
        onChange(changes.selectedItem);
      }
    },
  });

  return (
    <div
      className={cn(
        styles.selectInput,
        {
          [styles.open]: isOpen === true,
          [styles.closed]: isOpen === false,
          [styles.default]: selectedItem === null,
          [styles.text]: formFieldStatus === 'text',
          [styles.error]: error || formFieldStatus === 'error',
        },
        className
      )}
    >
      <div className={styles.inputContainer}>
        <input
          {...getInputProps()}
          className={cn(styles.input, {
            [styles.buttonDefault]: !disabled && selectedItem === null,
            [styles.buttonDisabled]: disabled,
            [styles.buttonError]: error,
            [styles.buttonOpen]: isOpen === true,
          })}
          disabled={disabled}
          {...(isOpen ? {} : { value: getItemText(item) ?? undefined })}
        />
        <Icon name="downChevron" className={styles.icon} />
      </div>

      <ul
        {...getMenuProps()}
        className={cn(styles.optionList, {
          [styles.open]: isOpen === true,
        })}
      >
        {isOpen &&
          filteredItems.map((item, index) => (
            <li className={styles.option} key={index}>
              <button
                type="button"
                className={cn(styles.optionButton, {
                  [styles.highlighted]: index === highlightedIndex,
                })}
                {...getItemProps({ item, index })}
              >
                {getItemText(item)}
              </button>
            </li>
          ))}
      </ul>
    </div>
  );
}
