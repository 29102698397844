import { DatePicker } from '../DatePicker/DatePicker';
import { FormField } from '../FormField';
import { InlineFormContainer } from '../InlineFormContainer';
import { InlineFormHeading } from '../InlineFormHeading';

export function InlineFormContainerShowcase() {
  return (
    <div>
      <span className="showcase-label">Inline Form Container</span>
      <InlineFormContainer
        primaryButtonText="Add Tour Date"
        primaryButtonOnClick={() => null}
        secondaryButtonText="Cancel"
        secondaryButtonOnClick={() => null}
      >
        <InlineFormHeading>Add a Tour Date</InlineFormHeading>
        <FormField
          id="form-field-email"
          label="Date"
          tooltipContent="tooltip content"
        >
          <DatePicker
            aria-label="Release date"
            value={null}
            onChange={() => null}
          />
        </FormField>
      </InlineFormContainer>
    </div>
  );
}
