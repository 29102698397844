import { useState } from 'react';
import { Block } from '../Block';
import { Feedback } from '../Feedback';
import { FormField } from '../FormField';
import { SaveableTextArea } from '../SaveableTextArea';
import styles from './BlockShowcase.module.scss';

export function BlockShowcase() {
  const [strategy, setStrategy] = useState('Sell lots of tickets.');

  return (
    <div className={styles.container}>
      <span className="showcase-label">Block</span>

      <Block headerText="Example Block 1" iconName="infoCircle">
        <p>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
          eiusmod tempor incididunt ut labore et dolore magna aliqua. Egestas
          tellus rutrum tellus pellentesque eu. Cursus metus aliquam eleifend
          mi. Nunc id cursus metus aliquam eleifend mi. Enim diam vulputate ut
          pharetra sit amet aliquam id diam. Fringilla ut morbi tincidunt augue
          interdum. Nunc consequat interdum varius sit amet mattis vulputate
          enim. Eleifend mi in nulla posuere sollicitudin aliquam ultrices
          sagittis. Lacus viverra vitae congue eu consequat ac felis donec et.
          Facilisis mauris sit amet massa vitae tortor condimentum. Mattis
          aliquam faucibus purus in. Pretium lectus quam id leo in. Tristique
          sollicitudin nibh sit amet commodo nulla facilisi nullam vehicula. Ut
          ornare lectus sit amet.
        </p>
        <ul>
          <li>Quisque non tellus orci ac auctor augue.</li>
          <li>Cursus turpis massa tincidunt dui ut ornare lectus sit.</li>
          <li>
            Cras semper auctor neque vitae tempus. Vel fringilla est ullamcorper
            eget. Fermentum et sollicitudin ac orci phasellus egestas tellus
            rutrum. Montes nascetur ridiculus mus mauris vitae ultricies leo.
          </li>
          <li>
            Tortor dignissim convallis aenean et tortor at risus viverra. Nunc
            non blandit massa enim nec dui nunc. Duis at tellus at urna
            condimentum mattis pellentesque id nibh.
          </li>
        </ul>
        <p>
          Commodo viverra maecenas accumsan lacus. Mus mauris vitae ultricies
          leo integer. Nulla facilisi morbi tempus iaculis urna id. Viverra
          mauris in aliquam sem fringilla ut morbi. Ut lectus arcu bibendum at
          varius vel pharetra vel turpis.
        </p>

        <FormField
          id="form-field-strategy"
          label="Describe your tour strategy"
          note="optional"
        >
          <SaveableTextArea
            id="form-field-strategy"
            label="Describe your tour strategy"
            savedValue={strategy}
            saveButtonLabel="Save Strategy"
            onSubmit={(value) => {
              setStrategy(value);
            }}
          />
        </FormField>
      </Block>

      <Block
        headerText="Introduction"
        iconName="user"
        headerButtonOnClick={() => alert('Action 2 clicked')}
        headerButtonText="Action"
      >
        <p>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
          eiusmod tempor incididunt ut labore et dolore magna aliqua. Egestas
          tellus rutrum tellus pellentesque eu. Cursus metus aliquam eleifend
          mi. Nunc id cursus metus aliquam eleifend mi. Enim diam vulputate ut
          pharetra sit amet aliquam id diam. Fringilla ut morbi tincidunt augue
          interdum. Nunc consequat interdum varius sit amet mattis vulputate
          enim. Eleifend mi in nulla posuere sollicitudin aliquam ultrices
          sagittis. Lacus viverra vitae congue eu consequat ac felis donec et.
          Facilisis mauris sit amet massa vitae tortor condimentum. Mattis
          aliquam faucibus purus in. Pretium lectus quam id leo in. Tristique
          sollicitudin nibh sit amet commodo nulla facilisi nullam vehicula. Ut
          ornare lectus sit amet.
        </p>
        <ul>
          <li>Quisque non tellus orci ac auctor augue.</li>
          <li>Cursus turpis massa tincidunt dui ut ornare lectus sit.</li>
          <li>
            Cras semper auctor neque vitae tempus. Vel fringilla est ullamcorper
            eget. Fermentum et sollicitudin ac orci phasellus egestas tellus
            rutrum. Montes nascetur ridiculus mus mauris vitae ultricies leo.
          </li>
          <li>
            Tortor dignissim convallis aenean et tortor at risus viverra. Nunc
            non blandit massa enim nec dui nunc. Duis at tellus at urna
            condimentum mattis pellentesque id nibh.
          </li>
        </ul>
        <p>
          Commodo viverra maecenas accumsan lacus. Mus mauris vitae ultricies
          leo integer. Nulla facilisi morbi tempus iaculis urna id. Viverra
          mauris in aliquam sem fringilla ut morbi. Ut lectus arcu bibendum at
          varius vel pharetra vel turpis.
        </p>
        <Feedback type="neutral" title="Feedback" />
      </Block>
    </div>
  );
}
