import { useMachine } from '@xstate/react';
import {
  machine,
  AdminDashboardMachineOptions,
} from '../../../machines/Admin/AdminDashboardMachine';
import { AdminDashboardView } from './AdminDashboardView';

type AdminDashboardControllerProps = {
  options: AdminDashboardMachineOptions;
};

export function AdminDashboardController({
  options,
}: AdminDashboardControllerProps) {
  const [state, send] = useMachine(machine, options);

  return <AdminDashboardView state={state} send={send} />;
}
