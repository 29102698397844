import { IconShowcase } from '../components/Showcase/IconShowcase';
import { TextShowcase } from '../components/Showcase/TextShowcase';
import { ButtonShowcase } from '../components/Showcase/ButtonShowcase';
import { FeedbackShowcase } from '../components/Showcase/FeedbackShowcase';
import { LinkShowcase } from '../components/Showcase/LinkShowcase';
import { CardShowcase } from '../components/Showcase/CardShowcase';
import { CheckOptionShowcase } from '../components/Showcase/CheckOptionShowcase';
import { HeaderShowcase } from '../components/Showcase/HeaderShowcase';
import { RadioOptionShowcase } from '../components/Showcase/RadioOptionShowcase';
import { SelectShowcase } from '../components/Showcase/SelectShowcase';
import { TextInputShowcase } from '../components/Showcase/TextInputShowcase';
import { PaginationShowcase } from '../components/Showcase/PaginationShowcase';
import { Footer } from '../components/Footer';
import { FieldSetShowcase } from '../components/Showcase/FieldSetShowcase';
import { FormFieldShowcase } from '../components/Showcase/FormFieldShowcase';
import { PopupMenuShowcase } from '../components/Showcase/PopupMenuShowcase';
import { ProgressIconShowcase } from '../components/Showcase/ProgressIconShowcase';
import { ProgressTooltipShowcase } from '../components/Showcase/ProgressTooltipShowcase';
import { RangeSliderShowcase } from '../components/Showcase/RangeSliderShowcase';
import { TableShowcase } from '../components/Showcase/TableShowcase';
import { AnnouncementShowcase } from '../components/Showcase/AnnouncementShowcase';
import { InlineFormContainerShowcase } from '../components/Showcase/InlineFormContainerShowcase';
import { TooltipShowcase } from '../components/Showcase/TooltipShowcase';
import { TextAreaShowcase } from '../components/Showcase/TextAreaShowcase';
import { BlockShowcase } from '../components/Showcase/BlockShowcase';
import { ComboBoxShowcase } from '../components/Showcase/ComboBoxShowcase';
import { ApplicationSidebarShowcase } from '../components/Showcase/ApplicationSidebarShowcase';
import { CTAShowcase } from '../components/Showcase/CTAShowcase';
import { HeroSectionShowcase } from '../components/Showcase/HeroSectionShowcase';
import { ActiveApplicationCardShowcase } from '../components/Showcase/ActiveApplicationCardShowcase';

import styles from './Showcase.module.scss';

export function Showcase() {
  return (
    <div className={styles.container}>
      <ApplicationSidebarShowcase />

      <div className="showcase-wrapper">
        <span className="showcase-wrapper showcase-label">Header</span>
        <HeaderShowcase />
        <TextShowcase />
        <BlockShowcase />
        <TextInputShowcase />
        <IconShowcase />
        <ButtonShowcase />
        <FeedbackShowcase />
        <CheckOptionShowcase />
        <LinkShowcase />
        <CardShowcase />
        <TooltipShowcase />
        <ProgressIconShowcase />
        <ProgressTooltipShowcase />
        <SelectShowcase />
        <RadioOptionShowcase />
        <PaginationShowcase />
        <PopupMenuShowcase />
        <FieldSetShowcase />
        <FormFieldShowcase />
        <RangeSliderShowcase />
        <TableShowcase />
        <AnnouncementShowcase />
        <ComboBoxShowcase />
        <TextAreaShowcase />
        <InlineFormContainerShowcase />
        <CTAShowcase />
        <HeroSectionShowcase />
        <ActiveApplicationCardShowcase />
        <span className="showcase-wrapper showcase-label">Footer</span>
        <Footer />
      </div>
    </div>
  );
}
