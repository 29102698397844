import { Link } from '../Link';

import styles from './LinkShowcase.module.scss';

export function LinkShowcase() {
  return (
    <div className={styles.container}>
      <div className="showcase-label">Links</div>
      <Link startIcon="file" endIcon="rightChevron" href="/">
        This is a link
      </Link>
      <Link href="/">This is a link</Link>
      <Link disabled={true} href="/">
        This is a link
      </Link>

      <Link onClick={() => console.log('clicked')} href="/">
        Print click to console
      </Link>
    </div>
  );
}
