import { createBrowserRouter, Outlet, RouterProvider } from 'react-router-dom';

import { Helmet } from './components/Helmet';
import { PublicPageHelmet } from './components/PublicPageHelmet';
import { AuthProvider } from './components/AuthProvider';
import { Showcase } from './pages/Showcase';
import { SignIn } from './pages/SignIn';
import { ConnectionStatus } from './components/ConnectionStatus';
import { Header } from './components/Header';
import { DevHome } from './pages/DevHome';
import { PageContainer } from './components/PageContainer';
import { ResetPassword } from './pages/ResetPassword/ResetPassword';
import { Dashboard } from './pages/Dashboard/Dashboard';
import { Application } from './pages/Application/Application';
import { ForgotPassword } from './pages/ForgotPassword/ForgotPassword';
import { Eligibility } from './pages/Eligibility/Eligibility';
import { Eligibility as OrionEligibility } from './pages/Eligibility/Orion/Eligibility';
import { BankAccounts } from './pages/BankAccounts';
import { RedeemAccountInvitation } from './pages/RedeemAccountInvitation/RedeemAccountInvitation';
import { RedeemArtistInvitation } from './pages/RedeemArtistInvitation/RedeemArtistInvitation';
import { Orion } from './pages/Orion';
import { BoardOfDirectors } from './pages/BoardOfDirectors';
import { Release } from './pages/Release/Release';
import { Unsubscribe } from './pages/Unsubscribe/Unsubscribe';
import { Home } from './pages/Home/Home';
import { Requirements } from './pages/Requirements';
import { Deadlines } from './pages/Deadlines';
import { FAQ } from './pages/FAQ';
import { ContactUs } from './pages/ContactUs';
import { About } from './pages/About';
import { AllArtists } from './pages/AllArtists/AllArtists';
import { AllReleases } from './pages/AllReleases/AllReleases';
import { AllApplications } from './pages/AllApplications/AllApplications';
import { AdminDashboard } from './pages/Admin/Dashboard/AdminDashboard';
import { AddArtist } from './pages/AddArtist/AddArtist';
import { AddRelease } from './pages/AddRelease/AddRelease';
import { ApplicationSearch } from './pages/ApplicationSearch/ApplicationSearch';
import { OnlyWhenSignedIn } from './components/OnlyWhenSignedIn';
import { Accounts } from './pages/Admin/Accounts/Accounts';
import { Finances } from './pages/Finances';
import { Rounds } from './pages/Admin/Rounds/Rounds';
import { SearchArtists } from './pages/Admin/Artists/SearchArtists';
import { Account } from './pages/Account/Account';
import { TimeTravelPanel } from './components/TimeTravelPanel';
import { NotFound } from './pages/NotFound';
import { ErrorPage } from './pages/ErrorPage';
import { ScrollToTop } from './components/ScrollToTop';
import { Subscribe } from './pages/Subscribe/Subscribe';
import { BoardMembers } from './pages/Admin/BoardMembers/BoardMembers';
import { RedeemBoardInvitation } from './pages/RedeemBoardInvitation/RedeemBoardInvitation';
import { Subscribers } from './pages/Admin/Subscribers/Subscribers';
import { BoardMeeting } from './pages/BoardMeeting/BoardMeeting';

import styles from './App.module.scss';

const router = createBrowserRouter([
  {
    path: '/',
    element: (
      <>
        <Helmet />
        <ScrollToTop />
        <Header />
      </>
    ),
    errorElement: (
      <>
        <Header />
        <ErrorPage />
      </>
    ),
    children: [
      {
        path: 'sign-in',
        element: (
          <PageContainer>
            <SignIn />
          </PageContainer>
        ),
      },
      {
        path: '/dev',
        element: (
          <PageContainer>
            <DevHome />
          </PageContainer>
        ),
      },
      {
        path: '/',
        element: (
          <>
            <PublicPageHelmet />
            <Home />
          </>
        ),
      },
      {
        path: '/requirements',
        element: (
          <>
            <PublicPageHelmet />
            <Requirements />
          </>
        ),
      },
      {
        path: '/about',
        element: (
          <>
            <PublicPageHelmet />
            <About />
          </>
        ),
      },
      {
        path: '/admin',
        element: (
          <OnlyWhenSignedIn allowedRoles={['administrator']}>
            <Outlet />
          </OnlyWhenSignedIn>
        ),
        children: [
          {
            path: '',
            element: (
              <PageContainer>
                <AdminDashboard />
              </PageContainer>
            ),
          },
          {
            path: 'accounts',
            element: <Accounts />,
          },
          {
            path: 'rounds',
            element: <Rounds />,
          },
          {
            path: 'artists',
            element: <SearchArtists />,
          },
          {
            path: 'search-applications',
            element: <ApplicationSearch />,
          },
          {
            path: 'board-members',
            element: <BoardMembers />,
          },
          {
            path: 'subscribers',
            element: <Subscribers />,
          },
          {
            path: 'board-meeting/:round',
            element: <BoardMeeting />,
          },
        ],
      },
      {
        path: '/board-meeting',
        element: (
          <OnlyWhenSignedIn allowedRoles={['board', 'administrator']}>
            <Outlet />
          </OnlyWhenSignedIn>
        ),
        children: [
          {
            path: '',
            element: <BoardMeeting />,
          },
          {
            path: 'applications',
            element: <ApplicationSearch isBoardView={true} />,
          },
        ],
      },
      {
        path: '/account/:accountId',
        element: (
          <OnlyWhenSignedIn>
            <Account />
          </OnlyWhenSignedIn>
        ),
      },
      {
        path: '/deadlines',
        element: (
          <>
            <PublicPageHelmet />
            <Deadlines />
          </>
        ),
      },
      {
        path: '/faq',
        element: (
          <>
            <PublicPageHelmet />
            <FAQ />
          </>
        ),
      },
      {
        path: '/contact',
        element: (
          <>
            <PublicPageHelmet />
            <ContactUs />
          </>
        ),
      },
      {
        path: '/subscribe/:tag',
        element: (
          <>
            <PublicPageHelmet />
            <Subscribe />
          </>
        ),
      },
      {
        path: '/unsubscribe/:tag',
        element: (
          <>
            <PublicPageHelmet />
            <Unsubscribe />
          </>
        ),
      },
      {
        path: '/orion',
        element: (
          <>
            <PublicPageHelmet />
            <Orion />
          </>
        ),
      },
      {
        path: '/board',
        element: (
          <>
            <PublicPageHelmet />
            <BoardOfDirectors />
          </>
        ),
      },
      {
        path: '/finances',
        element: (
          <>
            <PublicPageHelmet />
            <Finances />
          </>
        ),
      },
      {
        path: 'eligibility',
        children: [
          {
            path: 'orion',
            element: (
              <PageContainer>
                <OrionEligibility />
              </PageContainer>
            ),
          },
          {
            path: '',
            element: (
              <PageContainer>
                <Eligibility />
              </PageContainer>
            ),
          },
        ],
      },
      {
        path: 'showcase',
        element: (
          <PageContainer className={styles.showcase}>
            <Showcase />
          </PageContainer>
        ),
      },
      {
        path: 'forgot-password',
        element: (
          <PageContainer>
            <ForgotPassword />
          </PageContainer>
        ),
      },
      {
        path: 'reset-password/:tag',
        element: (
          <PageContainer>
            <ResetPassword />
          </PageContainer>
        ),
      },
      {
        path: 'artists',
        element: (
          <OnlyWhenSignedIn>
            <AllArtists />
          </OnlyWhenSignedIn>
        ),
      },
      {
        path: 'artist',
        element: (
          <OnlyWhenSignedIn>
            <Outlet />
          </OnlyWhenSignedIn>
        ),
        children: [
          {
            path: 'add',
            element: (
              <PageContainer>
                <AddArtist />
              </PageContainer>
            ),
          },
          {
            path: ':artistId',
            element: <Dashboard />,
          },
          {
            path: ':artistId/application/:applicationId',
            element: <Application />,
          },
          {
            path: ':artistId/applications',
            element: <AllApplications />,
          },
          {
            path: ':artistId/release/:releaseId',
            element: (
              <PageContainer>
                <Release />
              </PageContainer>
            ),
          },
          {
            path: ':artistId/releases',
            element: <AllReleases />,
          },
          {
            path: ':artistId/releases/add',
            element: (
              <PageContainer>
                <AddRelease />
              </PageContainer>
            ),
          },
        ],
      },
      {
        path: 'redeem-account-invitation/:tag',
        element: (
          <PageContainer>
            <RedeemAccountInvitation />
          </PageContainer>
        ),
      },
      {
        path: 'redeem-artist-invitation/:tag',
        element: (
          <PageContainer>
            <RedeemArtistInvitation />
          </PageContainer>
        ),
      },
      {
        path: 'redeem-board-member-invitation/:tag',
        element: (
          <PageContainer>
            <RedeemBoardInvitation />
          </PageContainer>
        ),
      },
      {
        path: 'bank-accounts',
        element: (
          <PageContainer>
            <BankAccounts />
          </PageContainer>
        ),
      },
    ],
  },
  {
    path: '*',
    element: (
      <>
        <Header />
        <NotFound />
      </>
    ),
  },
]);

const App = () => (
  <AuthProvider>
    {import.meta.env.VITE_SHOW_CONNECTION_STATUS === 'true' && (
      <ConnectionStatus />
    )}
    {import.meta.env.VITE_SHOW_TIME_TRAVEL_PANEL === 'true' && (
      <TimeTravelPanel />
    )}
    <RouterProvider router={router} />
  </AuthProvider>
);

export default App;
