import { useState } from 'react';
import { TextInput } from '../TextInput';

import styles from './TextInputShowcase.module.scss';

export function TextInputShowcase() {
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('invalid-email@foo');
  const [artist, setArtist] = useState('Kitty Trio');

  return (
    <div className={styles.container}>
      <div id="Text Input" className="showcase-label">
        Text Input
      </div>
      <TextInput
        id="firstName"
        label="First name"
        value={firstName}
        onChange={setFirstName}
      />
      <TextInput
        id="lastName"
        label="Last name"
        disabled={true}
        value={lastName}
        onChange={setLastName}
      />
      <TextInput
        id="email"
        label="Email"
        value={email}
        onChange={setEmail}
        error={!validateEmail(email)}
      />
      <TextInput
        id="artist"
        label="Artist"
        value={artist}
        onChange={setArtist}
      />
      <TextInput
        id="artist"
        label="Artist"
        value={artist}
        onChange={setArtist}
        icon="home"
      />
    </div>
  );
}

function validateEmail(email: string) {
  const re = /\S+@\S+\.\S+/;
  return re.test(email);
}
