import { P, match } from 'ts-pattern';
import { FundingRequestsBlockController } from '../../components/FundingRequests/FundingRequestsBlockController';
import { Feedback } from '../../components/Feedback';
import { TourDatesBlockController } from '../../components/TourDates/TourDatesBlockController';
import { ApplicationStatementBlockController } from '../../components/ApplicationStatement/ApplicationStatementBlockController';
import {
  ApplicationMachineSender,
  ApplicationMachineState,
} from '../../machines/Application/ApplicationMachine';
import { FundingRequestsBlockMachineActor } from '../../machines/components/FundingRequestsBlockMachine';
import { TourDatesBlockMachineActor } from '../../machines/components/TourDatesBlockMachine';
import { AttachmentsBlockController } from '../../components/Attachments/AttachmentsBlockController';
import { AttachmentsBlockMachineActor } from '../../machines/components/AttachmentsBlockMachine';
import { ApplicationStatementBlockMachineActor } from '../../machines/components/ApplicationStatementBlockMachine';
import { ContactsBlockController } from '../../components/Contacts/ContactsBlockController';
import { ContactsBlockMachineActor } from '../../machines/components/ContactsBlockMachine';
import { Block } from '../../components/Block';
import { ApplicationSidebar } from '../../components/ApplicationSidebar';
import { PageContainerWithSidebar } from '../../components/PageContainerWithSidebar';
import { ApplicationReviewBlockView } from '../../components/ApplicationReview/ApplicationReviewBlockView';
import { ApplicationReview } from '../../components/Admin/ApplicationReview/ApplicationReview';
import { ApplicationReviewMachineActor } from '../../machines/Admin/ApplicationReviewMachine';
import {
  ApplicationStatus,
  ApplicationType,
  ApplicationStage,
} from '../../graphql/operations';
import { Link } from '../../components/Link';
import { ClaimFundsController } from '../../components/ClaimFunds/ClaimFundsController';
import { DirectDepositsMachineActor } from '../../machines/ClaimFunds/DirectDepositsMachine';
import { getApplicationStatementType } from '../../utils/getApplicationStatementType';
import { getFundingRequestLabels } from '../../utils/getFundingRequestLabels';
import { ApplicationStatusFeedback } from '../../components/ApplicationStatusFeedback/ApplicationStatusFeedback';
import { Loading } from '../../components/Loading';
import { ErrorPage } from '../ErrorPage';
import { NotFound } from '../NotFound';
import { SignedContractMachineActor } from '../../machines/ClaimFunds/SignedContractMachine';
import { TourSummaryMachineActor } from '../../machines/ClaimFunds/TourSummaryMachine';
import { DescriptionList } from '../../components/DescriptionList';
import { normalizeEnum } from '../../utils/normalizeEnum';

import styles from './ApplicationView.module.scss';

interface ApplicationViewProps {
  state: ApplicationMachineState;
  send: ApplicationMachineSender;
}

export function ApplicationView({ state, send }: ApplicationViewProps) {
  if (state.matches('load')) {
    return <Loading />;
  }

  if (state.matches('load.error')) {
    return <ErrorPage />;
  }

  if (state.matches('load.notFound')) {
    return <NotFound />;
  }

  const {
    applicationType,
    status,
    artist,
    isAdmin,
    isBoard,
    requestedTotal,
    submittedAt,
    claimsSubmittedAt,
    stage,
    applicationRound,
    touringDirectDepositAccount,
    fundingRequestDirectDepositAccount,
    isOwner,
    fundingRequests,
    claimsReopenedAt,
    claimsResubmittedAt,
    claimsResubmissionDeadline,
    reopenClaimsNotes,
  } = state.context;

  const statementType = getApplicationStatementType(applicationType);
  const fundingType = getFundingRequestLabels(applicationType).title;
  const isCore = applicationType === ApplicationType.Core;
  const hasPayment =
    (touringDirectDepositAccount?.payments.length ?? 0) > 0 ||
    (fundingRequestDirectDepositAccount?.payments.length ?? 0) > 0;

  const canEditApplication = isOwner || isAdmin;

  return (
    <div>
      <PageContainerWithSidebar
        innerClassName={styles.container}
        heading={
          <>
            <span className="text-normal">
              {applicationRound ? `Round ${applicationRound.title} ` : ''}
              {applicationType === ApplicationType.Core ? 'Core' : 'Orion'}{' '}
              Funding Application for
            </span>{' '}
            {artist.name}
          </>
        }
        breadcrumbs={match({ isAdmin, isBoard })
          .with({ isAdmin: true }, () => [
            { text: 'Admin Dashboard', to: '/admin' },
            {
              text: 'Search Applications',
              to: '/admin/search-applications',
            },
          ])
          .with({ isBoard: true }, () => [
            { text: 'Board Meeting', to: '/board-meeting' },
            {
              text: 'Search Applications',
              to: '/board-meeting/applications',
            },
          ])
          .otherwise(() => [
            { text: 'Dashboard', to: `/artist/${artist.id}` },
            {
              text: 'All Applications',
              to: `/artist/${artist.id}/applications`,
            },
          ])}
      >
        <ApplicationSidebar
          links={[
            ...(status === ApplicationStatus.Approved ? ['Claims'] : []),
            ...(!submittedAt ? ['Introduction'] : []),
            ...(isCore ? ['Tour Dates'] : []),
            ...[statementType, fundingType, 'Attachments', 'Contacts'],
          ]}
          fundingRequested={requestedTotal}
          fundingApproved={match([status, isAdmin || isBoard])
            .with(
              [ApplicationStatus.Approved, P._],
              () => state.context.approvedTotal
            )
            .with([P._, true], () => state.context.tentativeTotal)
            .otherwise(() => undefined)}
          submittedAt={submittedAt}
          applicationType={applicationType}
          status={status}
          stage={stage}
          claimsSubmittedAt={claimsSubmittedAt}
          claimsReopenedAt={claimsReopenedAt}
          claimsResubmissionDeadline={claimsResubmissionDeadline}
          claimsResubmittedAt={claimsResubmittedAt}
        />
        <div className={styles.application}>
          {canEditApplication && (
            <div className={styles.applicationFeedback}>
              <ApplicationStatusFeedback
                application={{
                  status,
                  stage,
                  fundingRound: applicationRound,
                  type: applicationType,
                  hasPayment,
                  hasFundingRequest: fundingRequests.length > 0,
                  claimsResubmissionDeadline,
                  reopenClaimsNotes,
                }}
                location="application"
                isFinalOrionApplication={
                  artist.orionApprovalLimit - artist.orionApprovalCount === 1
                }
              />
              {!artist.isEligible && (
                <Feedback
                  type="warning"
                  title="You are NOT eligible for this round."
                  icon="xCircle"
                >
                  <div className={styles.feedback}>
                    <p>
                      You won’t be able to submit this application until you
                      meet the minimum requirements.
                    </p>
                    <p>
                      See your{' '}
                      <Link href={`/artist/${artist.id}`}>dashboard</Link> to
                      learn why.
                    </p>
                  </div>
                </Feedback>
              )}
            </div>
          )}
          <div className={styles.applicationSections}>
            {isAdmin && status != ApplicationStatus.InProgress && (
              <ApplicationReview
                actor={
                  state.children
                    .adminApplicationReviewMachine as ApplicationReviewMachineActor
                }
              />
            )}
            {status === ApplicationStatus.Approved && !isBoard && (
              <ClaimFundsController
                stage={state.context.stage}
                claimedTotalForTourDates={
                  state.context.claimedTotalForTourDates
                }
                claimedTotalForFundingRequests={
                  state.context.claimedTotalForFundingRequests
                }
                claimedTotal={state.context.claimedTotal}
                isOwner={isOwner}
                isClaimsReopenable={state.context.isClaimsReopenable}
                reopenClaimsNotes={state.context.reopenClaimsNotes}
                claimsReopenedAt={state.context.claimsReopenedAt}
                claimsResubmissionDeadline={
                  state.context.claimsResubmissionDeadline
                }
                claimsResubmittedAt={state.context.claimsResubmittedAt}
                hasApprovedFundingRequests={
                  state.context.approvedTotalForFundingRequests > 0
                }
                directDepositsActor={
                  state.children
                    .directDepositsMachine as DirectDepositsMachineActor
                }
                signedContractActor={
                  state.children
                    .signedContractMachine as SignedContractMachineActor
                }
                tourSummaryActor={
                  state.children.tourSummaryMachine as TourSummaryMachineActor
                }
                applicationState={state}
                applicationSend={send}
              />
            )}
            {isBoard && (
              <Block className={styles.boardBlock}>
                <DescriptionList
                  list={{
                    'Application Review Status': normalizeEnum(
                      state.context.reviewStatus
                    ),
                  }}
                  fontSize="body"
                />
                <div>
                  <p className="body-text-bold">
                    Application Review Notes for Board
                  </p>
                  <p className={styles.notes}>
                    {state.context.boardNotes?.notes}
                  </p>
                </div>
                <Link
                  endIcon="externalLink"
                  className={styles.link}
                  href={`/artist/${state.context.artist.id}`}
                >
                  {`View Artist Details for ${state.context.artist.name}`}
                </Link>
              </Block>
            )}
            {status === ApplicationStatus.InProgress && canEditApplication && (
              <Block
                id="Introduction"
                headerText="Introduction"
                iconName="infoCircle"
              >
                {isCore ? (
                  <p>
                    To complete and submit your funding application, please add
                    your Tour Dates and any Digital Content Funding requests
                    below. Provide context about your tour with the Tour
                    Summary. You may also attach any supporting files to your
                    application.
                  </p>
                ) : (
                  <p>
                    To complete and submit your funding application, please fill
                    out your Proposal and Funding Requests below. You may also
                    attach any supporting files to your application.
                  </p>
                )}
              </Block>
            )}
            {isCore && (
              <TourDatesBlockController
                actor={
                  state.children.tourDatesMachine as TourDatesBlockMachineActor
                }
                isReopened={stage === ApplicationStage.ClaimsReopened}
              />
            )}
            <ApplicationStatementBlockController
              statementType={statementType}
              actor={
                state.children
                  .applicationStatementMachine as ApplicationStatementBlockMachineActor
              }
            />
            <FundingRequestsBlockController
              actor={
                state.children
                  .fundingRequestsMachine as FundingRequestsBlockMachineActor
              }
            />
            <AttachmentsBlockController
              actor={
                state.children
                  .attachmentsMachine as AttachmentsBlockMachineActor
              }
            />
            <ContactsBlockController
              actor={
                state.children.contactsBlockMachine as ContactsBlockMachineActor
              }
            />
            <ApplicationReviewBlockView state={state} send={send} />
          </div>
        </div>
      </PageContainerWithSidebar>
    </div>
  );
}
