import cn from 'classnames';
import { Newsletter } from '../../components/CTA/Newsletter/Newsletter';
import { Card } from '../../components/Card';
import { Footer } from '../../components/Footer';
import { HeroSection } from '../../components/HeroSection';
import { ScheduleTable } from '../../components/Tables/ScheduleTable';
import { Link } from '../../components/Link';
import { HomeMachineState } from '../../machines/HomeMachine';
import { Loading } from '../../components/Loading';
import { ErrorPage } from '../ErrorPage';

import styles from './Home.module.scss';

interface HomeViewProps {
  state: HomeMachineState;
  isSignedIn: boolean;
}

export function HomeView({ state, isSignedIn }: HomeViewProps) {
  if (state.matches('init')) {
    return <Loading />;
  }

  if (state.matches('initError')) {
    return <ErrorPage />;
  }

  return (
    <div className={styles.container}>
      <HeroSection
        title="Canadian Starmaker Fund"
        {...(isSignedIn
          ? {
              primaryButtonText: 'View Your Artists',
              primaryButtonLinkTo: '/artists',
            }
          : {
              primaryButtonText: 'Start Core Funding Application',
              primaryButtonLinkTo: '/eligibility',
              secondaryButtonText: 'Start Orion Application',
              secondaryButtonLinkTo: '/eligibility/orion',
            })}
        rounded={true}
      >
        <p>
          Working to create a substantial and discernible difference in the
          careers of emerging Canadian artists from all cultural backgrounds.{' '}
          <Link href="/about" className={styles.link}>
            Learn more about the fund
          </Link>
          .
        </p>
      </HeroSection>
      <div className={cn('max-w-xl', styles.collapseRow)}>
        <div className={styles.table}>
          <ScheduleTable rounds={state.context.rounds} />
        </div>
        <div className={styles.reqsAndOrion}>
          <Card
            width="md"
            title="Requirements & Rules"
            icon="list"
            linkTo="/requirements"
          />
          <Card width="md" title="Orion Program" icon="file" linkTo="/orion" />
        </div>
      </div>
      <div className={styles.home}>
        <div className={styles.faq}>
          <h2>Frequently Asked Questions</h2>
          <div className={cn('max-w-xl', styles.collapseRow)}>
            <Card
              icon="requirements"
              title="Does My Project Qualify?"
              linkTo="/faq/#Does My Project Qualify?"
            />
            <Card
              icon="helpCircle"
              title="What Do I Need to Know to Apply?"
              linkTo="/faq/#What Do I Need To Know To Apply?"
            />
            <Card
              icon="award"
              title="Next Steps After Funding Approval?"
              linkTo="/faq/#If I'm Approved For RSF Funding, What Happens Next"
            />
          </div>
        </div>
        <Newsletter />
      </div>
      <Footer />
    </div>
  );
}
