import cn from 'classnames';

import styles from './DescriptionList.module.scss';

interface DescriptionListProps {
  list: Record<string, string | number>;
  title?: string;
  fontSize?: 'small' | 'body' | 'micro';
  className?: string;
}

export function DescriptionList({
  list,
  title,
  fontSize = 'small',
  className,
}: DescriptionListProps) {
  return (
    <div className={styles.container}>
      {title && (
        <p
          className={cn(styles.title, {
            [styles.body]: fontSize === 'small',
            [styles.small]: fontSize === 'micro',
          })}
        >
          {title}
        </p>
      )}
      <dl className={cn(className)}>
        {Object.entries(list).map(([term, description]) => (
          <div key={`${term}: ${description}`}>
            <dt className={cn(styles.term, styles[fontSize])}>{term}:</dt>
            <dd className={cn(styles.description, styles[fontSize])}>
              {description}
            </dd>
          </div>
        ))}
      </dl>
    </div>
  );
}
