import { assign, createMachine, sendParent } from 'xstate';
import type {
  ActorRefFrom,
  EventFrom,
  MachineOptionsFrom,
  Sender,
} from 'xstate';
import {
  ApplicationReviewStatus,
  ApplicationStatus,
} from '../../graphql/operations';
import { CreateAddBoardBlockPromiseResult } from '../../promises/Admin/createAddBoardBlockPromise';
import { CreateApproveAndNotifyPromiseResult } from '../../promises/Admin/createApproveAndNotifyPromise';
import { CreateRemoveBoardBlockPromiseResult } from '../../promises/Admin/createRemoveBoardBlockPromise';
import {
  CreateDenyApplicationPromiseResult,
  CreateResetApplicationStatusPromiseResult,
  CreateUpdateApplicationReviewStatusPromiseResult,
} from '../../promises/Admin/applicationReviewPromises';
import { CreateUpsertBoardNotesPromiseResult } from '../../promises/Admin/createUpsertBoardNotesPromise';
import { StateFrom } from '../../utils/StateFrom';
import { isEmailValid } from '../../utils/isEmailValid';
import { Context as ParentContext } from '../Application/ApplicationMachine';
import { refreshApplication } from '../../events/RefreshApplication';
import { logMachineError } from '../../utils/logError';

export type Events =
  | { type: 'DENY' }
  | { type: 'RESET_STATUS' }
  | { type: 'UPDATE_REVIEW_STATUS'; reviewStatus: ApplicationReviewStatus }
  | { type: 'OPEN_APPROVAL_FORM' }
  | { type: 'CLOSE_APPROVAL_FORM' }
  | { type: 'OPEN_ADD_EMAIL_FORM' }
  | { type: 'CLOSE_ADD_EMAIL_FORM' }
  | { type: 'BLUR_EMAIL_INPUT' }
  | { type: 'TOGGLE_EMAIL'; email: string }
  | { type: 'SAVE_ADDITIONAL_EMAIL' }
  | { type: 'REMOVE_ADDITIONAL_EMAIL'; email: string }
  | { type: 'APPROVE_AND_NOTIFY' }
  | { type: 'ADD_BOARD_BLOCK'; accountId: string }
  | { type: 'REMOVE_BOARD_BLOCK'; accountId: string }
  | { type: 'UPDATE_ADD_EMAIL_INPUT_VALUE'; value: string }
  | { type: 'OPEN_BOARD_NOTES' }
  | { type: 'UPDATE_BOARD_NOTES_INPUT_VALUE'; value: string }
  | { type: 'UPSERT_BOARD_NOTES' };

type ParentContextSubset = Pick<
  ParentContext,
  | 'applicationId'
  | 'status'
  | 'reviewStatus'
  | 'boardNotes'
  | 'boardBlocks'
  | 'boardMembers'
  | 'linkedAccounts'
  | 'logs'
>;

export type Context = ParentContextSubset & {
  contacts: {
    email: string;
    accountId?: string;
    name?: string;
    isPrimary?: boolean;
    checked: boolean;
  }[];
  addEmailInputValue: string;
  additionalEmails: string[];
  boardNotesInputValue: string;
};

export function createContext(parentContext: ParentContext): Context {
  const { linkedAccounts, contacts, primaryContact } = parentContext;
  return {
    ...parentContext,
    contacts: [
      ...linkedAccounts.map((account) => {
        const isPrimary = account.accountId === primaryContact.accountId;
        return {
          ...account,
          isPrimary,
          checked:
            isPrimary || contacts.some(({ email }) => account.email === email),
        };
      }),
      ...contacts
        .filter(
          (contact) =>
            !linkedAccounts.some(({ email }) => contact.email === email)
        )
        .map(({ email }) => ({
          email,
          checked: true,
        })),
    ],
    addEmailInputValue: '',
    additionalEmails: [],
    boardNotesInputValue: parentContext.boardNotes?.notes ?? '',
    logs: parentContext.logs,
  };
}

type Services = {
  addBoardBlock: {
    data: CreateAddBoardBlockPromiseResult;
  };
  removeBoardBlock: {
    data: CreateRemoveBoardBlockPromiseResult;
  };
  updateReviewStatus: {
    data: CreateUpdateApplicationReviewStatusPromiseResult;
  };
  resetStatus: {
    data: CreateResetApplicationStatusPromiseResult;
  };
  deny: {
    data: CreateDenyApplicationPromiseResult;
  };
  upsertBoardNotes: {
    data: CreateUpsertBoardNotesPromiseResult;
  };
  approveAndNotify: {
    data: CreateApproveAndNotifyPromiseResult;
  };
};

export const machine = createMachine(
  {
    id: 'reviewMachine',
    predictableActionArguments: true,
    tsTypes: {} as import('./ApplicationReviewMachine.typegen').Typegen0,
    schema: {
      context: {} as Context,
      events: {} as Events,
      services: {} as Services,
    },
    type: 'parallel',
    states: {
      status: {
        initial: 'initializing',
        states: {
          initializing: {
            always: [
              {
                target: 'approved',
                cond: 'isApproved',
              },
              {
                target: 'denied',
                cond: 'isDenied',
              },
              {
                target: 'none',
                cond: 'isSubmitted',
              },
            ],
          },
          approved: {
            on: {
              RESET_STATUS: [
                {
                  target: '.resetting',
                },
              ],
            },
            initial: 'default',
            states: {
              default: {},
              resetting: {
                invoke: {
                  src: 'resetApplication',
                  onDone: {
                    target: '#reviewMachine.status.none',
                    actions: ['resetStatus', 'sendRefreshApplication'],
                  },
                  onError: {
                    target: 'error',
                  },
                },
              },
              error: { entry: 'logMachineError' },
            },
          },
          denied: {
            on: {
              RESET_STATUS: [
                {
                  target: '.resetting',
                },
              ],
            },
            initial: 'default',
            states: {
              default: {},
              resetting: {
                invoke: {
                  src: 'resetApplication',
                  onDone: {
                    target: '#reviewMachine.status.none',
                    actions: ['resetStatus', 'sendRefreshApplication'],
                  },
                  onError: {
                    target: 'error',
                  },
                },
              },
              error: { entry: 'logMachineError' },
            },
          },
          none: {
            on: {
              DENY: [
                {
                  target: '.denying',
                },
              ],
              OPEN_APPROVAL_FORM: '.approvalConfirmation',
            },
            initial: 'default',
            states: {
              default: {},
              approvalConfirmation: {
                on: {
                  CLOSE_APPROVAL_FORM: '#reviewMachine.status.none',
                  TOGGLE_EMAIL: { actions: 'toggleEmail' },
                  OPEN_ADD_EMAIL_FORM: '.addEmailFormOpen',
                  CLOSE_ADD_EMAIL_FORM: '.default',
                  REMOVE_ADDITIONAL_EMAIL: {
                    actions: 'removeAdditionalEmail',
                  },
                  APPROVE_AND_NOTIFY: '.approvingAndNotifying',
                },
                initial: 'default',
                states: {
                  default: {
                    entry: 'clearEmailInput',
                  },
                  approvingAndNotifying: {
                    invoke: {
                      src: 'approveAndNotify',
                      onDone: {
                        actions: ['approve', 'sendRefreshApplication'],
                        target: '#reviewMachine.status.approved',
                      },
                      onError: 'approvingError',
                    },
                  },
                  approvingError: { entry: 'logMachineError' },
                  addEmailFormOpen: {
                    initial: 'noError',
                    on: {
                      UPDATE_ADD_EMAIL_INPUT_VALUE: {
                        actions: 'updateAddEmailInputValue',
                      },
                      BLUR_EMAIL_INPUT: [
                        {
                          cond: 'isEmailEmpty',
                          target: '.error.empty',
                        },
                        {
                          cond: 'isEmailInvalid',
                          target: '.error.value',
                        },
                      ],
                      SAVE_ADDITIONAL_EMAIL: [
                        {
                          cond: 'isEmailEmpty',
                          target: '.error.empty',
                        },
                        {
                          cond: 'isEmailInvalid',
                          target: '.error.value',
                        },
                        {
                          actions: 'addEmail',
                          target:
                            '#reviewMachine.status.none.approvalConfirmation.default',
                        },
                      ],
                    },
                    states: {
                      noError: {},
                      error: {
                        initial: 'value',
                        states: {
                          value: {},
                          empty: {},
                        },
                      },
                    },
                  },
                },
              },
              denying: {
                invoke: {
                  src: 'denyApplication',
                  onDone: {
                    target: '#reviewMachine.status.denied',
                    actions: ['deny', 'sendRefreshApplication'],
                  },
                  onError: {
                    target: 'error',
                  },
                },
              },
              error: { entry: 'logMachineError' },
            },
          },
        },
      },
      reviewStatus: {
        on: {
          UPDATE_REVIEW_STATUS: [
            {
              target: '.updating',
            },
          ],
        },
        initial: 'default',
        states: {
          default: {},
          updating: {
            invoke: {
              src: 'updateReviewStatus',
              onDone: {
                target: '#reviewMachine.reviewStatus.default',
                actions: ['updateContext'],
              },
              onError: {
                target: 'error',
              },
            },
          },
          error: { entry: 'logMachineError' },
        },
      },
      boardAccess: {
        on: {
          ADD_BOARD_BLOCK: [
            { cond: 'isAlreadyBlocked', target: '.default' },
            { target: '.addingBoardBlock' },
          ],
          REMOVE_BOARD_BLOCK: '.removingBoardBlock',
        },
        initial: 'default',
        states: {
          default: {},
          error: { entry: 'logMachineError' },
          removingBoardBlock: {
            invoke: {
              src: 'removeBoardBlock',
              onDone: {
                target: '#reviewMachine.boardAccess.default',
                actions: 'updateContext',
              },
              onError: 'error',
            },
          },
          addingBoardBlock: {
            invoke: {
              src: 'addBoardBlock',
              onDone: {
                target: '#reviewMachine.boardAccess.default',
                actions: 'updateContext',
              },
              onError: 'error',
            },
          },
        },
      },
      boardNotes: {
        initial: 'initializing',
        states: {
          initializing: {
            always: [
              {
                target: 'open',
                cond: 'hasBoardNotes',
              },
              {
                target: 'closed',
              },
            ],
          },
          closed: {
            on: {
              OPEN_BOARD_NOTES: 'open',
            },
          },
          open: {
            on: {
              UPSERT_BOARD_NOTES: '.upsertingBoardNotes',
              UPDATE_BOARD_NOTES_INPUT_VALUE: {
                actions: 'updateBoardNotesInputValue',
              },
            },
            initial: 'default',
            states: {
              default: {},
              upsertingBoardNotes: {
                invoke: {
                  src: 'upsertBoardNotes',
                  onDone: {
                    target: '#reviewMachine.boardNotes.initializing',
                    actions: 'updateContext',
                  },
                  onError: 'error',
                },
              },
              error: { entry: 'logMachineError' },
            },
          },
        },
      },
    },
  },
  {
    guards: {
      isSubmitted: (context) => context.status === ApplicationStatus.Submitted,
      isApproved: (context) => context.status === ApplicationStatus.Approved,
      isDenied: (context) => context.status === ApplicationStatus.Denied,
      isEmailEmpty: ({ addEmailInputValue }) => !addEmailInputValue,
      isEmailInvalid: ({ addEmailInputValue }) =>
        !isEmailValid(addEmailInputValue ?? ''),
      hasBoardNotes: ({ boardNotes }) =>
        Boolean(boardNotes && boardNotes.notes.length > 0),
      isAlreadyBlocked: ({ boardBlocks }, { accountId }) =>
        Boolean(boardBlocks?.some(({ accountId: id }) => id === accountId)),
    },
    actions: {
      logMachineError,
      updateContext: assign((_, { data }) => {
        return { ...data };
      }),
      toggleEmail: assign({
        contacts: (context, { email }) =>
          context.contacts.map((contact) => {
            if (email !== contact.email) {
              return contact;
            }
            return {
              ...contact,
              checked: !contact.checked,
            };
          }),
      }),
      updateAddEmailInputValue: assign((_, { value }) => {
        return { addEmailInputValue: value };
      }),
      updateBoardNotesInputValue: assign((_, { value }) => {
        return { boardNotesInputValue: value };
      }),
      clearEmailInput: assign({
        addEmailInputValue: (_) => '',
      }),
      addEmail: assign({
        additionalEmails: (context) => [
          ...context.additionalEmails,
          context.addEmailInputValue,
        ],
      }),
      removeAdditionalEmail: assign({
        additionalEmails: (context, { email }) =>
          context.additionalEmails.filter((value) => value !== email),
      }),
      resetStatus: assign((_) => {
        return { status: ApplicationStatus.Submitted };
      }),
      approve: assign((_) => {
        return { status: ApplicationStatus.Approved };
      }),
      deny: assign((_) => {
        return { status: ApplicationStatus.Denied };
      }),
      sendRefreshApplication: sendParent(refreshApplication()),
    },
  }
);

type Machine = typeof machine;

export type ApplicationReviewMachineState = StateFrom<Machine>;
export type ApplicationReviewMachineSender = Sender<EventFrom<Machine>>;
export type ApplicationReviewMachineOptions = MachineOptionsFrom<
  Machine,
  true
> & {
  context: Context;
};
export type ApplicationReviewMachineEvent = EventFrom<Machine>;
export type ApplicationReviewMachineActor = ActorRefFrom<Machine>;
