import { HowToApply } from '../CTA/HowToApply';
import { Newsletter } from '../CTA/Newsletter/Newsletter';

export function CTAShowcase() {
  return (
    <>
      <div className="showcase-label">CTA</div>
      <HowToApply />
      <Newsletter />
    </>
  );
}
