import { RoundController } from '../../../components/Admin/Rounds/RoundController';
import { RoundRow } from '../../../components/Admin/Rounds/RoundRow';
import { IndexPage } from '../../../components/IndexPage';
import { Loading } from '../../../components/Loading';
import {
  RoundsBlockMachineSender,
  RoundsBlockMachineState,
} from '../../../machines/Admin/RoundsBlockMachine';
import { ErrorPage } from '../../ErrorPage';

interface RoundsViewProps {
  state: RoundsBlockMachineState;
  send: RoundsBlockMachineSender;
}

export function RoundsView({ state, send }: RoundsViewProps) {
  if (state.matches('init')) {
    return <Loading />;
  }

  if (state.matches('error')) {
    return <ErrorPage />;
  }

  return (
    <IndexPage
      heading="Funding Rounds"
      breadcrumbs={[{ text: 'Admin Dashboard', to: '/admin' }]}
      buttonText="Add a New Round"
      buttonDisabled={state.context.openTourDate !== null}
      buttonOnClick={() => send({ type: 'OPEN_FUNDING_ROUND' })}
    >
      {state.context.openTourDate !== null && (
        <RoundController actor={state.context.openTourDate} />
      )}
      {state.context.extendedRounds.map(({ open, round, ref: actor }) =>
        open ? (
          <RoundController key={round.id} actor={actor} />
        ) : (
          <RoundRow
            key={round.id}
            round={round}
            open={() => send({ type: 'OPEN_FUNDING_ROUND', id: round.id })}
          />
        )
      )}
    </IndexPage>
  );
}
