import { Card } from '../../../components/Card';
import {
  AdminDashboardMachineSender,
  AdminDashboardMachineState,
} from '../../../machines/Admin/AdminDashboardMachine';
import { RoundCard } from '../../../components/Admin/Rounds/RoundCard';
import { match } from 'ts-pattern';
import { ErrorFeedback } from '../../../components/ErrorFeedback';
import { Loading } from '../../../components/Loading';
import { DirectDepositDownloadController } from '../../../components/Admin/DirectDeposit/DirectDepositDownloadController';
import { DirectDepositDownloadMachineActor } from '../../../machines/Admin/DirectDepositDownloadMachine';
import styles from './AdminDashboardView.module.scss';

interface AdminDashboardViewProps {
  state: AdminDashboardMachineState;
  send: AdminDashboardMachineSender;
}

export function AdminDashboardView({ state, send }: AdminDashboardViewProps) {
  if (state.matches('init')) {
    return <Loading />;
  }

  return (
    <div className={styles.container}>
      <h1>Admin Dashboard</h1>
      {match(state)
        .when(
          () => state.matches('ready'),
          () => (
            <div className={styles.roundList}>
              {state.context.recentActiveRounds.map((round) => (
                <RoundCard round={round} key={round.title} />
              ))}
            </div>
          )
        )
        .when(
          () => state.matches('error'),
          () => <ErrorFeedback />
        )
        .otherwise(() => null)}
      <div className={styles.cards}>
        <div className={styles.cardsFirstRow}>
          <Card
            icon="folder"
            title="Search Applications"
            display="row"
            shadow={false}
            linkTo="/admin/search-applications"
          />
          <Card
            icon="star"
            title="Search Artists"
            display="row"
            shadow={false}
            linkTo="/admin/artists"
          />
          <Card
            icon="calendar"
            title="Rounds"
            display="row"
            shadow={false}
            linkTo="/admin/rounds"
          />
          <Card
            icon="userCheck"
            title="Newsletter Subscribers"
            display="row"
            shadow={false}
            linkTo="/admin/subscribers"
          />
        </div>
        <div className={styles.cardsSecondRow}>
          <Card
            icon="user"
            title="Accounts"
            display="row"
            shadow={false}
            linkTo="/admin/accounts"
          />
          <Card icon="briefcase" title="Labels" display="row" shadow={false} />
          <Card
            icon="users"
            title="Board Members"
            display="row"
            shadow={false}
            linkTo="/admin/board-members"
          />
          <Card
            icon="bell"
            title="Orion Requests"
            display="row"
            shadow={false}
          />
        </div>
      </div>
      {state.matches('ready.directDepositFormClosed') && (
        <Card
          icon="download"
          title="Download Direct Deposit Information"
          display="row"
          shadow={false}
          onClick={() => send('OPEN_DIRECT_DEPOSIT_FORM')}
        />
      )}
      {state.matches('ready.directDepositFormOpen') &&
        state.children.directDepositExportRowMachine && (
          <DirectDepositDownloadController
            actor={
              state.children
                .directDepositExportRowMachine as DirectDepositDownloadMachineActor
            }
            onCancel={() => send('CLOSE_DIRECT_DEPOSIT_FORM')}
          />
        )}
    </div>
  );
}
