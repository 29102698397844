import { match } from 'ts-pattern';
import { ApplicationStatus } from '../../../graphql/operations';
import {
  ApplicationReviewMachineSender,
  ApplicationReviewMachineState,
} from '../../../machines/Admin/ApplicationReviewMachine';
import { ApplicationLogs } from './ApplicationLogs';
import { SecondaryControls } from './SecondaryControls';
import { ResetStatusControl } from './ResetStatusControl';
import { ApprovalConfirmation } from './ApprovalConfirmation';
import { NoStatusControls } from './NoStatusControls';

import styles from './ApplicationReviewView.module.scss';

interface Props {
  state: ApplicationReviewMachineState;
  send: ApplicationReviewMachineSender;
}

export function ApplicationReviewView({ state, send }: Props) {
  return (
    <div className={styles.container}>
      {state.context.status !== ApplicationStatus.InProgress && (
        <>
          <div className={styles.primaryControls}>
            {match(state)
              .when(
                () => state.matches('status.none.approvalConfirmation'),
                () => <ApprovalConfirmation state={state} send={send} />
              )
              .when(
                () => state.matches('status.none'),
                () => <NoStatusControls state={state} send={send} />
              )
              .when(
                () => state.matches('status.approved'),
                () => (
                  <ResetStatusControl
                    status="Approved"
                    send={send}
                    disabled={state.matches('status.approved.resetting')}
                  />
                )
              )
              .when(
                () => state.matches('status.denied'),
                () => (
                  <ResetStatusControl
                    status="Denied"
                    send={send}
                    disabled={state.matches('status.denied.resetting')}
                  />
                )
              )
              .otherwise(() => null)}
          </div>
          <SecondaryControls state={state} send={send} />
        </>
      )}
      <div className={styles.controlsSection}>
        {state.context.logs.length > 0 && (
          <ApplicationLogs logs={state.context.logs} />
        )}
      </div>
    </div>
  );
}
