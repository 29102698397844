import cn from 'classnames';
import styles from './FilterButton.module.scss';

interface FilterButtonProps {
  label: string;
  enabled: boolean;
  onClick: () => void;
}

export function FilterButton({ label, enabled, onClick }: FilterButtonProps) {
  return (
    <button
      type="button"
      className={cn(styles.filterButton, {
        [styles.enabled]: enabled,
      })}
      onClick={onClick}
    >
      {label}
    </button>
  );
}
