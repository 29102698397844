import { useMachine } from '@xstate/react';
import { ApplicationSearchView } from './ApplicationSearchView';
import {
  machine,
  ApplicationSearchMachineOptions,
} from '../../machines/Admin/ApplicationSearchMachine';
import { useInitialWindowSize } from '../../hooks/useWindowSize';

type Props = {
  options: ApplicationSearchMachineOptions;
  fundingRoundTitles: string[];
  isBoardView?: boolean;
  boardRound?: string;
  queryRound?: string;
};

export function ApplicationSearchController({
  options,
  fundingRoundTitles,
  isBoardView,
  boardRound,
  queryRound,
}: Props) {
  const { screen } = useInitialWindowSize();

  const [state, send] = useMachine(machine, {
    ...options,
    context: {
      searchVariables: {
        resultLimit: screen === 'desktop' ? 20 : 10,
        resultOffset: 0,
        roundTitle: isBoardView ? boardRound : queryRound,
      },
    },
  });

  return (
    <ApplicationSearchView
      state={state}
      send={send}
      roundTitles={fundingRoundTitles}
      isMobile={screen !== 'desktop'}
      isBoardView={isBoardView}
      boardRound={boardRound}
    />
  );
}
