import type { EventFrom, MachineOptionsFrom, Sender } from 'xstate';
import { assign, createMachine } from 'xstate';
import type { StateFrom } from '../../utils/StateFrom';
import { GetAdminDashboardContextPromiseResult } from '../../promises/Admin/createGetAdminDashboardContext';
import { logMachineError } from '../../utils/logError';

type Services = {
  getAdminDashboardContextPromise: {
    data: GetAdminDashboardContextPromiseResult;
  };
};

type Events =
  | { type: 'OPEN_DIRECT_DEPOSIT_FORM' }
  | { type: 'CLOSE_DIRECT_DEPOSIT_FORM' };

export type Context = GetAdminDashboardContextPromiseResult;

export const machine = createMachine(
  {
    predictableActionArguments: true,
    tsTypes: {} as import('./AdminDashboardMachine.typegen').Typegen0,
    id: 'adminDashboard',
    schema: {
      events: {} as Events,
      context: {} as Context,
      services: {} as Services,
    },
    initial: 'init',
    states: {
      init: {
        invoke: {
          id: 'getAdminDashboardContextPromise',
          src: 'getAdminDashboardContextPromise',
          onDone: {
            actions: 'setContext',
            target: 'ready',
          },
          onError: 'error',
        },
      },
      error: { entry: 'logMachineError' },
      ready: {
        initial: 'directDepositFormClosed',
        states: {
          directDepositFormClosed: {
            on: {
              OPEN_DIRECT_DEPOSIT_FORM: 'directDepositFormOpen',
            },
          },
          directDepositFormOpen: {
            on: {
              CLOSE_DIRECT_DEPOSIT_FORM: 'directDepositFormClosed',
            },
            invoke: {
              id: 'directDepositExportRowMachine',
              src: 'directDepositExportRowMachine',
              data: (context) => ({
                fundingRounds: context.allRounds,
                fundingRoundId: context.allRounds[0]?.id,
              }),
            },
          },
        },
      },
    },
  },
  {
    actions: { logMachineError, setContext: assign((_, { data }) => data) },
  }
);

type Machine = typeof machine;

export type AdminDashboardMachineState = StateFrom<Machine>;
export type AdminDashboardMachineSender = Sender<EventFrom<Machine>>;
export type AdminDashboardMachineOptions = MachineOptionsFrom<Machine, true>;
