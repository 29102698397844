import { useNavigate, useParams } from 'react-router-dom';
import { machine as DirectDepositMachine } from '../../machines/ClaimFunds/DirectDepositMachine';
import { machine as DirectDepositsMachine } from '../../machines/ClaimFunds/DirectDepositsMachine';
import { machine as SignedContractMachine } from '../../machines/ClaimFunds/SignedContractMachine';
import { machine as TourSummaryMachine } from '../../machines/ClaimFunds/TourSummaryMachine';
import { machine as SetDepositInfoMachine } from '../../machines/ClaimFunds/SetDepositInfoMachine';
import { machine as UpsertPaymentMachine } from '../../machines/ClaimFunds/UpsertPaymentMachine';
import { machine as ApplicationReviewMachine } from '../../machines/Admin/ApplicationReviewMachine';
import { machine as ReopenClaimsMachine } from '../../machines/Admin/ReopenClaimsMachine';
import { machine as FundingRequestsBlockMachine } from '../../machines/components/FundingRequestsBlockMachine';
import { machine as TourDatesBlockMachine } from '../../machines/components/TourDatesBlockMachine';
import { machine as AttachmentsBlockMachine } from '../../machines/components/AttachmentsBlockMachine';
import { machine as ApplicationStatementBlockMachine } from '../../machines/components/ApplicationStatementBlockMachine';
import { machine as ContactsMachine } from '../../machines/components/ContactsBlockMachine';
import {
  machine as ExpenseBlockMachine,
  ExpenseBlockMachineWithImplementations,
} from '../../machines/components/ExpenseBlockMachine';
import { ApplicationController } from './ApplicationController';
import { createGetApplicationPromise } from '../../promises/createGetApplicationPromise';
import { useGraphQLClient } from '../../components/GraphQLClientProvider';
import { createAddFundingRequestPromise } from '../../promises/FundingRequests/createAddFundingRequestPromise';
import { createDeleteFundingRequestPromise } from '../../promises/FundingRequests/createDeleteFundingRequest';
import { createUpdateFundingRequestPromise } from '../../promises/FundingRequests/createUpdateFundingRequestPromise';
import { createAddContactPromise } from '../../promises/contacts/createAddContactPromise';
import { createDeleteContactPromise } from '../../promises/contacts/createDeleteContactPromise';
import { createUpdateApplicationPromise } from '../../promises/createUpdateApplicationPromise';
import { createAddTourDatePromise } from '../../promises/TourDates/createAddTourDatePromise';
import { createDeleteTourDatePromise } from '../../promises/TourDates/createDeleteTourDatePromise';
import { createUpdateTourDatePromise } from '../../promises/TourDates/createUpdateTourDatePromise';
import { createGetFilePromise } from '../../promises/Attachments/createGetFilePromise';
import { createUploadFilePromise } from '../../promises/Attachments/createUploadFilePromise';
import { createDeleteFilePromise } from '../../promises/Attachments/createDeleteFilePromise';
import { createSubmitApplicationPromise } from '../../promises/createSubmitApplicationPromise';
import { createSubmitApplicationClaimsPromise } from '../../promises/createSubmitApplicationClaimsPromise';
import { createAddBoardBlockPromise } from '../../promises/Admin/createAddBoardBlockPromise';
import { createRemoveBoardBlockPromise } from '../../promises/Admin/createRemoveBoardBlockPromise';
import { createUpsertBoardNotesPromise } from '../../promises/Admin/createUpsertBoardNotesPromise';
import { createApproveAndNotifyPromise } from '../../promises/Admin/createApproveAndNotifyPromise';
import {
  createDenyApplicationPromise,
  createResetApplicationStatusPromise,
  createUpdateApplicationReviewStatusPromise,
} from '../../promises/Admin/applicationReviewPromises';
import { useIsAdmin } from '../../hooks/useIsAdmin';
import { useIsBoard } from '../../hooks/useIsBoard';
import { createSaveApplicationDirectDepositInfo } from '../../promises/saveApplicationDirectDepositInfo';
import { withDelay } from '../../utils/withDelay';
import { createCancelTourDatePromise } from '../../promises/TourDates/createCancelTourDatePromise';
import { createCancelFundingRequestPromise } from '../../promises/FundingRequests/createCancelFundingRequestPromise';
import { createUpsertPayment } from '../../promises/upsertPaymentPromise';
import { createDeletePaymentPromise } from '../../promises/deletePaymentPromise';
import { createAddExpensePromise } from '../../promises/FundingRequests/Expenses/createAddExpensePromise';
import { createDeleteExpensePromise } from '../../promises/FundingRequests/Expenses/createDeleteExpensePromise';
import { createUpdateExpensePromise } from '../../promises/FundingRequests/Expenses/createUpdateExpensePromise';
import { createReopenClaimsPromise } from '../../promises/Admin/createReopenClaimsPromise';
import { createUpdateReopenClaimsNotesPromise } from '../../promises/Admin/createUpdateReopenClaimsNotesPromise';
import { createResubmitApplicationClaimsPromise } from '../../promises/createResubmitApplicationClaimsPromise';
import { createCompleteApplicationPromise } from '../../promises/createCompleteApplicationPromise';

export const Application = () => {
  const client = useGraphQLClient();
  const { applicationId, artistId } = useParams();
  const navigate = useNavigate();
  const isAdmin = useIsAdmin();
  const isBoard = useIsBoard();

  if (!applicationId || !artistId) {
    navigate('/dashboard');
    return null;
  }

  return (
    <ApplicationController
      options={{
        actions: {
          navigateToDashboard: () => navigate(`/artist/${artistId}`),
          scrollToTop: () => window.scrollTo(0, 0),
        },
        services: {
          getApplication: withDelay(
            createGetApplicationPromise(client, applicationId, isAdmin, isBoard)
          ),
          submitApplication: createSubmitApplicationPromise(client),
          submitClaims: createSubmitApplicationClaimsPromise(client),
          resubmitClaims: createResubmitApplicationClaimsPromise(client),
          completeApplication: createCompleteApplicationPromise(client),
          directDepositsMachine: DirectDepositsMachine.withConfig({
            services: {
              directDepositMachine: DirectDepositMachine.withConfig({
                services: {
                  setDepositInfoMachine: SetDepositInfoMachine.withConfig({
                    services: {
                      saveApplicationDirectDepositInfo:
                        createSaveApplicationDirectDepositInfo(client),
                    },
                  }),
                  upsertPaymentMachine: UpsertPaymentMachine.withConfig({
                    services: {
                      upsertPayment: createUpsertPayment(client),
                    },
                  }),
                  deletePayment: createDeletePaymentPromise(client),
                },
              }),
            },
          }),
          signedContractMachine: SignedContractMachine.withConfig({
            actions: {
              openAttachment: (_, event) => window.open(event.data),
            },
            services: {
              getFilePromise: createGetFilePromise({
                directory: 'signed-contracts',
              }),
              uploadFilePromise: createUploadFilePromise({
                directory: 'signed-contracts',
              }),
              deleteFilePromise: createDeleteFilePromise({
                directory: 'signed-contracts',
              }),
            },
          }),
          tourSummaryMachine: TourSummaryMachine.withConfig({
            actions: {
              openAttachment: (_, event) => window.open(event.data),
            },
            services: {
              getFilePromise: createGetFilePromise({
                directory: 'tour-summaries',
              }),
              uploadFilePromise: createUploadFilePromise({
                directory: 'tour-summaries',
              }),
              deleteFilePromise: createDeleteFilePromise({
                directory: 'tour-summaries',
              }),
            },
          }),
          adminApplicationReview: ApplicationReviewMachine.withConfig({
            services: {
              addBoardBlock: createAddBoardBlockPromise(client),
              removeBoardBlock: createRemoveBoardBlockPromise(client),
              denyApplication: createDenyApplicationPromise(client),
              resetApplication: createResetApplicationStatusPromise(client),
              updateReviewStatus:
                createUpdateApplicationReviewStatusPromise(client),
              upsertBoardNotes: createUpsertBoardNotesPromise(client),
              approveAndNotify: createApproveAndNotifyPromise(client),
            },
          }),
          reopenClaimsMachine: ReopenClaimsMachine.withConfig({
            services: {
              reopenClaims: createReopenClaimsPromise(client),
              saveNotes: createUpdateReopenClaimsNotesPromise(client),
            },
          }),
          fundingRequestsMachine: FundingRequestsBlockMachine.withConfig({
            services: {
              addFundingRequest: createAddFundingRequestPromise(client),
              deleteFundingRequest: createDeleteFundingRequestPromise(client),
              updateFundingRequest: createUpdateFundingRequestPromise(client),
              cancelFundingRequest: createCancelFundingRequestPromise(client),
            },
          }),
          tourDatesMachine: TourDatesBlockMachine.withConfig({
            services: {
              addTourDate: createAddTourDatePromise(client),
              deleteTourDate: createDeleteTourDatePromise(client),
              updateTourDate: createUpdateTourDatePromise(client),
              cancelTourDate: createCancelTourDatePromise(client),
            },
          }),
          attachmentsMachine: AttachmentsBlockMachine.withConfig({
            actions: {
              openAttachment: (_, event) => window.open(event.data),
            },
            services: {
              getFilePromise: createGetFilePromise({
                directory: 'general-attachments',
              }),
              uploadFilePromise: createUploadFilePromise({
                directory: 'general-attachments',
              }),
              deleteFilePromise: createDeleteFilePromise({
                directory: 'general-attachments',
              }),
            },
          }),
          applicationStatementMachine:
            ApplicationStatementBlockMachine.withConfig({
              services: {
                updateApplication: createUpdateApplicationPromise(client),
              },
            }),
          contactsBlockMachine: ContactsMachine.withConfig({
            services: {
              addContact: createAddContactPromise(client),
              deleteContact: createDeleteContactPromise(client),
              updateApplication: createUpdateApplicationPromise(client),
            },
          }),
          createExpenseBlockMachine: () => {
            return new Promise((resolve) => {
              resolve(
                ExpenseBlockMachine.withConfig({
                  services: {
                    createAddExpensePromise: createAddExpensePromise(client),
                    createDeleteExpensePromise:
                      createDeleteExpensePromise(client),
                    createUpdateExpensePromise:
                      createUpdateExpensePromise(client),
                  },
                }) as ExpenseBlockMachineWithImplementations
              );
            });
          },
        },
      }}
    />
  );
};
