import type {
  ActorRefFrom,
  EventFrom,
  MachineOptionsFrom,
  Sender,
} from 'xstate';
import { assign, createMachine, spawn } from 'xstate';
import type { StateFrom } from '../../utils/StateFrom';
import { CreateGetFundingRoundsPromiseResult } from '../../promises/Admin/createGetFundingRoundsPromise';
import { RoundMachineActor, machine as RoundMachine } from './RoundMachine';
import { AddFundingRoundPromiseResult } from '../../promises/fundingRounds/createAddFundingRoundPromise';
import { UpdateFundingRoundPromiseResult } from '../../promises/fundingRounds/createUpdateFundingRoundPromise';
import { logMachineError } from '../../utils/logError';
import {
  FundingRound,
  PartialFundingRound,
  completeFundingRoundSchema,
} from '../../schemas/fundingRound/fundingRoundSchema';
import { AddFundingRound } from '../../events/AddFundingRound';
import { UpdateFundingRound } from '../../events/UpdateFundingRound';
import { CloseFundingRound } from '../../events/CloseFundingRound';

type Events =
  | { type: 'OPEN_FUNDING_ROUND'; id?: FundingRound['id'] }
  | AddFundingRound
  | UpdateFundingRound
  | CloseFundingRound;

type ExtendedRound = {
  round: FundingRound;
  open: boolean;
  ref: RoundMachineActor;
};

type Services = {
  getFundingRoundsPromise: {
    data: CreateGetFundingRoundsPromiseResult;
  };
  addFundingRoundPromise: {
    data: AddFundingRoundPromiseResult;
  };
  updateFundingRoundPromise: {
    data: UpdateFundingRoundPromiseResult;
  };
};

export type Context = {
  rounds: FundingRound[];
  extendedRounds: ExtendedRound[];
  openTourDate: RoundMachineActor | null;
  timezone: string;
};

const createRef = (
  id: string | undefined,
  round: PartialFundingRound
): RoundMachineActor =>
  spawn(
    RoundMachine.withConfig({
      services: {
        validateCompleteFundingRound: ({ round }) =>
          completeFundingRoundSchema.parseAsync(round),
      },
    }).withContext({ round, id })
  );

const createExtendedRound = (round: FundingRound): ExtendedRound => {
  return {
    round,
    open: false,
    ref: createRef(round.id, round),
  };
};

const createNewRound = (timezone: string) => {
  return createRef(undefined, {
    title: '',
    timezone,
    startDate: null,
    endDate: null,
    boardStartDate: null,
    boardEndDate: null,
    boardMeetingDate: null,
    allowOrion: null,
  });
};

export const machine = createMachine(
  {
    predictableActionArguments: true,
    tsTypes: {} as import('./RoundsBlockMachine.typegen').Typegen0,
    id: 'form',
    schema: {
      context: {} as Context,
      events: {} as Events,
      services: {} as Services,
    },
    initial: 'init',
    states: {
      init: {
        invoke: {
          id: 'getFundingRoundsPromise',
          src: 'getFundingRoundsPromise',
          onDone: {
            actions: 'setContext',
            target: 'ready',
          },
          onError: 'error',
        },
      },
      error: { entry: 'logMachineError' },
      ready: {
        initial: 'default',
        states: {
          default: {},
          add: {
            invoke: {
              id: 'addFundingRoundPromise',
              src: 'addFundingRoundPromise',
              onDone: {
                actions: 'addRound',
                target: 'default',
              },
              onError: '#form.error',
            },
          },
          update: {
            invoke: {
              id: 'updateFundingRoundPromise',
              src: 'updateFundingRoundPromise',
              onDone: {
                actions: 'updateRound',
                target: 'default',
              },
              onError: '#form.error',
            },
          },
        },
        on: {
          OPEN_FUNDING_ROUND: {
            actions: 'openFundingRound',
          },
          CLOSE_FUNDING_ROUND: {
            actions: 'closeFundingRound',
          },
          ADD_FUNDING_ROUND: '.add',
          UPDATE_FUNDING_ROUND: '.update',
        },
      },
    },
  },
  {
    actions: {
      logMachineError,
      setContext: assign((_, { data: { rounds, timezone } }) => ({
        rounds,
        timezone,
        openTourDate: null,
        extendedRounds: rounds.map((round) => createExtendedRound(round)),
      })),
      openFundingRound: assign(
        ({ timezone, openTourDate, extendedRounds }, { id }) => ({
          extendedRounds: extendedRounds.map((e) => ({
            ...e,
            open: e.round.id === id ? true : e.open,
          })),
          openTourDate:
            id === undefined && openTourDate === null
              ? createNewRound(timezone)
              : openTourDate,
        })
      ),
      closeFundingRound: assign(({ openTourDate, extendedRounds }, { id }) => ({
        extendedRounds: extendedRounds.map((e) => ({
          ...e,
          open: e.round.id === id ? false : e.open,
        })),
        openTourDate: id === undefined ? null : openTourDate,
      })),
      addRound: assign(({ rounds, extendedRounds }, { data: round }) => ({
        openTourDate: null,
        rounds: [round, ...rounds],
        extendedRounds: [createExtendedRound(round), ...extendedRounds],
      })),
      updateRound: assign(({ rounds, extendedRounds }, { data: round }) => ({
        rounds: rounds.map((r) => (round.id === r.id ? round : r)),
        extendedRounds: extendedRounds.map((e) =>
          round.id === e.round.id ? createExtendedRound(round) : e
        ),
      })),
    },
  }
);

type Machine = typeof machine;

export type RoundsBlockMachineState = StateFrom<Machine>;
export type RoundsBlockMachineSender = Sender<EventFrom<Machine>>;
export type RoundsBlockMachineOptions = MachineOptionsFrom<Machine, true>;
export type RoundsBlockMachineActor = ActorRefFrom<Machine>;
