import { Header } from '../Header';

import styles from './HeaderShowcase.module.scss';

export function HeaderShowcase() {
  return (
    <div id="Header" className={styles.container}>
      <Header />
    </div>
  );
}
