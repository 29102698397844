import { z } from 'zod';
import { ApplicationType } from '../../graphql/operations';

export const directDepositExportRow = z.tuple([
  z.string(),
  z.nativeEnum(ApplicationType),
  z.string(),
  z.enum(['touring', 'digital-content', 'funding-request']),
  z.string(),
  z.string(),
  z.string(),
  z.string(),
]);

export type DirectDepositExportRow = z.infer<typeof directDepositExportRow>;
