import { GraphQLClient } from 'graphql-request';
import { GetAdminDashboardContextDocument } from '../../graphql/operations';
import { adminDashboardContextSchema } from '../../schemas/admin/adminDashboardContextSchema';

export const createGetAdminDashboardContextPromise =
  (client: GraphQLClient) => async () => {
    const result = await client.request(GetAdminDashboardContextDocument);

    return adminDashboardContextSchema.parse(result);
  };

export type GetAdminDashboardContextPromise = ReturnType<
  typeof createGetAdminDashboardContextPromise
>;

export type GetAdminDashboardContextPromiseResult = Awaited<
  ReturnType<GetAdminDashboardContextPromise>
>;
