import { useMachine } from '@xstate/react';

import { machine } from '../../machines/PaginationShowcaseMachine';
import { Pagination } from '../Pagination';

import styles from './PaginationShowcase.module.scss';

export function PaginationShowcase() {
  const [state, send] = useMachine(machine);

  return (
    <div>
      <span className="showcase-label">Pagination</span>
      <Pagination steps={state.context.steps} active={state.context.active} />
      <div className={styles.buttons}>
        <button
          type="button"
          className={styles.button}
          onClick={() => send('INCREASE')}
        >
          Increase
        </button>
        <button
          type="button"
          className={styles.button}
          onClick={() => send('DECREASE')}
        >
          Decrease
        </button>
        <button
          type="button"
          className={styles.button}
          onClick={() => send('ADD_STEP')}
        >
          Add Step
        </button>
        <button
          type="button"
          className={styles.button}
          onClick={() => send('REMOVE_STEP')}
        >
          Remove Step
        </button>
      </div>
    </div>
  );
}
