export function scrollElementIntoView(id: string, highlight?: boolean) {
  setTimeout(() => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView();
      if (highlight) {
        element.classList.remove('container-highlight');
        element.classList.add('container-highlight');
      }
    }
  }, 0);
}
