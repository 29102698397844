import { ProgressIcon } from '../ProgressIcon';

import styles from './ProgressIconShowcase.module.scss';

export function ProgressIconShowcase() {
  return (
    <div className={styles.container}>
      <div className="showcase-label">Progress Icons</div>
      <div className={styles.content}>
        <ProgressIcon icon="upload" status="active" />
        <ProgressIcon icon="upload" status="completed" />
        <ProgressIcon icon="upload" status="disabled" />
        <ProgressIcon icon="upload" status="waiting" />
      </div>
    </div>
  );
}
