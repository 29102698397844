import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from '@tanstack/react-table';

import styles from './Table.module.scss';

interface AccountsTableProps {
  data: Account[];
}

interface Account {
  artist: string;
  email: string;
}

const columnHelper = createColumnHelper<Account>();

export function AccountsTable({ data }: AccountsTableProps) {
  const columns = [
    columnHelper.accessor('artist', {
      header: 'Artist',
      cell: (info) => info.renderValue(),
    }),
    columnHelper.accessor('email', {
      header: 'Email',
      cell: (info) => info.renderValue(),
    }),
  ];

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
  });

  return (
    <table>
      <thead className={styles.tableHeader}>
        {table.getHeaderGroups().map((headerGroup) => (
          <tr key={headerGroup.id}>
            {headerGroup.headers.map((header) => (
              <th key={header.id}>
                {flexRender(
                  header.column.columnDef.header,
                  header.getContext()
                )}
              </th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody className={styles.tableBody}>
        {table.getRowModel().rows.map((row) => (
          <tr key={row.id}>
            {row.getVisibleCells().map((cell) => (
              <td key={cell.id}>
                {flexRender(cell.column.columnDef.cell, cell.getContext())}
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  );
}
