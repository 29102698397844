import {
  CalendarDate,
  CalendarDateTime,
  toZoned,
} from '@internationalized/date';
import {
  ApplicationStage,
  ApplicationStatus,
  ApplicationType,
} from '../../graphql/operations';
import { ActiveApplicationCard } from '../ActiveApplicationCard';
import styles from './ActiveApplicationCardShowcase.module.scss';

const timezone = 'America/Toronto';

const application = {
  id: 'abc',
  timezone,
  status: ApplicationStatus.InProgress,
  fundingRound: {
    title: '85',
    id: '123',
    boardMeetingDate: new CalendarDate(2024, 4, 1),
    claimDeadline: toZoned(new CalendarDateTime(2024, 8, 1), timezone),
  },
  createdAt: toZoned(new CalendarDateTime(2024, 1, 1), timezone),
  submittedAt: null,
  totalNumberOfTourDates: 10,
  totalNumberOfFundingRequests: 0,
  requestedTotal: 4500,
  approvedTotal: 0,
  type: ApplicationType.Core,
  stage: ApplicationStage.Unsubmitted,
  totalPaid: 0,
  reopenClaimsNotes: null,
  claimsResubmissionDeadline: null,
};

export function ActiveApplicationCardShowcase() {
  return (
    <>
      <div className="showcase-label">Active Application Card</div>
      <div className={styles.container}>
        <ActiveApplicationCard application={application} applicationLink="/" />
        <ActiveApplicationCard
          application={{ ...application, status: ApplicationStatus.Approved }}
          applicationLink="/"
        />
      </div>
    </>
  );
}
