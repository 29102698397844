import type {
  ActorRefFrom,
  EventFrom,
  MachineOptionsFrom,
  Sender,
} from 'xstate';
import { assign, createMachine } from 'xstate';
import type { StateFrom } from '../../utils/StateFrom';
import { CreateGetDirectDepositExportRowsPromiseResult } from '../../promises/Admin/createGetDirectDepositExportRows';
import { FundingRound } from '../../schemas/fundingRound/fundingRoundSchema';
import { logMachineError } from '../../utils/logError';

type Services = {
  getDirectDepositExportRows: {
    data: CreateGetDirectDepositExportRowsPromiseResult;
  };
};

type Events =
  | { type: 'SET_PRIVATE_KEY'; privateKey: string }
  | { type: 'UPDATE_SELECTED_FUNDING_ROUND_ID'; id?: string }
  | { type: 'DOWNLOAD' };

export type Context = {
  fundingRounds: Array<Pick<FundingRound, 'id' | 'title'>>;
  fundingRoundId?: string;
  privateKey?: string;
};

export const machine = createMachine(
  {
    predictableActionArguments: true,
    tsTypes: {} as import('./DirectDepositDownloadMachine.typegen').Typegen0,
    id: 'directDepositDownloadMachine',
    schema: {
      events: {} as Events,
      context: {} as Context,
      services: {} as Services,
    },
    initial: 'idle',
    states: {
      idle: {
        on: {
          SET_PRIVATE_KEY: { actions: 'setPrivateKey' },
          UPDATE_SELECTED_FUNDING_ROUND_ID: { actions: 'setFundingRound' },
          DOWNLOAD: 'downloading',
        },
      },
      downloading: {
        invoke: {
          src: 'getDirectDepositExportRows',
          onDone: { actions: 'downloadCsv', target: 'idle' },
          onError: { target: 'error', actions: 'logMachineError' },
        },
      },
      error: {},
    },
  },
  {
    actions: {
      setPrivateKey: assign({ privateKey: (_, event) => event.privateKey }),
      setFundingRound: assign({ fundingRoundId: (_, event) => event.id }),
      downloadCsv: (context, event) => {
        const fundingRound = context.fundingRounds.find(
          (round) => round.id === context.fundingRoundId
        );

        const csvContent =
          'data:text/csv;charset=utf-8,' +
          'Artist,Application Type,Round,Deposit Type,Account Name,Institution,Transit Number,Account Number\n' +
          event.data.map((e) => e.join(',')).join('\n');

        const encodedUri = encodeURI(csvContent);

        // Need to create a link to give the file a name.
        const link = document.createElement('a');
        link.href = encodedUri;
        link.download = `Direct Deposit Info for Round ${
          fundingRound?.title ?? ''
        }.csv`;
        link.click();
      },
      logMachineError,
    },
  }
);

type Machine = typeof machine;

export type DirectDepositDownloadMachineState = StateFrom<Machine>;
export type DirectDepositDownloadMachineSender = Sender<EventFrom<Machine>>;
export type DirectDepositDownloadMachineOptions = MachineOptionsFrom<
  Machine,
  true
>;
export type DirectDepositDownloadMachineActor = ActorRefFrom<Machine>;
