import { PopupMenu } from '../PopupMenu';

import styles from './PopupMenuShowcase.module.scss';

export function PopupMenuShowcase() {
  return (
    <div>
      <span className="showcase-label">Popup Menu</span>
      <div className={styles.container}>
        <PopupMenu label="showcase" options={[{ label: 'click', href: '/' }]} />
      </div>
    </div>
  );
}
