export function TextShowcase() {
  return (
    <div className="text-showcase">
      <span className="showcase-label">{`<h1> Page Heading`}</span>
      <h1>Sed Sit Amet Eleifend Lectus, Sed Consequat?</h1>
      <span className="showcase-label">{`<h2> Section Heading`}</span>
      <h2>Sed sollicitudin libero quis nullam odio viverra.</h2>
      <span className="showcase-label">{`<h3> Subsection Heading`}</span>
      <h3>Aliquam semper vestibulum neque, non congue arcu laoreet vitae.</h3>
      <span className="showcase-label">{`<h4> Item Heading`}</span>
      <h4>In nec mauris tempus, placerat erat ac, scelerisque mi.</h4>
      <span className="showcase-label">{`<p> Body Text`}</span>
      <p>
        Aenean id diam metus. Ut urna urna, congue a arcu sed, mattis feugiat
        quam. Phasellus facilisis tortor et ex suscipit, id eleifend leo auctor.
        Aliquam semper vestibulum neque, non congue arcu laoreet vitae. In nec
        mauris tempus, placerat erat ac, scelerisque mi. Integer posuere aliquam
        quam, egestas tristique metus rutrum id. Nulla ac urna quam.
      </p>
      <span className="showcase-label">{`<ul> Unordered List`}</span>
      <ul>
        <li>Vivamus tristique ante sit amet porttitor.</li>
        <li>Duis condimentum varius aliquet.</li>
        <li>
          Phasellus euismod vehicula sollicitudin. Suspendisse hendrerit nec
          tellus placerat.
        </li>
        <li>
          Aenean id diam metus. Ut urna urna, congue a arcu sed, mattis feugiat
          quam.
        </li>
      </ul>
      <span className="showcase-label">{`<ol> Ordered List`}</span>
      <ol>
        <li>
          Phasellus facilisis tortor et ex suscipit, id eleifend leo auctor.
        </li>
        <li>Aliquam semper vestibulum neque, non congue arcu vitae.</li>
        <li>In nec mauris tempus, placerat erat ac, scelerisque mi.</li>
        <li>
          Integer posuere aliquam quam, egestas tristique metus rutrum id. Nulla
          ac urna quam.
        </li>
      </ol>
    </div>
  );
}
