import React, { useState } from 'react';
import { FormField } from '../FormField';
import { Select } from '../Select';
import { TextInput } from '../TextInput';

import styles from './FormFieldShowcase.module.scss';

export function FormFieldShowcase() {
  const [email, setEmail] = useState('');

  return (
    <div className={styles.container}>
      <span className="showcase-label">FormField</span>
      <FormField
        id="form-field-months-toggle-button"
        label="Form Field Select Dropdown"
        description="Lorem ipsum blah blah"
        infoLabel="Lorem ipsum dolor sit amet"
        tooltipContent="tooltip content"
        formFieldStatus="default"
        note="required"
      >
        <Select
          id="form-field-months"
          label="Select"
          items={['January']}
          itemToKey={(item) => item}
          selectedItem={null}
          getItemText={(item) => item}
          onChange={() => null}
        />
      </FormField>
      <FormField
        id="form-field-email"
        label="Form Field Text Input"
        infoLabel="Not required"
        description="default"
        tooltipContent="tooltip content"
      >
        <TextInput
          id="form-field-email"
          value={email}
          onChange={setEmail}
          label="user@email.com"
        />
      </FormField>
      <FormField
        id="form-field-email"
        label="Form Field Text Input"
        infoLabel="Not required"
        description="text"
        tooltipContent="tooltip content"
        formFieldStatus="text"
      >
        <TextInput
          id="form-field-email"
          value={email}
          onChange={setEmail}
          label="user@email.com"
        />
      </FormField>
      <FormField
        id="form-field-email"
        label="Form Field Text Input"
        infoLabel="Not required"
        description="error"
        tooltipContent="tooltip content"
        formFieldStatus="error"
        note="this is a note"
      >
        <TextInput
          id="form-field-email"
          value={email}
          onChange={setEmail}
          label="user@email.com"
        />
      </FormField>
    </div>
  );
}
