import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from '@tanstack/react-table';
import { useWindowSize } from '../../hooks/useWindowSize';
import { Icon } from '../Icon';

import { Link } from '../Link';

import styles from './Table.module.scss';

export interface Release {
  title: string;
  label: string;
  date: string;
  status: 'eligible' | 'ineligible';
  href: string;
}

interface ReleaseTableProps {
  data: Release[];
  showHeader: boolean;
}

const columnHelper = createColumnHelper<Release>();

export function ReleaseTable({ data, showHeader }: ReleaseTableProps) {
  const { screen } = useWindowSize();

  const columns = [
    columnHelper.accessor('title', {
      header: 'Title',
      cell: (info) => (
        <Link href={info.row.original.href}>{info.getValue()}</Link>
      ),
      footer: (info) => info.column.id,
    }),
    ...(screen === 'desktop'
      ? [
          columnHelper.accessor('label', {
            header: 'Label',
            footer: (info) => info.column.id,
          }),
          columnHelper.accessor('date', {
            header: 'Release Date',
            footer: (info) => info.column.id,
          }),
        ]
      : []),
    columnHelper.accessor('status', {
      header: () => (
        <div className={styles.row}>
          <span className={styles.bold}>Status</span>
          <Icon name="checkCircle" />
        </div>
      ),
      cell: (info) => (
        <div className={styles.row}>
          <span className={styles.bold}>{info.getValue()}</span>
          <Icon name="checkCircle" />
        </div>
      ),
      footer: (info) => info.column.id,
    }),
  ];

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
  });

  return (
    <table>
      {showHeader && (
        <thead className={styles.tableHeader}>
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <th key={header.id}>
                  {flexRender(
                    header.column.columnDef.header,
                    header.getContext()
                  )}
                </th>
              ))}
            </tr>
          ))}
        </thead>
      )}
      <tbody className={styles.tableBody}>
        {table.getRowModel().rows.map((row) => (
          <tr key={row.id}>
            {row.getVisibleCells().map((cell) => (
              <td key={cell.id}>
                {flexRender(cell.column.columnDef.cell, cell.getContext())}
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  );
}
