import cn from 'classnames';
import { ZonedDateTime } from '@internationalized/date';
import { formatZonedDateTime } from '../../../utils/formatZonedDateTime';
import { DescriptionList } from '../../DescriptionList';
import { FundingRound } from '../../../schemas/fundingRound/fundingRoundSchema';

import styles from './RoundDetails.module.scss';

export interface RoundDetailsProps {
  round: Omit<FundingRound, 'allowOrion'>;
  direction?: 'row' | 'column';
  fontSize?: 'small' | 'micro';
}

const toList = (entries: Array<[string, ZonedDateTime | null]>) =>
  Object.fromEntries(
    entries.map(([label, date]) => [
      label,
      formatZonedDateTime(date, 'short', ' at ', false),
    ])
  );

export function RoundDetails({
  round,
  direction = 'column',
  fontSize = 'micro',
}: RoundDetailsProps) {
  const {
    startDate,
    endDate,
    boardStartDate,
    boardEndDate,
    contractDeadline,
    claimDeadline,
  } = round;

  return (
    <div className={cn(styles.container, styles[direction])}>
      <DescriptionList
        title="Applications:"
        fontSize={fontSize}
        list={toList([
          ['Start', startDate],
          ['End', endDate],
          ['Contract Deadline', contractDeadline],
          ['Claim Deadline', claimDeadline],
        ])}
      />
      <DescriptionList
        title="Board Access:"
        fontSize={fontSize}
        list={toList([
          ['Start', boardStartDate],
          ['End', boardEndDate],
        ])}
      />
    </div>
  );
}
