import cn from 'classnames';
import { useRef, useState } from 'react';
import { useDatePickerState } from 'react-stately';
import { useDatePicker, useLocale, AriaDatePickerProps } from 'react-aria';
import { CalendarDate, createCalendar } from '@internationalized/date';

import { Icon } from '../Icon';
import { DateField } from './DateField';
import { Popover } from './Popover';
import { TimePeriodPanel } from './TimePeriodPanel';
import { Button } from 'react-aria-components';
import { Calendar } from './Calendar';

import styles from './DatePicker.module.scss';

type DatePickerProps = AriaDatePickerProps<CalendarDate> & {
  formFieldStatus?: 'default' | 'text' | 'error';
  'aria-label': string;
  value: CalendarDate | null;
  onChange: (value: CalendarDate) => void;
};

export function DatePicker({
  formFieldStatus = 'default',
  ...props
}: DatePickerProps) {
  const { locale } = useLocale();
  const [showMonthView, setShowMonthView] = useState(false);
  const ref = useRef(null);
  const buttonRef = useRef(null);

  const state = useDatePickerState({
    ...props,
    shouldCloseOnSelect: true,
  });

  const { groupProps, fieldProps, calendarProps } = useDatePicker(
    props,
    state,
    ref
  );

  return (
    <>
      <div
        className={cn(styles.container, {
          [styles.error]: formFieldStatus === 'error',
        })}
        ref={ref}
      >
        <div {...groupProps} className={styles.row}>
          <DateField
            {...fieldProps}
            value={state.dateValue}
            locale={locale}
            createCalendar={createCalendar}
          />
          <Button
            type="button"
            onPress={() => {
              state.setOpen(!state.isOpen);
              setShowMonthView(false);
            }}
            ref={buttonRef}
            className={styles.icon}
          >
            <Icon name="calendar" className={styles.icon} />
          </Button>
        </div>
      </div>
      <Popover
        divRef={buttonRef}
        open={state.isOpen}
        onClose={() => state.setOpen(false)}
      >
        {!showMonthView && (
          <Calendar
            {...calendarProps}
            onShowMonthView={() => setShowMonthView(true)}
            locale={locale}
            value={props.value}
            createCalendar={createCalendar}
          />
        )}
        {showMonthView && (
          <div>
            <TimePeriodPanel
              value={props.value}
              onDone={(date) => {
                props.onChange(date);
                setShowMonthView(false);
              }}
            />
          </div>
        )}
      </Popover>
    </>
  );
}
