import { useGraphQLClient } from '../../../components/GraphQLClientProvider';
import { createGetFundingRoundsPromise } from '../../../promises/Admin/createGetFundingRoundsPromise';
import { RoundsController } from './RoundsController';
import { createAddFundingRoundPromise } from '../../../promises/fundingRounds/createAddFundingRoundPromise';
import { createUpdateFundingRoundPromise } from '../../../promises/fundingRounds/createUpdateFundingRoundPromise';
import { withDelay } from '../../../utils/withDelay';

export const Rounds = () => {
  const client = useGraphQLClient();

  return (
    <RoundsController
      options={{
        services: {
          getFundingRoundsPromise: withDelay(
            createGetFundingRoundsPromise(client)
          ),
          addFundingRoundPromise: createAddFundingRoundPromise(client),
          updateFundingRoundPromise: createUpdateFundingRoundPromise(client),
        },
      }}
    />
  );
};
