import cn from 'classnames';
import {
  ApplicationStage,
  ApplicationType,
  InsertDirectDepositAndLinkToApplicationType,
} from '../../graphql/operations';
import {
  SignedContractMachineSender,
  SignedContractMachineState,
} from '../../machines/ClaimFunds/SignedContractMachine';
import {
  DirectDepositsMachineSender,
  DirectDepositsMachineState,
  Context,
} from '../../machines/ClaimFunds/DirectDepositsMachine';
import { DirectDepositMachineActor } from '../../machines/ClaimFunds/DirectDepositMachine';
import { Block } from '../Block';
import { ErrorFeedback } from '../ErrorFeedback';
import { ApplicationProgressTimeline } from '../ApplicationProgressTimeline';
import { UploadButton } from '../UploadButton';
import { SignedContractPreview } from './SignedContractPreview';
import { DepositInfoController } from './DepositInfoController';
import { CollapsedDepositInfoController } from './CollapsedDepositInfoController';
import { TourSummaryPreview } from './TourSummaryPreview';
import {
  TourSummaryMachineSender,
  TourSummaryMachineState,
} from '../../machines/ClaimFunds/TourSummaryMachine';

import styles from './ClaimFundsView.module.scss';
import { Button } from '../Button';
import { useIsAdmin } from '../../hooks/useIsAdmin';
import { ReopenClaimsMachineActor } from '../../machines/Admin/ReopenClaimsMachine';
import { ZonedDateTime } from '@internationalized/date';
import { match, P } from 'ts-pattern';
import {
  ApplicationMachineSender,
  ApplicationMachineState,
} from '../../machines/Application/ApplicationMachine';
import { ReopenClaimsFormController } from './ReopenClaimsFormController';
import { ReopenClaimsNotesView } from './ReopenClaimsNotesView';

interface ClaimFundsViewProps {
  stage: ApplicationStage;
  claimedTotalForTourDates: number;
  claimedTotalForFundingRequests: number;
  claimedTotal: number;
  isOwner: boolean;
  isClaimsReopenable: boolean;
  reopenClaimsNotes: string | null;
  claimsResubmissionDeadline: ZonedDateTime | null;
  claimsReopenedAt: ZonedDateTime | null;
  claimsResubmittedAt: ZonedDateTime | null;
  hasApprovedFundingRequests: boolean;
  directDepositsState: DirectDepositsMachineState;
  directDepositsSend: DirectDepositsMachineSender;
  signedContractState: SignedContractMachineState;
  signedContractSend: SignedContractMachineSender;
  tourSummaryState: TourSummaryMachineState;
  tourSummarySend: TourSummaryMachineSender;
  applicationState: ApplicationMachineState;
  applicationSend: ApplicationMachineSender;
}

export function ClaimFundsView({
  stage,
  claimedTotalForTourDates,
  claimedTotalForFundingRequests,
  claimedTotal,
  isOwner,
  isClaimsReopenable,
  reopenClaimsNotes,
  claimsReopenedAt,
  claimsResubmissionDeadline,
  hasApprovedFundingRequests,
  directDepositsState,
  directDepositsSend,
  signedContractState,
  signedContractSend,
  tourSummaryState,
  tourSummarySend,
  applicationState,
  applicationSend,
}: ClaimFundsViewProps) {
  const isAdmin = useIsAdmin();
  if (
    directDepositsState.matches('ui.error') ||
    signedContractState.matches('error')
  ) {
    return (
      <Block headerText="Claim your funds" iconName="dollarSign">
        <ErrorFeedback />
      </Block>
    );
  }

  const collapseDirectDepositInfo = isUsingSameDirectDepositInfo(
    directDepositsState.context
  );

  const showTourDepositInfo =
    directDepositsState.context.applicationType === ApplicationType.Core &&
    !collapseDirectDepositInfo;

  const showFundingDepositInfo =
    (directDepositsState.context.applicationType === ApplicationType.Orion ||
      hasApprovedFundingRequests) &&
    !collapseDirectDepositInfo;

  const showTourSummary =
    tourSummaryState.context.applicationType === ApplicationType.Core &&
    stage !== ApplicationStage.ContractAndDirectDepositPending;

  return (
    <Block
      id="Claims"
      className={cn({ [styles.outline]: isAdmin })}
      headerText="Claim Your Funds"
      iconName="dollarSign"
      footer={match([
        isAdmin,
        isClaimsReopenable,
        applicationState.matches('form.reopen-claims.open'),
        reopenClaimsNotes,
      ])
        .with([false, P.any, P.any, P.any], () => null)
        .with([true, true, false, P.nullish], () => (
          <Button
            label="Add Review Notes & Reopen Claim"
            variant="neutral"
            size="small"
            length="full"
            onClick={() => applicationSend({ type: 'OPEN_REOPEN_CLAIMS_FORM' })}
          />
        ))
        .with(
          [true, P.any, false, P.not(P.nullish)],
          ([_, __, ___, reopenClaimsNotes]) => (
            <ReopenClaimsNotesView
              notes={reopenClaimsNotes}
              onEdit={() => {
                applicationSend({ type: 'OPEN_REOPEN_CLAIMS_FORM' });
              }}
              claimsReopenedAt={claimsReopenedAt}
              claimsResubmissionDeadline={claimsResubmissionDeadline}
            />
          )
        )
        .with([true, P.any, true, P.any], () => (
          <ReopenClaimsFormController
            reopenClaimsActor={
              applicationState.children
                .reopenClaimsMachine as ReopenClaimsMachineActor
            }
            applicationSend={applicationSend}
          />
        ))
        .with([true, P.any, true, P.not(P.nullish)], () => (
          <p>editNotesFormOpen</p>
        ))
        .otherwise(() => null)}
      footerClassName={styles.footer}
    >
      <div className={styles.container}>
        <ApplicationProgressTimeline stage={stage} hideButtons={true} />
        <div>
          {showTourSummary && (
            <TourSummary
              tourSummaryState={tourSummaryState}
              tourSummarySend={tourSummarySend}
              showDelete={
                ApplicationStage.Completed !== stage &&
                ApplicationStage.ClaimsSubmitted !== stage &&
                ApplicationStage.ClaimsResubmitted !== stage
              }
            />
          )}
          <SignedContract
            isOwner={isOwner}
            signedContractState={signedContractState}
            signedContractSend={signedContractSend}
            showDelete={
              ApplicationStage.Completed !== stage &&
              ApplicationStage.ClaimsSubmitted !== stage &&
              ApplicationStage.ClaimsReopened !== stage &&
              ApplicationStage.ClaimsResubmitted !== stage
            }
          />
          {collapseDirectDepositInfo && (
            <CollapsedDepositInfoController
              stage={stage}
              claimedTotal={claimedTotal}
              directDepositsState={directDepositsState}
              directDepositsSend={directDepositsSend}
              touringDirectDepositActor={
                directDepositsState.children
                  .tourFundingDirectDepositInfo as DirectDepositMachineActor
              }
              fundingRequestDirectDepositActor={
                directDepositsState.children
                  .fundingRequestDirectDepositInfo as DirectDepositMachineActor
              }
            />
          )}
          {showTourDepositInfo && (
            <DepositInfoController
              stage={stage}
              claimedTotal={claimedTotalForTourDates}
              depositInfoType={
                InsertDirectDepositAndLinkToApplicationType.Touring
              }
              isOwner={isOwner}
              directDepositsState={directDepositsState}
              directDepositActor={
                directDepositsState.children
                  .tourFundingDirectDepositInfo as DirectDepositMachineActor
              }
            />
          )}
          {showFundingDepositInfo && (
            <DepositInfoController
              stage={stage}
              claimedTotal={claimedTotalForFundingRequests}
              depositInfoType={
                InsertDirectDepositAndLinkToApplicationType.FundingRequest
              }
              isOwner={isOwner}
              directDepositsState={directDepositsState}
              directDepositActor={
                directDepositsState.children
                  .fundingRequestDirectDepositInfo as DirectDepositMachineActor
              }
            />
          )}
        </div>
      </div>
    </Block>
  );
}

function TourSummary({
  tourSummaryState: state,
  tourSummarySend: send,
  showDelete,
}: Pick<ClaimFundsViewProps, 'tourSummaryState' | 'tourSummarySend'> & {
  showDelete: boolean;
}) {
  return state.context.tourSummary ? (
    <div className={styles.previewWrapper}>
      <TourSummaryPreview
        attachment={state.context.tourSummary}
        onClick={(file) => send({ type: 'GET_TOUR_SUMMARY', file })}
        showDelete={showDelete}
        onDelete={(file) => send({ type: 'DELETE_TOUR_SUMMARY', file })}
      />
    </div>
  ) : (
    <div className={styles.buttonWrapper}>
      <UploadButton
        buttonLabel="Upload Tour Summary"
        multiple={false}
        isUploading={state.matches('uploading')}
        onClick={(files) => send({ type: 'UPLOAD_TOUR_SUMMARY', files })}
        variant="secondary"
      />
    </div>
  );
}

function SignedContract({
  isOwner,
  signedContractState: state,
  signedContractSend: send,
  showDelete,
}: Pick<ClaimFundsViewProps, 'signedContractState' | 'signedContractSend'> & {
  isOwner: boolean;
  showDelete: boolean;
}) {
  if (state.context.signedContract) {
    return (
      <div className={styles.previewWrapper}>
        <SignedContractPreview
          artistName={state.context.artistName}
          attachment={state.context.signedContract}
          onClick={(file) => send({ type: 'GET_CONTRACT', file })}
          onDelete={(file) => {
            if (
              window.confirm(
                'Are you sure you want to delete your signed contract?'
              )
            ) {
              send({ type: 'DELETE_CONTRACT', file });
            }
          }}
          showDelete={showDelete}
        />
      </div>
    );
  } else if (isOwner) {
    return (
      <div className={styles.buttonWrapper}>
        <UploadButton
          buttonLabel="Upload Signed Contract"
          multiple={false}
          isUploading={state.matches('uploading')}
          onClick={(files) => send({ type: 'UPLOAD_CONTRACT', files })}
          variant="secondary"
        />
      </div>
    );
  } else {
    return null;
  }
}

function isUsingSameDirectDepositInfo(context: Context): boolean {
  return Boolean(
    context.touringDirectDepositAccount &&
      context.fundingRequestDirectDepositAccount &&
      context.touringDirectDepositAccount.id ===
        context.fundingRequestDirectDepositAccount.id
  );
}
