import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from '@tanstack/react-table';
import React, { useState } from 'react';

import { Button } from '../Button';
import { Icon } from '../Icon';
import { TextInput } from '../TextInput';

import styles from './Table.module.scss';
import { Form } from '../Form';

interface Genre {
  name: string;
}

interface GenreTableProps {
  data: Genre[];
  onAddGenre: (genre: string) => void;
  onRemoveGenre: (genre: string) => void;
  onEdit: () => void;
  onView: () => void;
  onMoveUp: () => void;
  onMoveDown: () => void;
}

const columnHelper = createColumnHelper<Genre>();

export function GenreTable({
  data,
  onAddGenre,
  onRemoveGenre,
}: GenreTableProps) {
  const [newRow, setNewRow] = useState({
    show: false,
    value: '',
  });

  const columns = [
    columnHelper.accessor('name', {
      header: 'Genre',
      cell: (info) => info.renderValue(),
    }),
  ];

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
  });

  const onSubmit = () => {
    onAddGenre(newRow.value);
    setNewRow({ show: false, value: '' });
  };

  return (
    <div>
      <table>
        <thead className={styles.tableHeader}>
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <th key={header.id}>
                  {flexRender(
                    header.column.columnDef.header,
                    header.getContext()
                  )}
                </th>
              ))}
              <td>
                <Button
                  variant="secondary"
                  label="Add Genre"
                  onClick={() => setNewRow({ ...newRow, show: true })}
                />
              </td>
            </tr>
          ))}
        </thead>
        <tbody className={styles.tableBody}>
          {table.getRowModel().rows.map((row) => (
            <tr key={row.id}>
              {row.getVisibleCells().map((cell) => (
                <td key={cell.id}>
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </td>
              ))}
              <td className={styles.icons}>
                <Icon
                  name="trash"
                  onClick={() => onRemoveGenre(row.original.name)}
                />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      {newRow.show && (
        <Form onSubmit={onSubmit} className={styles.form}>
          <TextInput
            id="newGenre"
            label=""
            value={newRow.value}
            onChange={(value) => setNewRow({ ...newRow, value })}
            icon="trash"
            onIconClick={() => setNewRow({ show: false, value: '' })}
          />
        </Form>
      )}
    </div>
  );
}
