import { useState } from 'react';
import { ZonedDateTime } from '@internationalized/date';
import { ApplicationSidebar } from '../ApplicationSidebar';
import { TextInput } from '../TextInput';
import { RadioOption } from '../RadioOption';

import styles from './ApplicationSidebarShowcase.module.scss';
import { DatePickerWithTime } from '../DatePicker/DatePickerWithTime';
import { ApplicationStage, ApplicationStatus } from '../../graphql/operations';

interface Application {
  fundingRequested?: number;
  fundingApproved?: number;
  fundingDeadline?: ZonedDateTime | null;
  claimsSubmittedAt?: ZonedDateTime | null;
  submittedAt?: ZonedDateTime | null;
  status?: ApplicationStatus;
  stage: ApplicationStage;
}

export function ApplicationSidebarShowcase() {
  const [application, setApplication] = useState<Application>({
    stage: ApplicationStage.Unsubmitted,
  });

  return (
    <ApplicationSidebar
      title="Core Funding Application"
      links={[
        'Header',
        'Text Input',
        'Primary Buttons',
        'Feedback',
        'Tooltips',
      ]}
      fundingRequested={application.fundingRequested}
      fundingApproved={application.fundingApproved}
      fundingDeadline={application.fundingDeadline}
      submittedAt={application.submittedAt}
      claimsSubmittedAt={application.claimsSubmittedAt}
      status={application.status}
      stage={application.stage}
    >
      <div className={styles.row}>
        <div>
          <p>Funding</p>
          <TextInput
            id="funding"
            placeholder="Set funding"
            value={application.fundingRequested?.toString() ?? ''}
            onChange={(funding) =>
              setApplication({
                ...application,
                fundingRequested: parseInt(funding),
              })
            }
          />
        </div>
        <div>
          <p>Approved funding</p>
          <TextInput
            id="approved-funding"
            placeholder="Set funding"
            value={application.fundingApproved?.toString() ?? ''}
            onChange={(fundingApproved) =>
              setApplication({
                ...application,
                fundingApproved: parseInt(fundingApproved),
              })
            }
          />
        </div>
      </div>
      <div className={styles.row}>
        <div>
          <p>Deadline</p>
          <DatePickerWithTime
            aria-label="Funding deadline"
            value={application.fundingDeadline ?? null}
            timezone={
              application.fundingDeadline?.timeZone ?? 'America/Toronto'
            }
            onChange={(fundingDeadline) =>
              setApplication({
                ...application,
                fundingDeadline,
              })
            }
          />
        </div>
        <div>
          <p>Submitted</p>
          <DatePickerWithTime
            aria-label="Date submitted"
            value={application.submittedAt ?? null}
            timezone={application.submittedAt?.timeZone ?? 'America/Toronto'}
            onChange={(submittedAt) =>
              setApplication({
                ...application,
                submittedAt,
              })
            }
          />
        </div>
      </div>
      <div className={styles.row}>
        <div>
          <p>Status</p>
          <RadioOption
            checked={application.status === undefined}
            label="None"
            onChange={() =>
              setApplication({ ...application, status: undefined })
            }
          />
          <RadioOption
            checked={application.status === ApplicationStatus.Approved}
            label="Approved"
            onChange={() =>
              setApplication({
                ...application,
                status: ApplicationStatus.Approved,
              })
            }
          />
        </div>
        <div>
          <p>Claim submitted</p>
          <DatePickerWithTime
            aria-label="Claim submitted"
            value={application.claimsSubmittedAt ?? null}
            timezone={
              application.claimsSubmittedAt?.timeZone ?? 'America/Toronto'
            }
            onChange={(claimsSubmittedAt) =>
              setApplication({
                ...application,
                claimsSubmittedAt,
              })
            }
          />
        </div>
      </div>
    </ApplicationSidebar>
  );
}
