import { useActor } from '@xstate/react';
import { DirectDepositDownloadMachineActor } from '../../../machines/Admin/DirectDepositDownloadMachine';
import { DirectDepositDownloadView } from './DirectDepositDownloadView';

type DirectDepositDownloadControllerProps = {
  actor: DirectDepositDownloadMachineActor;
  onCancel: () => void;
};

export function DirectDepositDownloadController({
  actor,
  onCancel,
}: DirectDepositDownloadControllerProps) {
  const [state, send] = useActor(actor);

  return (
    <DirectDepositDownloadView state={state} send={send} onCancel={onCancel} />
  );
}
