import cn from 'classnames';
import { SearchFilters } from '../../components/ApplicationSearch/SearchFilters';
import { Breadcrumbs } from '../../components/Breadcrumbs';
import { ApplicationCard } from '../../components/Dashboard/ApplicationCard';
import { InfoBox } from '../../components/InfoBox';
import { Link } from '../../components/Link';
import { Loading } from '../../components/Loading';
import { PagePagination } from '../../components/PagePagination';
import { SortBy } from '../../components/SortBy';
import { TextInput } from '../../components/TextInput';
import {
  ApplicationStage,
  ApplicationStatus,
  SearchApplicationsSortOrder,
} from '../../graphql/operations';
import {
  ApplicationSearchMachineSender,
  ApplicationSearchMachineState,
} from '../../machines/Admin/ApplicationSearchMachine';
import { ErrorPage } from '../ErrorPage';

import styles from './ApplicationSearchView.module.scss';

interface ApplicationSearchViewProps {
  roundTitles: string[];
  state: ApplicationSearchMachineState;
  send: ApplicationSearchMachineSender;
  isMobile: boolean;
  isBoardView?: boolean;
  boardRound?: string;
}

export function ApplicationSearchView({
  roundTitles,
  state,
  send,
  isMobile,
  isBoardView,
  boardRound,
}: ApplicationSearchViewProps) {
  if (state.matches('loading.initial')) {
    return <Loading />;
  }

  if (state.matches('error') || (isBoardView && !boardRound)) {
    return <ErrorPage />;
  }

  function onClear() {
    send({
      type: 'SET_SEARCH_VARIABLES',
      data: {
        roundTitle: isBoardView && boardRound ? roundTitle : 'ALL',
        resultOffset: 0,
        resultLimit: state.context.searchVariables.resultLimit,
      },
    });
  }

  const sortByProps = {
    options: [
      {
        label: 'Artist Name (A-Z)',
        value: SearchApplicationsSortOrder.ArtistNameAsc,
      },
      {
        label: 'Artist Name (Z-A)',
        value: SearchApplicationsSortOrder.ArtistNameDesc,
      },
      {
        label: 'Latest Submitted',
        value: SearchApplicationsSortOrder.SubmittedAtDesc,
      },
      {
        label: 'Latest Modified',
        value: SearchApplicationsSortOrder.ModifiedDesc,
      },
    ],
    value:
      state.context.searchVariables.orderBy ??
      SearchApplicationsSortOrder.ModifiedDesc,
    onSelect: (val: SearchApplicationsSortOrder) => {
      send({
        type: 'SET_SEARCH_VARIABLES',
        data: { ...state.context.searchVariables, orderBy: val },
        resetOffset: true,
      });
    },
  };

  const totalResults = state.context.total;
  const { resultLimit, resultOffset, roundTitle } =
    state.context.searchVariables || {};
  const numberOfPages =
    totalResults && resultLimit
      ? Math.ceil(totalResults / resultLimit)
      : undefined;

  const activePage =
    resultOffset && resultLimit ? resultOffset / resultLimit + 1 : 1;

  return (
    <div className={cn(styles.container, 'max-w-xl')}>
      <div className={styles.crumbsAndSearchInput}>
        <Breadcrumbs
          breadcrumbs={
            isBoardView && boardRound
              ? [
                  {
                    text: `Round ${boardRound} Board Meeting`,
                    to: `/board-meeting`,
                  },
                ]
              : [{ text: 'Admin Dashboard', to: '/admin' }]
          }
        />
        <TextInput
          id="search-query"
          className={styles.searchInput}
          value={state.context.searchVariables.searchQuery ?? ''}
          icon="search"
          iconClassName={styles.searchIcon}
          placeholder="Search by artist, label, or account"
          onChange={(val) => {
            send({
              type: 'SET_SEARCH_QUERY',
              data: val,
              resetOffset: true,
            });
          }}
        />
      </div>
      <div className={styles.headingAndDesktopSort}>
        <h1 className={styles.heading}>Search Applications</h1>
        <SortBy {...sortByProps} className={styles.desktopSort} />
      </div>
      <div className={styles.filtersAndResults}>
        <div className={styles.filters}>
          <SearchFilters
            hideClaimsFilters={
              isBoardView && Number(roundTitle) >= Number(boardRound)
            }
            roundTitles={roundTitles}
            roundTitle={roundTitle ?? 'ALL'}
            onRoundTitleChange={(val) => {
              send({
                type: 'SET_SEARCH_VARIABLES',
                data: { ...state.context.searchVariables, roundTitle: val },
                resetOffset: true,
              });
            }}
            applicationType={state.context.searchVariables.applicationType}
            onApplicationTypeChange={(val) =>
              send({
                type: 'SET_SEARCH_VARIABLES',
                data: {
                  ...state.context.searchVariables,
                  applicationType: val === 'ALL' ? undefined : val,
                },
                resetOffset: true,
              })
            }
            applicationStatus={state.context.searchVariables.applicationStatus}
            onApplicationStatusChange={(val) => {
              send({
                type: 'SET_SEARCH_VARIABLES',
                data: {
                  ...state.context.searchVariables,
                  applicationStatus:
                    val === state.context.searchVariables.applicationStatus
                      ? undefined
                      : val,
                  ...(val === ApplicationStatus.Approved &&
                    state.context.searchVariables.applicationStatus ===
                      ApplicationStatus.Approved && { stage: undefined }),
                  ...(val === ApplicationStatus.Denied &&
                    state.context.searchVariables.applicationStatus !==
                      ApplicationStatus.Denied && { stage: undefined }),
                },
                resetOffset: true,
              });
            }}
            stage={state.context.searchVariables.stage}
            onStageChange={(val) => {
              if (val?.length) {
                return send({
                  type: 'SET_SEARCH_VARIABLES',
                  data: {
                    ...state.context.searchVariables,
                    stage: val,
                    applicationStatus: ApplicationStatus.Approved,
                  },
                  resetOffset: true,
                });
              }

              send({
                type: 'SET_SEARCH_VARIABLES',
                data: {
                  ...state.context.searchVariables,
                  stage: val,
                },
                resetOffset: true,
              });
            }}
            alternateRequest={state.context.searchVariables.alternateRequest}
            onAlternateRequestChange={(val) => {
              send({
                type: 'SET_SEARCH_VARIABLES',
                data: {
                  ...state.context.searchVariables,
                  alternateRequest: val,
                },
                resetOffset: true,
              });
            }}
            reviewStatus={state.context.searchVariables.reviewStatus}
            onReviewStatusChange={(val) => {
              send({
                type: 'SET_SEARCH_VARIABLES',
                data: {
                  ...state.context.searchVariables,
                  reviewStatus: val,
                },
                resetOffset: true,
              });
            }}
            initialPaymentPaid={
              state.context.searchVariables.initialPaymentPaid
            }
            onInitialPaymentPaidChange={(val) => {
              send({
                type: 'SET_SEARCH_VARIABLES',
                data: {
                  ...state.context.searchVariables,
                  initialPaymentPaid: val,
                },
                resetOffset: true,
              });
            }}
            onClear={onClear}
          />
        </div>
        <SortBy {...sortByProps} className={styles.mobileSort} />
        <div className={styles.results}>
          <div className={styles.applications}>
            {state.context.applications.length === 0 && (
              <InfoBox>
                There are no results matching your search criteria.
                <br />
                <Link onClick={onClear}>Clear all filters</Link> or adjust your
                search.
              </InfoBox>
            )}
            {state.context.applications.map((application) => (
              <ApplicationCard
                key={application.id}
                type={application.type}
                round={application.fundingRound?.title}
                status={application.status}
                submittedAt={application.submittedAt ?? null}
                createdAt={application.createdAt}
                applicationLink={`/artist/${application.artist.id}/application/${application.id}`}
                isComplete={application.stage === ApplicationStage.Completed}
                admin={{
                  artistName: application.artist?.name ?? '',
                  modifiedAt: application.artistModifiedAt,
                  requested: application.totalRequested,
                  approved: application.approvedTotal,
                  tentativeTotal: application.tentativeTotal,
                  claim: application.claimedTotal,
                  paid: application.totalPaid,
                  unused: application.approvedTotal - application.claimedTotal,
                  unsettledRequests: application.numberOfUnsettledRequests ?? 0,
                  pendingRequests: application.numberOfPendingRequests ?? 0,
                  reviewStatus: application.reviewStatus,
                }}
              />
            ))}
          </div>
          {numberOfPages && numberOfPages > 1 && activePage && (
            <PagePagination
              numberOfPages={numberOfPages}
              active={activePage}
              onPageClick={(page) => {
                resultLimit &&
                  send({
                    type: 'SET_SEARCH_VARIABLES',
                    data: {
                      ...state.context.searchVariables,
                      resultOffset: (page - 1) * resultLimit,
                    },
                    resetOffset: false,
                  });
              }}
              isMobile={isMobile}
            />
          )}
        </div>
      </div>
    </div>
  );
}
