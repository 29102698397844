import { useState } from 'react';
import { TextArea } from '../TextArea';

import styles from './TextInputShowcase.module.scss';

export function TextAreaShowcase() {
  const [text, setText] = useState('');

  return (
    <div className={styles.container}>
      <span className="showcase-label">Text Area</span>
      <TextArea
        id="firstName"
        size="small"
        label="First name"
        value={text}
        onChange={setText}
      />
      <TextArea
        id="firstName"
        size="large"
        label="Last name"
        value={text}
        onChange={setText}
      />
      <TextArea
        id="firstName"
        size="huge"
        label="Wow name"
        value={text}
        onChange={setText}
      />
      <TextArea
        id="firstName"
        size="small"
        label="Wow name"
        value={text}
        disabled={true}
        onChange={setText}
      />
      <TextArea
        id="firstName"
        size="small"
        label="Wow name"
        value={text}
        error={true}
        onChange={setText}
      />
    </div>
  );
}
