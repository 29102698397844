import { Button } from './Button';
import { Icon, IconName } from './Icon';

import styles from './Announcement.module.scss';

interface AnnouncementProps {
  message: string;
  icon: IconName;
  hasButton?: boolean;
  buttonText?: string;
  buttonDisabled?: boolean;
  buttonOnClick?: () => void;
}

export function Announcement({
  message,
  icon,
  hasButton = false,
  buttonText,
  buttonDisabled,
  buttonOnClick,
}: AnnouncementProps) {
  return (
    <div className={styles.announcement}>
      <div className={styles.icon}>
        <Icon name={icon} />
      </div>
      <div className={styles.content}>
        <div className={styles.heading}>{message}</div>

        {hasButton && (
          <>
            <div className={styles.buttonMobile}>
              <Button
                size="small"
                variant="secondary"
                label={buttonText}
                disabled={buttonDisabled}
                onClick={buttonOnClick}
              />
            </div>
            <div className={styles.buttonDesktop}>
              <Button
                size="large"
                variant="secondary"
                label={buttonText}
                disabled={buttonDisabled}
                onClick={buttonOnClick}
              />
            </div>
          </>
        )}
      </div>
    </div>
  );
}
