import type {
  ActorRefFrom,
  EventFrom,
  MachineOptionsFrom,
  Sender,
} from 'xstate';
import { assign, createMachine, sendParent } from 'xstate';
import { CalendarDate, parseDate } from '@internationalized/date';
import { Context as ParentContext } from './Context';
import { ReplayableEvent, createReplay } from '../../utils/replay';
import { back, next } from '../navigation';

import { match } from 'ts-pattern';
import { StateFrom } from '../../utils/StateFrom';
import { parsePositiveInteger } from '../../utils/parsePositiveInteger';
import { isAfterCutoff } from '../../utils/isAfterCutoff';
import { EligibilityStorage } from './storage';
import { AddReleaseStorage } from '../AddRelease/storage';

type FormType = 'eligibility' | 'add-release';

type Labels = NonNullable<ParentContext['labels']>;
type Label = Labels[0];

type Distributors = NonNullable<ParentContext['distributors']>;
type Distributor = Distributors[0];

type SetTitleEvent = ReplayableEvent<{
  type: 'SET_TITLE';
  title: string;
}>;

type SetLabelEvent = ReplayableEvent<{
  type: 'SET_LABEL';
  label: Label;
}>;

type SetOtherLabelEvent = ReplayableEvent<{
  type: 'SET_OTHER_LABEL';
  otherLabel: string;
}>;

type SetDistributorEvent = ReplayableEvent<{
  type: 'SET_DISTRIBUTOR';
  distributor: Distributor;
}>;

type SetOtherDistributorEvent = ReplayableEvent<{
  type: 'SET_OTHER_DISTRIBUTOR';
  otherDistributor: string;
}>;

type SetDateEvent = ReplayableEvent<{
  type: 'SET_DATE';
  date: CalendarDate;
}>;

type SetNumberOfMinutesEvent = ReplayableEvent<{
  type: 'SET_NUMBER_OF_MINUTES';
  numberOfMinutes: string;
}>;

type SetNumberOfTracksEvent = ReplayableEvent<{
  type: 'SET_NUMBER_OF_TRACKS';
  numberOfTracks: string;
}>;

type SetLanguageEvent = ReplayableEvent<{
  type: 'SET_LANGUAGE';
  isMajorityEnglish: boolean;
}>;

type ReplayableEvents =
  | SetTitleEvent
  | SetLabelEvent
  | SetOtherLabelEvent
  | SetDistributorEvent
  | SetOtherDistributorEvent
  | SetDateEvent
  | SetNumberOfMinutesEvent
  | SetNumberOfTracksEvent
  | SetLanguageEvent;

type Events =
  | { type: 'NEXT' }
  | { type: 'BACK' }
  | { type: 'BLUR_TITLE' }
  | { type: 'BLUR_OTHER_LABEL' }
  | { type: 'BLUR_OTHER_DISTRIBUTOR' }
  | { type: 'BLUR_NUMBER_OF_MINUTES' }
  | { type: 'BLUR_NUMBER_OF_TRACKS' }
  | ReplayableEvents;

export type Context = {
  type: FormType;
  label?: Label;
  otherLabel: string | null;
  labels: Labels;
  distributor?: Distributor;
  otherDistributor: string | null;
  distributors: Distributors;
  title: string;
  date: CalendarDate | null;
  numberOfTracks: string;
  numberOfMinutes: string;
  isMajorityEnglish?: boolean;
  eligibilityCutoffDate: CalendarDate;
};

const inputStates = {
  pristine: {
    on: {
      NEXT: 'empty',
    },
  },
  empty: {},
  invalid: {
    initial: 'none',
    states: {
      none: {},
      type: {},
      value: {},
    },
  },
  valid: {},
};

export const machine = createMachine(
  {
    predictableActionArguments: true,
    tsTypes: {} as import('./ReleaseMachine.typegen').Typegen0,
    schema: {
      events: {} as Events,
      context: {} as Context,
    },
    id: 'release',
    invoke: {
      id: 'resume',
      src: 'resume',
    },
    type: 'parallel',
    states: {
      title: {
        initial: 'pristine',
        on: {
          BLUR_TITLE: [
            {
              cond: 'isEmpty',
              target: '.empty',
            },
            { target: '.valid' },
          ],
          SET_TITLE: {
            actions: ['setTitle', 'saveTitle'],
            target: '.waiting',
          },
        },
        states: {
          ...inputStates,
          waiting: {
            after: {
              3000: {
                cond: 'isEmpty',
                target: 'empty',
              },
            },
          },
        },
      },
      label: {
        initial: 'pristine',
        states: {
          pristine: {
            on: {
              NEXT: 'empty',
            },
          },
          empty: {},
          valid: {},
        },
        on: {
          SET_LABEL: {
            actions: ['setLabel', 'saveLabel'],
            target: '.valid',
          },
        },
      },
      otherLabel: {
        initial: 'hidden',
        states: {
          ...inputStates,
          hidden: {
            on: {
              SET_LABEL: {
                cond: 'isOtherLabel',
                actions: ['initializeOtherLabel', 'saveOtherLabel'],
                target: 'pristine',
              },
            },
          },
          waiting: {
            after: {
              3000: {
                cond: 'isEmpty',
                target: 'empty',
              },
            },
          },
        },
        on: {
          SET_LABEL: {
            cond: 'isNotOtherLabel',
            actions: ['clearOtherLabel', 'saveOtherLabel'],
            target: '.hidden',
          },
          SET_OTHER_LABEL: {
            actions: ['setOtherLabel', 'saveOtherLabel'],
            target: '.waiting',
          },
          BLUR_OTHER_LABEL: [
            {
              cond: 'isEmpty',
              target: '.empty',
            },
            { target: '.valid' },
          ],
        },
      },
      distributor: {
        initial: 'pristine',
        states: {
          pristine: {
            on: {
              NEXT: 'empty',
            },
          },
          empty: {},
          valid: {},
        },
        on: {
          SET_DISTRIBUTOR: {
            actions: ['setDistributor', 'saveDistributor'],
            target: '.valid',
          },
        },
      },
      otherDistributor: {
        initial: 'hidden',
        states: {
          ...inputStates,
          hidden: {
            on: {
              SET_DISTRIBUTOR: {
                cond: 'isOtherDistributor',
                actions: ['initializeOtherDistributor', 'saveOtherDistributor'],
                target: 'pristine',
              },
            },
          },
          waiting: {
            after: {
              3000: {
                cond: 'isEmpty',
                target: 'empty',
              },
            },
          },
        },
        on: {
          SET_DISTRIBUTOR: {
            cond: 'isNotOtherDistributor',
            actions: ['clearOtherDistributor', 'saveOtherDistributor'],
            target: '.hidden',
          },
          SET_OTHER_DISTRIBUTOR: {
            actions: ['setOtherDistributor', 'saveOtherDistributor'],
            target: '.waiting',
          },
          BLUR_OTHER_DISTRIBUTOR: [
            {
              cond: 'isEmpty',
              target: '.empty',
            },
            { target: '.valid' },
          ],
        },
      },
      numberOfMinutes: {
        initial: 'pristine',
        on: {
          BLUR_NUMBER_OF_MINUTES: [
            {
              cond: 'isEmpty',
              target: '.empty',
            },
            {
              cond: 'isNotNumeric',
              target: '.invalid.type',
            },
            { cond: 'isNumberOfMinutesInvalid', target: '.invalid.value' },
            { target: '.valid' },
          ],
          SET_NUMBER_OF_MINUTES: {
            actions: ['setNumberOfMinutes', 'saveNumberOfMinutes'],
            target: '.waiting',
          },
        },
        states: {
          ...inputStates,
          waiting: {
            after: {
              3000: [
                {
                  cond: 'isEmpty',
                  target: 'empty',
                },
                {
                  cond: 'isNotNumeric',
                  target: 'invalid.type',
                },
                { cond: 'isNumberOfMinutesInvalid', target: 'invalid.value' },
                { target: 'valid' },
              ],
            },
          },
        },
      },
      numberOfTracks: {
        initial: 'pristine',
        on: {
          BLUR_NUMBER_OF_TRACKS: [
            {
              cond: 'isEmpty',
              target: '.empty',
            },
            {
              cond: 'isNotNumeric',
              target: '.invalid.type',
            },
            {
              cond: 'isNumberOfTracksInvalid',
              target: '.invalid.value',
            },
            { target: '.valid' },
          ],
          SET_NUMBER_OF_TRACKS: {
            actions: ['setNumberOfTracks', 'saveNumberOfTracks'],
            target: '.waiting',
          },
        },
        states: {
          ...inputStates,
          waiting: {
            after: {
              3000: [
                {
                  cond: 'isEmpty',
                  target: 'empty',
                },
                {
                  cond: 'isNotNumeric',
                  target: 'invalid.type',
                },
                {
                  cond: 'isNumberOfTracksInvalid',
                  target: 'invalid.value',
                },
                { target: 'valid' },
              ],
            },
          },
        },
      },
      date: {
        initial: 'pristine',
        on: {
          SET_DATE: [
            {
              target: '.validating',
              actions: ['setDate', 'saveDate'],
            },
          ],
        },
        states: {
          ...inputStates,
          validating: {
            always: [
              { cond: 'isValidDate', target: 'valid' },
              { target: 'invalid' },
            ],
          },
        },
      },
      language: {
        initial: 'pristine',
        on: {
          SET_LANGUAGE: [
            {
              cond: 'isLanguageInvalid',
              target: '.invalid.value',
              actions: ['setLanguage', 'saveLanguage'],
            },
            { target: '.valid', actions: ['setLanguage', 'saveLanguage'] },
          ],
        },
        states: {
          ...inputStates,
        },
      },
      submitted: {
        id: 'submitted',
        initial: 'no',
        states: {
          no: {},
          empty: {
            initial: 'normal',
            states: {
              normal: {},
              highlight: {
                after: {
                  1000: { target: 'normal' },
                },
              },
            },
            on: {
              NEXT: '.highlight',
            },
            always: [
              { cond: 'isFormValidAddReleaseVariant', target: 'no' },
              { cond: 'isFormValid', target: 'no' },
              {
                cond: 'hasInvalidFieldsAddReleaseVariant',
                target: 'empty-and-invalid',
              },
              {
                cond: 'hasInvalidFields',
                target: 'empty-and-invalid',
              },
            ],
          },
          invalid: {
            initial: 'normal',
            states: {
              normal: {},
              highlight: {
                after: {
                  1000: { target: 'normal' },
                },
              },
            },
            on: {
              NEXT: '.highlight',
            },
            always: [
              { cond: 'isFormValidAddReleaseVariant', target: 'no' },
              { cond: 'isFormValid', target: 'no' },
              {
                cond: 'hasEmptyFields',
                target: 'empty-and-invalid',
              },
            ],
          },
          'empty-and-invalid': {
            initial: 'normal',
            states: {
              normal: {},
              highlight: {
                after: {
                  1000: { target: 'normal' },
                },
              },
            },
            on: {
              NEXT: [
                {
                  cond: 'hasEmptyFields',
                  target: 'empty',
                },
                {
                  cond: 'hasInvalidFieldsAddReleaseVariant',
                  target: 'invalid',
                },
                { cond: 'isFormValidAddReleaseVariant', actions: 'next' },
                { cond: 'hasInvalidFields', target: 'invalid' },
                { cond: 'isFormValid', actions: 'next' },
                { target: '.highlight' },
              ],
            },
          },
        },
        on: {
          NEXT: [
            { cond: 'hasEmptyFields', target: 'submitted.empty' },
            { cond: 'isFormValidAddReleaseVariant', actions: 'next' },
            { cond: 'isFormValid', actions: 'next' },
            { target: 'submitted.invalid' },
          ],
          BACK: { actions: 'back' },
        },
      },
    },
  },
  {
    guards: {
      isEmpty: (
        {
          title,
          numberOfMinutes,
          numberOfTracks,
          otherDistributor,
          otherLabel,
        },
        { type }
      ) =>
        match(type)
          .with(
            'BLUR_TITLE',
            'xstate.after(3000)#release.title.waiting',
            () => title
          )
          .with(
            'BLUR_NUMBER_OF_MINUTES',
            'xstate.after(3000)#release.numberOfMinutes.waiting',
            () => numberOfMinutes
          )
          .with(
            'BLUR_NUMBER_OF_TRACKS',
            'xstate.after(3000)#release.numberOfTracks.waiting',
            () => numberOfTracks
          )
          .with(
            'BLUR_OTHER_LABEL',
            'xstate.after(3000)#release.otherLabel.waiting',
            () => otherLabel ?? ''
          )
          .with(
            'BLUR_OTHER_DISTRIBUTOR',
            'xstate.after(3000)#release.otherDistributor.waiting',
            () => otherDistributor ?? ''
          )
          .exhaustive()
          .trim() === '',
      isNotNumeric: ({ numberOfMinutes, numberOfTracks }, { type }) =>
        isNaN(
          parsePositiveInteger(
            match(type)
              .with(
                'BLUR_NUMBER_OF_MINUTES',
                'xstate.after(3000)#release.numberOfMinutes.waiting',
                () => numberOfMinutes
              )
              .with(
                'BLUR_NUMBER_OF_TRACKS',
                'xstate.after(3000)#release.numberOfTracks.waiting',
                () => numberOfTracks
              )
              .exhaustive()
              .trim()
          )
        ),
      isNumberOfMinutesInvalid: ({ numberOfMinutes: lengthInMinutes }) =>
        parsePositiveInteger(lengthInMinutes) < 9,
      isNumberOfTracksInvalid: ({ numberOfTracks }) =>
        parsePositiveInteger(numberOfTracks) < 3,
      isValidDate: ({ date, ...context }) => {
        if (context.type === 'add-release') {
          return true;
        }
        return (
          date !== null && isAfterCutoff(context.eligibilityCutoffDate, date)
        );
      },

      isLanguageInvalid: (_, { isMajorityEnglish }) =>
        isMajorityEnglish === false,
      hasEmptyFields: (context, event, { state }) =>
        [
          'title.pristine',
          'title.empty',
          'label.pristine',
          'label.empty',
          'otherLabel.pristine',
          'otherLabel.empty',
          'distributor.pristine',
          'distributor.empty',
          'otherDistributor.pristine',
          'otherDistributor.empty',
          'date.pristine',
          'date.empty',
          'numberOfMinutes.pristine',
          'numberOfMinutes.empty',
          'numberOfTracks.pristine',
          'numberOfTracks.empty',
          'language.pristine',
          'language.empty',
        ].some((match) => state.matches(match)),
      hasInvalidFields: (context, event, { state }) =>
        [
          'title.invalid',
          'date.invalid',
          'numberOfMinutes.invalid',
          'numberOfTracks.invalid',
          'language.invalid',
        ].some((match) => state.matches(match)),
      hasInvalidFieldsAddReleaseVariant: (context, event, { state }) =>
        context.type === 'add-release' &&
        [
          'title.invalid',
          'date.invalid.none',
          'date.invalid.type',
          'numberOfMinutes.invalid.none',
          'numberOfMinutes.invalid.type',
          'numberOfTracks.invalid.none',
          'numberOfTracks.invalid.type',
        ].some((match) => state.matches(match)),
      isFormValid: (context, event, { state }) =>
        [
          'title.valid',
          'label.valid',
          ...(!state.matches('otherLabel.hidden') ? ['otherLabel.valid'] : []),
          'distributor.valid',
          ...(!state.matches('otherDistributor.hidden')
            ? ['otherDistributor.valid']
            : []),
          'date.valid',
          'numberOfMinutes.valid',
          'numberOfTracks.valid',
          'language.valid',
        ].every((match) => state.matches(match)),
      isFormValidAddReleaseVariant: (context, event, { state }) => {
        return (
          context.type === 'add-release' &&
          state.matches('title.valid') &&
          state.matches('label.valid') &&
          (state.matches('otherLabel.hidden') ||
            state.matches('otherLabel.valid')) &&
          state.matches('distributor.valid') &&
          (state.matches('distributor.hidden') ||
            state.matches('distributor.valid')) &&
          (state.matches('date.valid') ||
            state.matches('date.invalid.value')) &&
          (state.matches('numberOfMinutes.valid') ||
            state.matches('numberOfMinutes.invalid.value')) &&
          (state.matches('numberOfTracks.valid') ||
            state.matches('numberOfTracks.invalid.value')) &&
          (state.matches('language.valid') ||
            state.matches('language.invalid.value'))
        );
      },
      isOtherLabel: (_, event) => {
        return event.label.name === 'Other';
      },
      isNotOtherLabel: (_, event) => {
        return event.label.name !== 'Other';
      },
      isOtherDistributor: (_, event) => {
        return event.distributor.name === 'Other';
      },
      isNotOtherDistributor: (_, event) => {
        return event.distributor.name !== 'Other';
      },
    },
    actions: {
      next: sendParent(next()),
      back: sendParent(back()),
      setTitle: assign((_, { title }) => ({ title })),
      setLabel: assign((_, { label }) => ({ label })),
      setOtherLabel: assign((_, { otherLabel }) => ({
        otherLabel,
      })),
      initializeOtherLabel: assign((_) => ({
        otherLabel: '',
      })),
      clearOtherLabel: assign((_) => ({
        otherLabel: undefined,
      })),
      setDistributor: assign((_, { distributor }) => ({ distributor })),
      setOtherDistributor: assign((_, { otherDistributor }) => ({
        otherDistributor,
      })),
      clearOtherDistributor: assign((_) => ({
        otherDistributor: undefined,
      })),
      initializeOtherDistributor: assign((_) => ({
        otherDistributor: '',
      })),
      setNumberOfMinutes: assign((_, { numberOfMinutes }) => ({
        numberOfMinutes,
      })),
      setNumberOfTracks: assign((_, { numberOfTracks }) => ({
        numberOfTracks,
      })),
      setDate: assign((_, { date }) => ({
        date,
      })),
      setLanguage: assign((_, { isMajorityEnglish }) => ({
        isMajorityEnglish,
      })),
    },
  }
);

type Machine = typeof machine;

export type ReleaseMachineState = StateFrom<Machine>;
export type ReleaseMachineSender = Sender<EventFrom<Machine>>;
export type ReleaseMachineOptions = MachineOptionsFrom<Machine, true>;
export type ReleaseMachineActor = ActorRefFrom<Machine>;

export function createCreateReleaseContext(type: FormType) {
  return ({
    eligibilityCutoffDate,
    labels,
    distributors,
  }: Pick<
    ParentContext,
    'labels' | 'distributors' | 'eligibilityCutoffDate'
  >): Context => {
    return {
      type,
      labels,
      otherLabel: null,
      distributors,
      otherDistributor: null,
      title: '',
      date: null,
      numberOfMinutes: '',
      numberOfTracks: '',
      eligibilityCutoffDate,
    };
  };
}

export const createReleaseResume =
  (storage: EligibilityStorage | AddReleaseStorage) =>
  ({ labels, distributors }: Context) =>
  (send: ReleaseMachineSender) => {
    const replay = createReplay<ReplayableEvents>();
    const { release } = storage.get();

    const label = labels.find(({ id }) => id === release?.labelId);

    const distributor = distributors.find(
      ({ id }) => id === release?.distributorId
    );

    (
      [
        ...(release?.title !== undefined
          ? [
              replay({ type: 'SET_TITLE', title: release.title }),
              { type: 'BLUR_TITLE' as const },
            ]
          : []),
        ...(label !== undefined ? [replay({ type: 'SET_LABEL', label })] : []),
        ...(release?.otherLabel !== undefined
          ? [
              replay({
                type: 'SET_OTHER_LABEL',
                otherLabel: release.otherLabel,
              }),
              { type: 'BLUR_OTHER_LABEL' as const },
            ]
          : []),
        ...(distributor !== undefined
          ? [replay({ type: 'SET_DISTRIBUTOR', distributor })]
          : []),
        ...(release?.otherDistributor !== undefined
          ? [
              replay({
                type: 'SET_OTHER_DISTRIBUTOR',
                otherDistributor: release.otherDistributor,
              }),
              { type: 'BLUR_OTHER_DISTRIBUTOR' as const },
            ]
          : []),
        ...(release?.date !== undefined
          ? [
              replay({
                type: 'SET_DATE',
                date: parseDate(release.date),
              }),
            ]
          : []),
        ...(release?.numberOfMinutes !== undefined
          ? [
              replay({
                type: 'SET_NUMBER_OF_MINUTES',
                numberOfMinutes: release.numberOfMinutes,
              }),
              { type: 'BLUR_NUMBER_OF_MINUTES' as const },
            ]
          : []),
        ...(release?.numberOfTracks !== undefined
          ? [
              replay({
                type: 'SET_NUMBER_OF_TRACKS',
                numberOfTracks: release.numberOfTracks,
              }),
              { type: 'BLUR_NUMBER_OF_TRACKS' as const },
            ]
          : []),
        ...(release?.isMajorityEnglish !== undefined
          ? [
              replay({
                type: 'SET_LANGUAGE',
                isMajorityEnglish: release.isMajorityEnglish,
              }),
            ]
          : []),
      ] satisfies Events[]
    ).forEach((event) => send(event));
  };

export const createReleaseSaveActions = (
  storage: EligibilityStorage | AddReleaseStorage
) => ({
  saveTitle: (_: Context, { title, replay }: SetTitleEvent) =>
    storage.add({ release: { title } }, replay),
  saveLabel: (_: Context, { label: { id: labelId }, replay }: SetLabelEvent) =>
    storage.add({ release: { labelId } }, replay),
  saveOtherLabel: (_: Context, event: SetOtherLabelEvent | SetLabelEvent) => {
    const value = match(event)
      .with({ type: 'SET_OTHER_LABEL' }, (ev) => ev.otherLabel)
      .with({ type: 'SET_LABEL' }, (ev) =>
        ev.label.name === 'Other' ? '' : undefined
      )
      .exhaustive();

    return storage.add({ release: { otherLabel: value } }, event.replay);
  },
  saveDistributor: (
    _: Context,
    { distributor: { id: distributorId }, replay }: SetDistributorEvent
  ) => storage.add({ release: { distributorId } }, replay),
  saveOtherDistributor: (
    _: Context,
    event: SetOtherDistributorEvent | SetDistributorEvent
  ) => {
    const value = match(event)
      .with({ type: 'SET_OTHER_DISTRIBUTOR' }, (ev) => ev.otherDistributor)
      .with({ type: 'SET_DISTRIBUTOR' }, (ev) =>
        ev.distributor.name === 'Other' ? '' : undefined
      )
      .exhaustive();

    return storage.add({ release: { otherDistributor: value } }, event.replay);
  },
  saveDate: (_: Context, { date, replay }: SetDateEvent) =>
    storage.add({ release: { date: date.toString() } }, replay),
  saveNumberOfMinutes: (
    _: Context,
    { numberOfMinutes, replay }: SetNumberOfMinutesEvent
  ) => storage.add({ release: { numberOfMinutes } }, replay),
  saveNumberOfTracks: (
    _: Context,
    { numberOfTracks, replay }: SetNumberOfTracksEvent
  ) => storage.add({ release: { numberOfTracks } }, replay),
  saveLanguage: (_: Context, { isMajorityEnglish, replay }: SetLanguageEvent) =>
    storage.add(
      {
        release: {
          isMajorityEnglish,
        },
      },
      replay
    ),
});
