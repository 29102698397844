import cn from 'classnames';
import { Link } from 'react-router-dom';
import { ZonedDateTime } from '@internationalized/date';
import { formatZonedDate } from '../../utils/formatZonedDateTime';
import { Button } from '../Button';
import { formatMoney } from '../../utils/formatMoney';
import { Feedback } from '../Feedback';
import { formatReviewStatus } from '../../utils/formatApplicationReviewStatus';
import {
  ApplicationReviewStatus,
  ApplicationStatus,
  ApplicationType,
} from '../../graphql/operations';
import { ApplicationStatusBadge } from '../ApplicationStatusBadge';

import styles from './ApplicationCard.module.scss';

interface ApplicationCardProps {
  type: ApplicationType;
  round?: string;
  status: ApplicationStatus;
  submittedAt: ZonedDateTime | null;
  createdAt: ZonedDateTime | null;
  admin?: ApplicationCardAdminProps;
  applicationLink: string;
  isComplete?: boolean;
}

type ApplicationCardAdminProps = {
  artistName: string;
  modifiedAt: ZonedDateTime | null;
  requested: number;
  approved: number;
  claim: number;
  paid: number;
  unused: number;
  tentativeTotal: number;
  unsettledRequests: number;
  pendingRequests: number;
  reviewStatus?: ApplicationReviewStatus;
};

const UserView = ({
  type,
  round,
  status,
  submittedAt,
  applicationLink,
  isComplete,
}: ApplicationCardProps) => {
  return (
    <div className={styles.card}>
      <div className={styles.titleRow}>
        <div className={styles.col}>
          <div className={styles.title}>
            <h3 className={styles.heading}>
              {type === ApplicationType.Core ? 'Core Funding' : 'Orion Funding'}
            </h3>
            <ApplicationStatusBadge status={status} isComplete={isComplete} />
          </div>
          <div className={styles.collapseRow}>
            <div>
              <dl className={styles.field}>
                <dt>Submitted:</dt>
                <dd className={styles.bold}>
                  {submittedAt ? formatZonedDate(submittedAt, 'long') : '-'}
                </dd>
              </dl>
            </div>
            <dl className={styles.field}>
              <dt>Round:</dt>
              {round ? <dd className={styles.bold}>{round}</dd> : '-'}
            </dl>
          </div>
        </div>
        <div className={styles.col}>
          <Button
            variant="secondary"
            label="View"
            size="small"
            linkTo={applicationLink}
          />
        </div>
      </div>
    </div>
  );
};

const AdminView = ({
  type,
  round,
  status,
  submittedAt,
  createdAt,
  admin,
  applicationLink,
  isComplete,
}: ApplicationCardProps) => {
  if (!admin) {
    return null;
  }

  return (
    <div className={cn(styles.card, styles.admin)}>
      <div className={styles.titleRow}>
        <div className={styles.col}>
          <div className={styles.title}>
            <Link className={styles.link} to={applicationLink}>
              <h3 className={styles.heading}>{admin.artistName}</h3>
            </Link>
            <ApplicationStatusBadge status={status} isComplete={isComplete} />
          </div>
          <div className={styles.collapseRow}>
            <dl className={styles.field}>
              <dt>Application Type:</dt>
              <dd className={styles.bold}>
                {type === ApplicationType.Core
                  ? 'Core Funding'
                  : 'Orion Funding'}
              </dd>
            </dl>
            <dl className={styles.field}>
              <dt>Round:</dt>
              {round ? <dd className={styles.bold}>{round}</dd> : '-'}
            </dl>
          </div>
          <div className={styles.collapseRow}>
            {submittedAt && (
              <dl className={styles.field}>
                <dt>Submitted:</dt>
                <dd className={styles.bold}>{formatZonedDate(submittedAt)}</dd>
              </dl>
            )}
            {!submittedAt && createdAt && (
              <dl className={styles.field}>
                <dt>Modified:</dt>
                <dd className={styles.bold}>
                  {formatZonedDate(admin.modifiedAt)}
                </dd>
              </dl>
            )}
            {status === ApplicationStatus.Approved && (
              <dl className={styles.field}>
                <dt>Modified:</dt>
                <dd className={styles.bold}>
                  {formatZonedDate(admin.modifiedAt)}
                </dd>
              </dl>
            )}
            {status !== ApplicationStatus.Approved && (
              <>
                <dl className={styles.field}>
                  <dt>Requested:</dt>
                  <dd className={styles.bold}>
                    {formatMoney(admin.requested)}
                  </dd>
                </dl>
                {status !== ApplicationStatus.Denied && (
                  <dl className={styles.field}>
                    <dt>Approved:</dt>
                    <dd className={styles.bold}>
                      {formatMoney(admin.tentativeTotal)}
                    </dd>
                  </dl>
                )}
              </>
            )}
          </div>
          {status === ApplicationStatus.Submitted && (
            <dl className={styles.field}>
              <dt>Review Status:</dt>
              <dd className={styles.bold}>
                {admin.reviewStatus
                  ? formatReviewStatus(admin.reviewStatus)
                  : 'None'}
              </dd>
            </dl>
          )}
        </div>
        <div className={styles.col}>
          <Button
            variant="secondary"
            label="View"
            size="small"
            linkTo={applicationLink}
          />
        </div>
      </div>
      {status === ApplicationStatus.Approved && (
        <div className={styles.collapseRow}>
          <div className={styles.row}>
            <dl className={styles.field}>
              <dt>Requested:</dt>
              <dd className={styles.bold}>{formatMoney(admin.requested)}</dd>
            </dl>
            <dl className={styles.field}>
              <dt>Approved:</dt>
              <dd className={styles.bold}>{formatMoney(admin.approved)}</dd>
            </dl>
          </div>
          <div className={styles.row}>
            <dl className={styles.field}>
              <dt>Claim:</dt>
              <dd className={styles.bold}>{formatMoney(admin.claim)}</dd>
            </dl>
            <dl className={styles.field}>
              <dt>Paid:</dt>
              <dd className={styles.bold}>{formatMoney(admin.paid)}</dd>
            </dl>
            <dl className={styles.field}>
              <dt>Unused:</dt>
              <dd className={styles.bold}>{formatMoney(admin.unused)}</dd>
            </dl>
          </div>
        </div>
      )}
      {admin.unsettledRequests > 0 && (
        <Feedback
          type="neutral"
          size="compact"
          icon="clock"
          className={styles.feedback}
        >
          <p className={styles.bold}>Unsettled Funding Requests</p>
        </Feedback>
      )}
      {admin.pendingRequests > 0 && (
        <Feedback
          type="neutral"
          size="compact"
          icon="clock"
          className={styles.feedback}
        >
          <p className={styles.bold}>Pending Funding Requests</p>
        </Feedback>
      )}
    </div>
  );
};

export function ApplicationCard(props: ApplicationCardProps) {
  return props.admin ? <AdminView {...props} /> : <UserView {...props} />;
}
