import { useState } from 'react';
import { Icon } from './Icon';
import cn from 'classnames';

import { Article } from './Article';
import styles from './Accordion.module.scss';

interface AccordionProps {
  id: string;
  className?: string;
  title: string;
  forceOpen: boolean;
  children: React.ReactNode;
}

export function Accordion({
  id,
  className,
  title,
  forceOpen,
  children,
}: AccordionProps) {
  const [open, setOpen] = useState(false);

  return (
    <details
      id={id}
      className={cn(styles.accordion, className)}
      onToggle={() => setOpen(!open)}
      open={forceOpen}
    >
      <summary
        className={cn(styles.row, styles.summary, {
          [styles.open]: open,
        })}
      >
        <Icon
          className={styles.icon}
          name={open ? 'upChevron' : 'downChevron'}
        />
        <h4 className={styles.title}>{title}</h4>
      </summary>
      <Article className={styles.content}>{children}</Article>
    </details>
  );
}
