import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from '@tanstack/react-table';
import { useWindowSize } from '../../hooks/useWindowSize';

import { Icon } from '../Icon';
import { Link } from '../Link';

import styles from './Table.module.scss';

interface ArtistRelease {
  release: string;
  href: string;
  label: string;
  signedOff: boolean;
  boardStatus: string;
  modifiedDate: string;
  requestTotal: number;
  approvedTotal: number;
  rating: number;
}

interface ArtistReleaseTableProps {
  data: ArtistRelease[];
}

const columnHelper = createColumnHelper<ArtistRelease>();

export function ArtistReleaseTable({ data }: ArtistReleaseTableProps) {
  const { screen } = useWindowSize();
  const columns = [
    columnHelper.accessor('release', {
      header: 'Release - Artist',
      cell: (info) => (
        <Link href={info.row.original.href}>{info.getValue()}</Link>
      ),
    }),
    columnHelper.accessor('label', {
      header: 'Label',
      cell: (info) => info.renderValue(),
    }),
    ...(screen !== 'mobile'
      ? [
          columnHelper.accessor('signedOff', {
            header: 'Signed Off',
            cell: (info) =>
              info.getValue() ? <Icon name="checkmark" /> : null,
          }),
          columnHelper.accessor('boardStatus', {
            header: 'Board Status',
            cell: (info) => info.renderValue(),
          }),
          columnHelper.accessor('modifiedDate', {
            header: 'Modified Date',
            cell: (info) => info.renderValue(),
          }),
          columnHelper.accessor('requestTotal', {
            header: 'Request Total',
            cell: (info) =>
              info.renderValue()?.toLocaleString('en-US', {
                style: 'currency',
                currency: 'USD',
              }),
          }),
          columnHelper.accessor('approvedTotal', {
            header: 'Approved Total',
            cell: (info) =>
              info.renderValue()?.toLocaleString('en-US', {
                style: 'currency',
                currency: 'USD',
              }),
          }),
          columnHelper.accessor('rating', {
            header: 'Rating',
            cell: (info) => info.renderValue(),
          }),
        ]
      : []),
  ];

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
  });

  return (
    <table>
      <thead className={styles.tableHeader}>
        {table.getHeaderGroups().map((headerGroup) => (
          <tr key={headerGroup.id}>
            {headerGroup.headers.map((header) => (
              <th key={header.id}>
                {flexRender(
                  header.column.columnDef.header,
                  header.getContext()
                )}
              </th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody className={styles.tableBody}>
        {table.getRowModel().rows.map((row) => (
          <tr key={row.id}>
            {row.getVisibleCells().map((cell) => (
              <td key={cell.id}>
                {flexRender(cell.column.columnDef.cell, cell.getContext())}
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  );
}
