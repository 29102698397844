import { ProgressTooltip } from '../ProgressTooltip';

import styles from './ProgressTooltipShowcase.module.scss';

export function ProgressTooltipShowcase() {
  return (
    <div className={styles.container}>
      <div className="showcase-label">Progress Tooltips</div>
      <div className={styles.content}>
        <ProgressTooltip status="active" text="Upload Documents" />
        <ProgressTooltip status="completed" text="50% Payment" />
      </div>
      <div className={styles.content}>
        <ProgressTooltip status="disabled" text="Update Tour Information" />
        <ProgressTooltip status="waiting" text="Final Payment" />
      </div>
    </div>
  );
}
