import cn from 'classnames';
import { useRef, useState } from 'react';
import { useDatePickerState } from 'react-stately';
import { useDatePicker, useLocale, AriaDatePickerProps } from 'react-aria';
import {
  createCalendar,
  now,
  toCalendarDate,
  toZoned,
  ZonedDateTime,
} from '@internationalized/date';

import { Icon } from '../Icon';
import { DateField } from './DateField';
import { Popover } from './Popover';
import { TimePeriodPanel } from './TimePeriodPanel';
import { Button } from 'react-aria-components';
import { Calendar } from './Calendar';

import styles from './DatePicker.module.scss';

type DatePickerProps = AriaDatePickerProps<ZonedDateTime> & {
  formFieldStatus?: 'default' | 'text' | 'error';
  'aria-label': string;
  value: ZonedDateTime | null;
  timezone: string;
  onChange: (date: ZonedDateTime) => void;
};

export function DatePickerWithTime({
  formFieldStatus = 'default',
  ...props
}: DatePickerProps) {
  const { locale } = useLocale();
  const [showMonthView, setShowMonthView] = useState(false);
  const ref = useRef(null);
  const buttonRef = useRef(null);

  props.granularity = 'minute';
  props.hideTimeZone = true;

  // We need the placeholder to be set otherwise we won't get a
  // ZonedDateTime object back. It seems that the underlying code
  // needs to be given a hint to return a ZonedDateTime.
  props.placeholderValue = now(props.timezone);

  const state = useDatePickerState({
    ...props,
    shouldCloseOnSelect: true,
  });

  const { groupProps, fieldProps, calendarProps } = useDatePicker(
    props,
    state,
    ref
  );

  return (
    <>
      <div
        className={cn(styles.container, {
          [styles.error]: formFieldStatus === 'error',
        })}
        ref={ref}
      >
        <div {...groupProps} className={styles.row}>
          <DateField
            {...fieldProps}
            value={state.dateValue}
            locale={locale}
            createCalendar={createCalendar}
          />
          <Button
            type="button"
            onPress={() => {
              state.setOpen(!state.isOpen);
              setShowMonthView(false);
            }}
            ref={buttonRef}
          >
            <Icon name="calendar" className={styles.icon} />
          </Button>
        </div>
      </div>
      <Popover
        divRef={buttonRef}
        open={state.isOpen}
        onClose={() => state.setOpen(false)}
      >
        {!showMonthView && (
          <Calendar
            {...calendarProps}
            onShowMonthView={() => setShowMonthView(true)}
            locale={locale}
            value={props.value}
            defaultValue={now(props.timezone)}
            createCalendar={createCalendar}
            onChange={(date) =>
              props.onChange(
                toZoned(date, props.timezone).set({ hour: 0, minute: 0 })
              )
            }
          />
        )}
        {showMonthView && (
          <div>
            <TimePeriodPanel
              value={toCalendarDate(props.value ?? now(props.timezone))}
              onDone={(date) => {
                props.onChange(toZoned(date, props.timezone));
                setShowMonthView(false);
              }}
            />
          </div>
        )}
      </Popover>
    </>
  );
}
