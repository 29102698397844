import {
  offset,
  useClick,
  useDismiss,
  useFloating,
  useFocus,
  useInteractions,
  useListNavigation,
} from '@floating-ui/react';
import { useRef, useState } from 'react';
import cn from 'classnames';
import { Icon } from './Icon';
import { useOnKeyPress } from '../hooks/useOnKeyPress';
import { Link } from './Link';

import styles from './PopupMenu.module.scss';

type Option = {
  label: string;
  href: string;
};

interface PopupMenuProps {
  label: string;
  options: Option[];
}

export function PopupMenu({ label, options }: PopupMenuProps) {
  const [activeIndex, setActiveIndex] = useState<number | null>(null);
  const [isOpen, setIsOpen] = useState(false);
  const { refs, floatingStyles, context } = useFloating({
    open: isOpen,
    onOpenChange: setIsOpen,
    placement: 'bottom-end',
    middleware: [offset({ mainAxis: 8, crossAxis: 16 })],
  });

  useOnKeyPress(
    'Tab',
    () => isOpen,
    () => setIsOpen(false)
  );

  const listRef = useRef<HTMLAnchorElement[]>([]);

  const listNavigation = useListNavigation(context, {
    listRef,
    activeIndex,
    onNavigate: setActiveIndex,
  });

  const click = useClick(context);
  const dismiss = useDismiss(context);
  const focus = useFocus(context);

  const { getReferenceProps, getFloatingProps, getItemProps } = useInteractions(
    [click, dismiss, focus, listNavigation]
  );

  return (
    <div className={styles.container}>
      <div className={styles.button}>
        <button
          type="button"
          ref={refs.setReference}
          {...getReferenceProps()}
          className={cn(styles.label, {
            [styles.open]: isOpen,
          })}
        >
          {label}
          <Icon
            name="downChevron"
            className={cn({ [styles.flip]: isOpen, [styles.closed]: !isOpen })}
          />
        </button>
      </div>

      {isOpen && (
        <div
          ref={refs.setFloating}
          style={floatingStyles}
          className={styles.menu}
          {...getFloatingProps()}
        >
          {options.map((option, index) => (
            <Link
              key={option.label}
              className={styles.link}
              href={option.href}
              {...getItemProps()}
              ref={(node) => {
                if (node) {
                  listRef.current[index] = node;
                }
              }}
              onClick={() => setIsOpen(false)}
            >
              {option.label}
            </Link>
          ))}
        </div>
      )}
    </div>
  );
}
