import { useState } from 'react';
import * as openpgp from 'openpgp';
import { Button } from '../components/Button';
import { PageHeader } from '../components/PageHeader';
import { Form } from '../components/Form';

export function BankAccounts() {
  const [encryptedMessage, setEncryptedMessage] = useState('');
  const [privateKeyArmored, setPrivateKeyArmored] = useState('');
  const [result, setResult] = useState('');

  return (
    <>
      <PageHeader heading="Bank Accounts" />
      <div>
        <div>
          <h2>Search</h2>
          <Form
            onSubmit={() => {
              async function handleSubmit() {
                const message = await openpgp.readMessage({
                  armoredMessage: encryptedMessage,
                });

                const privateKey = await openpgp.readPrivateKey({
                  armoredKey: privateKeyArmored,
                });

                const { data: decrypted } = await openpgp.decrypt({
                  message,
                  decryptionKeys: privateKey,
                });

                setResult(decrypted.toString());
              }

              handleSubmit().catch((err) => {
                console.log(err);
              });
            }}
          >
            <label htmlFor="message">Encrypted Message</label>
            <textarea
              id="message"
              value={encryptedMessage}
              onChange={(e) => setEncryptedMessage(e.target.value)}
            />
            <label htmlFor="private-key">Private Key</label>
            <textarea
              id="private-key"
              value={privateKeyArmored}
              onChange={(e) => setPrivateKeyArmored(e.target.value)}
            />
            <div>
              <Button type="submit" variant="secondary" label="Decrypt" />
            </div>
          </Form>

          <h2>Result</h2>
          <p>{result}</p>
        </div>
      </div>
    </>
  );
}
