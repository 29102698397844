import { useState } from 'react';
import { RangeSlider } from '../RangeSlider';
import { TextInput } from '../TextInput';

import styles from './RangeSliderShowcase.module.scss';

export function RangeSliderShowcase() {
  const [val, setVal] = useState(6000);

  return (
    <div className={styles.container}>
      <span className="showcase-label">Sales Slider</span>
      <RangeSlider value={val} range={{ min: 1000, max: 110250 }} />
      <TextInput
        id="value"
        type="number"
        label="Value"
        value={val.toString()}
        onChange={(val: string) => setVal(Number(val))}
      />
      <span className="showcase-label">Empty Sales Slider</span>
      <RangeSlider value={val} />
    </div>
  );
}
