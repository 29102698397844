import { createMachine, assign } from 'xstate';

type Events =
  | { type: 'ADD_STEP' }
  | { type: 'REMOVE_STEP' }
  | { type: 'INCREASE' }
  | { type: 'DECREASE' };

type Context = { steps: number; active: number };

export const machine = createMachine(
  {
    predictableActionArguments: true,
    tsTypes: {} as import('./PaginationShowcaseMachine.typegen').Typegen0,
    schema: {
      context: {} as Context,
      events: {} as Events,
    },
    context: { steps: 8, active: 1 },
    initial: 'idle',
    states: {
      idle: {
        on: {
          ADD_STEP: { actions: 'addStep' },
          REMOVE_STEP: { cond: 'isShrinkable', actions: 'removeStep' },
          INCREASE: { cond: 'isInProgress', actions: 'increase' },
          DECREASE: { cond: 'isStarted', actions: 'decrease' },
        },
      },
    },
  },
  {
    guards: {
      isShrinkable: ({ steps }) => steps > 2,
      isInProgress: ({ active, steps }) => active < steps,
      isStarted: ({ active }) => active > 1,
    },
    actions: {
      addStep: assign(({ steps }) => ({ steps: steps + 1 })),
      removeStep: assign(({ steps, active }) => ({
        steps: steps - 1,
        active: active >= steps ? steps - 1 : active,
      })),
      increase: assign(({ active }) => ({
        active: active + 1,
      })),
      decrease: assign(({ active }) => ({
        active: active - 1,
      })),
    },
  }
);
