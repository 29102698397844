import classNames from 'classnames';

import styles from './ProgressTooltip.module.scss';

type Status = 'active' | 'completed' | 'disabled' | 'waiting';

interface ProgressTooltipProps {
  text: string;
  status: Status;
}

export function ProgressTooltip({ text, status }: ProgressTooltipProps) {
  const tooltipClassName = classNames(styles.progressTooltip, styles[status]);

  const textClassNames = classNames(styles.text, styles[status]);

  const arrowClassNames = classNames(styles.arrow, styles[status]);

  return (
    <div className={tooltipClassName}>
      <div className={arrowClassNames}></div>
      <div className={textClassNames}>{text}</div>
    </div>
  );
}
