import { z } from 'zod';

export const presignedUrlMessageResponse = z.discriminatedUnion('type', [
  z.object({ type: z.literal('presigned-url'), url: z.string().url() }),
  z.object({ type: z.literal('complete'), attachmentId: z.string() }),
]);

export type PresignedUrlMessageResponse = z.infer<
  typeof presignedUrlMessageResponse
>;
