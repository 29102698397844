import { useState } from 'react';
import { AccountsTable } from '../Tables/AccountsTable';
import { ArtistReleaseTable } from '../Tables/ArtistReleaseTable';
import { BoardMemberTable } from '../Tables/BoardMemberTable';
import { GenreTable } from '../Tables/GenreTable';
import { Release, ReleaseTable } from '../Tables/ReleaseTable';

const defaultBoardMembers = [
  {
    name: 'Alanna Stuart',
    email: 'alannastuart@gmail.com',
    access: true,
  },
];

const accounts = [
  {
    artist: 'Fred',
    email: 'fred@silverorange.com',
  },
];

const artistRelease = [
  {
    release: 'Apashe - I Killed The Orchestra',
    href: '#',
    label: 'Kannibalen Records',
    signedOff: true,
    boardStatus: 'Approved',
    modifiedDate: 'Aug 24, 2022',
    requestTotal: 5000,
    approvedTotal: 5000,
    rating: 1,
  },
];

const defaultGenres = [
  {
    name: 'Adult Contemporary',
  },
];

const releases: Release[] = [
  {
    title: 'I Killed The Orchestra',
    href: '#',
    label: 'Kannibalen Records',
    date: 'October 20, 2022',
    status: 'eligible',
  },
];

export function TableShowcase() {
  const [boardMembers, setBoardMembers] = useState(defaultBoardMembers);
  const [genres, setGenres] = useState(defaultGenres);

  const onBoardMemberChange = (name: string) => {
    setBoardMembers(
      boardMembers.map((member) => ({
        ...member,
        access: name === member.name ? !member.access : member.access,
      }))
    );
  };

  return (
    <div>
      <span className="showcase-label">Table Row</span>
      <ReleaseTable showHeader={true} data={releases} />
      <BoardMemberTable data={boardMembers} onChange={onBoardMemberChange} />
      <GenreTable
        data={genres}
        onAddGenre={(genre) => setGenres([...genres, { name: genre }])}
        onRemoveGenre={(genre) =>
          setGenres(genres.filter((el) => el.name != genre))
        }
        onEdit={() => null}
        onView={() => null}
        onMoveUp={() => null}
        onMoveDown={() => null}
      />
      <ArtistReleaseTable data={artistRelease} />
      <AccountsTable data={accounts} />
    </div>
  );
}
