import * as openpgp from 'openpgp';
import { GraphQLClient } from 'graphql-request';
import {
  ApplicationType,
  GetDirectDepositAccountsDocument,
} from '../../graphql/operations';
import {
  directDepositExportRow,
  DirectDepositExportRow,
} from '../../schemas/admin/directDepositExportRow';

export const createGetDirectDepositExportRowsPromise =
  (client: GraphQLClient) =>
  async ({
    fundingRoundId,
    privateKey,
  }: {
    fundingRoundId?: string;
    privateKey?: string;
  }) => {
    if (!fundingRoundId) {
      throw new Error('Must specify funding round');
    }

    const { fundingRound } = await client.request(
      GetDirectDepositAccountsDocument,
      {
        id: fundingRoundId,
      }
    );

    const applications = fundingRound?.applications;

    if (!applications || !fundingRound) {
      throw new Error('Unable to fetch applications for funding round');
    }

    const rows = applications.reduce<DirectDepositExportRow[]>(
      (acc, application) => {
        if (application.touringDirectDepositAccount) {
          acc.push(
            directDepositExportRow.parse([
              application.artist?.name,
              application.type,
              fundingRound.title,
              'touring',
              application.touringDirectDepositAccount.name,
              application.touringDirectDepositAccount.institution,
              application.touringDirectDepositAccount.transit,
              privateKey
                ? application.touringDirectDepositAccount.account
                : application.touringDirectDepositAccount.maskedAccount,
            ])
          );
        }

        if (application.fundingRequestDirectDepositAccount) {
          acc.push(
            directDepositExportRow.parse([
              application.artist?.name,
              application.type,
              fundingRound.title,
              application.type === ApplicationType.Core
                ? 'digital-content'
                : 'funding-request',
              application.fundingRequestDirectDepositAccount.name,
              application.fundingRequestDirectDepositAccount.institution,
              application.fundingRequestDirectDepositAccount.transit,
              privateKey
                ? application.fundingRequestDirectDepositAccount.account
                : application.fundingRequestDirectDepositAccount.maskedAccount,
            ])
          );
        }

        return acc;
      },
      []
    );

    if (!privateKey) {
      return rows;
    }

    const key = await openpgp.readPrivateKey({
      armoredKey: privateKey,
    });

    const decryptedRows = await Promise.all(
      rows.map((row) => getDecryptedRow(row, key))
    );

    return decryptedRows;
  };

async function getDecryptedRow(
  row: DirectDepositExportRow,
  key: openpgp.PrivateKey
) {
  const message = await openpgp.readMessage({
    armoredMessage: row[7],
  });

  const { data: decrypted } = await openpgp.decrypt({
    message,
    decryptionKeys: key,
  });

  const decryptedAccount = decrypted.toString();

  const rowCopy = [...row];
  rowCopy[7] = decryptedAccount;
  return rowCopy;
}

export type CreateGetDirectDepositExportRowsPromise = ReturnType<
  typeof createGetDirectDepositExportRowsPromise
>;
export type CreateGetDirectDepositExportRowsPromiseResult = Awaited<
  ReturnType<CreateGetDirectDepositExportRowsPromise>
>;
