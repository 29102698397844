import { Card } from '../Card';
import { Icon } from '../Icon';

import styles from './CardShowcase.module.scss';

export function CardShowcase() {
  return (
    <div className={styles.container}>
      <div className="showcase-label">Cards</div>
      <Card
        width="md"
        icon="list"
        title="View Eligibility Criteria"
        buttonLabel="Download PDF"
        buttonIcon="download"
      >
        The fund is dedicated solely for the English-language music industry. An
        equivalent program, Fonds RadioStar, has been created uniquely for
        French-language Canadian artists.
      </Card>
      <Card
        width="sm"
        icon="user"
        title="Chip Sutherland"
        subTitle="Executive Director"
      >
        <div className={styles.row}>
          <Icon name="phone" />
          <p>(416) 597-6622, Ext. 23</p>
        </div>
        <div className={styles.row}>
          <Icon name="send" />
          <p className={styles.email}>chipsutherland@starmaker.ca</p>
        </div>
      </Card>
      <Card width="lg" display="row" icon="user" title="Phone">
        <p>
          call us toll-free at{' '}
          <span className={styles.bold}>1-888 256-2211</span> or
        </p>
        <p>
          <span className={styles.bold}>(416) 597-6622</span>
        </p>
      </Card>
      <Card width="lg" display="row" icon="send" title="Email">
        <p className={styles.email}>michellefarres@starmaker.ca</p>
      </Card>
      <Card
        width="lg"
        display="row"
        icon="mail"
        title="Mailing Address"
        subTitle="Canadian Starmaker Fund"
      >
        <p>372 Bat Street. 302</p>
        <p>Toronto, Ontario M5H 2W9</p>
      </Card>
      <Card
        title="2020/2021"
        buttonIcon="download"
        buttonLabel="Download PDF"
      />
    </div>
  );
}
