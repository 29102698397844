import { useState } from 'react';
import { RadioOption } from '../RadioOption';
import styles from './RadioOptionShowcase.module.scss';

export function RadioOptionShowcase() {
  const [group1, setGroup1] = useState<string | null>(null);
  const [group2, setGroup2] = useState<string | null>(null);
  return (
    <div className={styles.container}>
      <span className="showcase-label">Radio Buttons</span>
      <RadioOption
        label="Set 1 option 1"
        description='This is a description for "Set 1 option 1"'
        onChange={() => setGroup1('Set 1 option 1')}
        checked={group1 === 'Set 1 option 1'}
      />
      <RadioOption
        label="Set 1 option 2"
        description='This is a description for "Set 1 option 2"'
        onChange={() => setGroup1('Set 1 option 2')}
        checked={group1 === 'Set 1 option 2'}
      />
      <RadioOption
        label="Disabled"
        description='This is a description for "Disabled"'
        disabled={true}
        onChange={() => null}
        checked={false}
      />
      <RadioOption
        label="Disabled"
        description='This is a description for "Disabled"'
        disabled={true}
        onChange={() => null}
        checked={true}
      />
      <RadioOption
        label="Error"
        description='This is a description for "Error"'
        error={true}
        onChange={() => setGroup2('Set 2 option 1')}
        checked={group2 === 'Set 2 option 1'}
      />
      <RadioOption
        label="Set 2"
        description='This is a description for "Set 2"'
        onChange={() => setGroup2('Set 2 option 2')}
        checked={group2 === 'Set 2 option 2'}
      />
      <RadioOption
        label="Set 2"
        description='This is a description for "Set 2"'
        onChange={() => setGroup2('Set 2 option 3')}
        checked={group2 === 'Set 2 option 3'}
      />
    </div>
  );
}
