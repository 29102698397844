import { CheckOption } from '../CheckOption';
import { useState } from 'react';

import styles from './CheckOptionShowcase.module.scss';

export function CheckOptionShowcase() {
  const [checkboxes, setCheckboxes] = useState([
    {
      label: 'Normal',
      checked: false,
    },
    {
      label: 'Error',
      checked: false,
      error: true,
    },
    {
      label: 'Disabled unchecked',
      checked: false,
      disabled: true,
    },
    {
      label: 'Disabled checked',
      checked: true,
      disabled: true,
    },
  ]);

  const onChange = (checkboxIndex: number) => {
    setCheckboxes(
      checkboxes.map((el, index) => ({
        ...el,
        checked: checkboxIndex === index ? !el.checked : el.checked,
      }))
    );
  };

  return (
    <div className={styles.container}>
      <div className="showcase-label">Check Options</div>
      {checkboxes.map((el, index) => (
        <CheckOption
          key={el.label}
          label={el.label}
          error={el.error ?? false}
          disabled={el.disabled ?? false}
          checked={el.checked}
          onChange={() => onChange(index)}
        />
      ))}
    </div>
  );
}
