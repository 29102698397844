import { ZonedDateTime } from '@internationalized/date';
import { ReopenDeadlineParagraph } from '../ClaimFunds/ReopenDeadlineParagraph';
import { Feedback } from '../Feedback';

interface Props {
  notes: string;
  claimsResubmissionDeadline: ZonedDateTime;
}

export function ClaimsReopenedFeedback({
  notes,
  claimsResubmissionDeadline,
}: Props) {
  return (
    <Feedback type="warning" title="Claim Reopened" icon="alertTriangle">
      <p>{notes}</p>
      <ReopenDeadlineParagraph
        claimsResubmissionDeadline={claimsResubmissionDeadline}
      />
    </Feedback>
  );
}
