import {
  DirectDepositDownloadMachineSender,
  DirectDepositDownloadMachineState,
} from '../../../machines/Admin/DirectDepositDownloadMachine';
import { Feedback } from '../../Feedback';
import { FormField } from '../../FormField';
import { InlineFormContainer } from '../../InlineFormContainer';
import { InlineFormHeading } from '../../InlineFormHeading';
import { Select } from '../../Select';
import { UploadButton } from '../../UploadButton';
import styles from './DirectDepositDownloadView.module.scss';

interface Props {
  state: DirectDepositDownloadMachineState;
  send: DirectDepositDownloadMachineSender;
  onCancel: () => void;
}

export function DirectDepositDownloadView({ state, send, onCancel }: Props) {
  return (
    <InlineFormContainer
      primaryButtonText="Download"
      primaryButtonOnClick={() => send('DOWNLOAD')}
      secondaryButtonText="Cancel"
      secondaryButtonOnClick={onCancel}
    >
      <InlineFormHeading>Download Direct Deposit Information</InlineFormHeading>
      <FormField id="fundingRound" label="Funding Round" feedbackType="warning">
        <Select
          id="fundingRound"
          label="Funding Round"
          items={state.context.fundingRounds}
          itemToKey={(item) => item?.id}
          selectedItem={state.context.fundingRounds.find(
            (fundingRound) => fundingRound.id === state.context.fundingRoundId
          )}
          onChange={(fundingRound) =>
            send({
              type: 'UPDATE_SELECTED_FUNDING_ROUND_ID',
              id: fundingRound?.id,
            })
          }
          getItemText={(item) => (item ? `Round ${item.title}` : '')}
        />
      </FormField>
      {state.context.privateKey ? (
        <p className={styles.privateKeySet}>Private key set</p>
      ) : (
        <>
          <UploadButton
            className={styles.upload}
            buttonLabel="Browse Private Key File"
            multiple={false}
            isUploading={false}
            onClick={(files) => {
              void files[0].text().then((privateKey) => {
                send({ type: 'SET_PRIVATE_KEY', privateKey });
              });
            }}
          />
          <p>Leave blank to download with masked account numbers.</p>
        </>
      )}
      {state.matches('error') && (
        <Feedback
          type="warning"
          title="There was an error downloading the direct deposit information."
        />
      )}
    </InlineFormContainer>
  );
}
