import { Button } from '../../Button';
import { FormField } from '../../FormField';
import { TextInput } from '../../TextInput';
import cn from 'classnames';
import {
  NewsletterMachineSender,
  NewsletterMachineState,
} from '../../../machines/components/NewsletterMachine';

import styles from '../CTA.module.scss';
import { match } from 'ts-pattern';
import { FormFieldLabel } from '../../FormFieldLabel';

interface NewsletterViewProps {
  state: NewsletterMachineState;
  send: NewsletterMachineSender;
}

export function NewsletterView({ state, send }: NewsletterViewProps) {
  return (
    <div className={cn(styles.cta, 'dark')}>
      <div className="max-w-xl">
        <div className={styles.content}>
          {!state.matches('done') && (
            <>
              <div className={styles.header}>
                <h2>Stay Informed with Our Newsletter</h2>
                <p>
                  Subscribe to receive quarterly updates on policy changes,
                  program developments, and application rounds directly to your
                  inbox.
                </p>
              </div>
              <div className={cn(styles.col)}>
                <FormFieldLabel label="Enter Email" className={styles.white} />
                <div className={styles.newsletterButtons}>
                  <div className={styles.row}>
                    <FormField
                      htmlFor="email"
                      feedbackType="warning"
                      formFieldStatus={
                        state.matches('email.invalid') || state.matches('error')
                          ? 'error'
                          : 'default'
                      }
                      infoLabel={match(state)
                        .when(
                          () => state.matches('email.invalid'),
                          () => 'This doesn’t appear to be a valid email.'
                        )
                        .when(
                          () => state.matches('error'),
                          () =>
                            'An error occured while trying to subscribe you to our newsletter.'
                        )
                        .otherwise(() => '')}
                      className={styles.black}
                    >
                      <TextInput
                        id="email"
                        value={state.context.email}
                        onChange={(email) => send({ type: 'SET_EMAIL', email })}
                        onBlur={() => send('BLUR_EMAIL')}
                      />
                    </FormField>
                    <Button
                      buttonClassName={styles.padding}
                      label={
                        state.matches('sending')
                          ? 'Sending...'
                          : 'Subscribe Now'
                      }
                      disabled={state.matches('sending')}
                      onClick={() => send('SUBMIT')}
                    />
                  </div>
                </div>
              </div>
            </>
          )}
          {state.matches('done') && (
            <div className={styles.thanks}>
              <h2>Thank you!</h2>
              <p>
                You’re so close to receiving quarterly updates on policy
                changes, program developments, and application rounds directly
                to your inbox. Check your email to verify your subscription.
              </p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
