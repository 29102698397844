import { useNavigate, useParams } from 'react-router-dom';
import { machine as ReleaseDetailsMachine } from '../../machines/Release/ReleaseDetailsMachine';
import { machine as ReleaseSalesMachine } from '../../machines/Release/ReleaseSalesMachine';
import { machine as ReleaseMasterOwnershipMachine } from '../../machines/Release/ReleaseMasterOwnershipMachine';
import { machine as ReleaseSinglesMachine } from '../../machines/Release/ReleaseSinglesMachine';
import { ReleaseController } from './ReleaseController';
import { createGetReleasePagePromise } from '../../promises/createGetReleasePagePromise';
import { useGraphQLClient } from '../../components/GraphQLClientProvider';
import { createUpdateReleasePromise } from '../../promises/createUpdateReleasePromise';
import { createUpdateReleaseSalesPromise } from '../../promises/createUpdateReleaseSalesPromise';
import { createDeleteSinglePromise } from '../../promises/deleteSinglePromise';
import { createDeleteReleasePromise } from '../../promises/deleteReleasePromise';

import { createCreateSinglePromise } from '../../promises/createSinglePromise';
import { createUpdateSinglePromise } from '../../promises/updateSinglePromise';
import { withDelay } from '../../utils/withDelay';
import { useIsAdmin } from '../../hooks/useIsAdmin';

export const Release = () => {
  const { artistId, releaseId } = useParams();
  const isAdmin = useIsAdmin();
  const client = useGraphQLClient();
  const navigate = useNavigate();
  if (!artistId || !releaseId) {
    navigate('/dashboard');
    return null;
  }

  return (
    <ReleaseController
      client={client}
      options={{
        context: {
          createSinglePromise: createCreateSinglePromise(client),
          updateSinglePromise: createUpdateSinglePromise(client),
          isAdmin,
        },
        actions: {
          redirectToDashboard: () => {
            navigate(`/artist/${artistId}`, { replace: true });
          },
        },
        services: {
          getReleasePage: withDelay(
            createGetReleasePagePromise(client, {
              releaseId,
              artistId,
            })
          ),
          deleteRelease: createDeleteReleasePromise(client, releaseId),
          releaseDetailsMachine: ReleaseDetailsMachine.withConfig({
            services: {
              updateRelease: createUpdateReleasePromise(
                client,
                releaseId,
                ({
                  title,
                  label,
                  otherLabel,
                  distributor,
                  otherDistributor,
                  date,
                  isMajorityEnglish,
                  numberOfTracks,
                  numberOfMinutes,
                }) => ({
                  title,
                  otherLabel,
                  otherDistributor,
                  date,
                  isMajorityEnglish,
                  numberOfTracks: Number(numberOfTracks),
                  numberOfMinutes: Number(numberOfMinutes),
                  labelId: label.id,
                  distributorId: distributor.id,
                })
              ),
            },
          }),
          releaseSalesMachine: ReleaseSalesMachine.withConfig({
            services: {
              updateReleaseSales: createUpdateReleaseSalesPromise(
                client,
                releaseId,
                (context) => ({
                  genreId: '',
                  ...context.values,
                  releaseId,
                  releasesScanned: Number(context.values.releasesScanned),
                  tracksDownloaded: Number(context.values.tracksDownloaded),
                  tracksStreamed: Number(context.values.tracksStreamed),
                })
              ),
            },
          }),
          releaseMasterOwnershipMachine:
            ReleaseMasterOwnershipMachine.withConfig({
              services: {
                updateRelease: createUpdateReleasePromise(
                  client,
                  releaseId,
                  (context) => ({
                    ...context.values,
                  })
                ),
              },
            }),
          releaseSinglesMachine: ReleaseSinglesMachine.withConfig({
            services: {
              deleteSingle: createDeleteSinglePromise(client),
            },
          }),
        },
      }}
      artistId={artistId}
    />
  );
};
