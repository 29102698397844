import cn from 'classnames';
import { FundingRoundStatusType } from '../../../graphql/operations';
import { FundingRound } from '../../../schemas/fundingRound/fundingRoundSchema';
import { RoundDetails } from './RoundDetails';
import { RoundTitle } from './RoundTitle';
import { RoundSubtitle } from './RoundSubtitle';

import styles from './RoundCard.module.scss';

export interface RoundCardProps {
  round: FundingRound;
}
export function RoundCard({ round }: RoundCardProps) {
  const { title, allowOrion, status } = round;

  return (
    <div className={styles.card}>
      <div
        className={cn(
          styles.round,
          status === FundingRoundStatusType.OpenForApplications
            ? styles.openForApplications
            : styles.closedForApplications
        )}
      >
        <RoundTitle title={title} status={status} />
        <RoundSubtitle status={status} allowOrion={allowOrion} />
      </div>
      <RoundDetails round={round} />
    </div>
  );
}
