import React, { useState } from 'react';
import { FieldSet } from '../FieldSet';
import { RadioOption } from '../RadioOption';
import { Select } from '../Select';
import { TextInput } from '../TextInput';

import styles from './FieldSetShowcase.module.scss';

export function FieldSetShowcase() {
  const [selectedItem, setSelectedItem] = useState('');
  const [email, setEmail] = useState('');
  const onChange = (e: React.FormEvent<HTMLInputElement>) => {
    setSelectedItem(e.currentTarget.value);
  };
  return (
    <div className={styles.container}>
      <span className="showcase-label">FieldSet</span>
      <FieldSet
        label="Field name"
        direction="horizontal"
        infoLabel="Lorem ipsum dolor sit amet"
        required={true}
      >
        <RadioOption label="Yes" onChange={onChange} checked={false} />
        <RadioOption label="No" onChange={onChange} checked={false} />
        <RadioOption label="Maybe" onChange={onChange} checked={false} />
      </FieldSet>
      <div>Selected item: {selectedItem}</div>
      <FieldSet
        label="Field name"
        infoLabel="Lorem ipsum dolor sit amet"
        required={true}
      >
        <RadioOption label="Option 1" onChange={onChange} checked={false} />
        <RadioOption label="Option 2" onChange={onChange} checked={false} />
        <RadioOption label="Option 3" onChange={onChange} checked={false} />
      </FieldSet>
      <div>Selected item: {selectedItem}</div>
      <FieldSet
        label="Field name"
        infoLabel="Lorem ipsum dolor sit amet"
        required={true}
      >
        <TextInput
          id="email"
          value={email}
          onChange={setEmail}
          label="user@email.com"
        />
      </FieldSet>
      <FieldSet
        label="Field name"
        infoLabel="Lorem ipsum dolor sit amet"
        required={true}
      >
        <Select
          id="months"
          label="Select"
          items={['January']}
          itemToKey={(item) => item}
          selectedItem={null}
          getItemText={(item) => item}
          onChange={() => null}
        />
      </FieldSet>
      <FieldSet label="Field name" infoLabel="Not required" required={false}>
        <TextInput
          id="email"
          value={email}
          onChange={setEmail}
          label="user@email.com"
        />
      </FieldSet>
    </div>
  );
}
