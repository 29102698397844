import { Button } from '../Button';
import { FilterButton } from '../FilterButton';
import { useState } from 'react';
import { Tab } from '../Tab';

import styles from './ButtonShowcase.module.scss';

export function ButtonShowcase() {
  const [filterOn, setFilterOn] = useState(false);

  return (
    <div className={styles.container}>
      <div id="Primary Buttons" className="showcase-label">
        Primary Buttons
      </div>
      <div className={styles.row}>
        <Button variant="primary" label="Icons" startIcon="leftChevron" />
        <Button variant="primary" label="Icons" endIcon="rightChevron" />
        <Button
          variant="primary"
          label="Icons"
          startIcon="leftChevron"
          endIcon="rightChevron"
        />
        <Button variant="primary" label="Button Text" />
      </div>
      <div className={styles.column}>
        <Button variant="primary" label="Disabled" disabled={true} />
        <Button variant="primary" label="Button Text" size="small" />
        <Button
          variant="primary"
          label="Button Text"
          size="small"
          startIcon="leftChevron"
          endIcon="rightChevron"
        />
        <Button
          variant="primary"
          label="Button Text"
          size="small"
          startIcon="leftChevron"
        />
        <Button
          variant="primary"
          label="Button Text"
          size="small"
          endIcon="rightChevron"
        />
        <Button
          variant="primary"
          label="Button Text"
          size="small"
          disabled={true}
        />
      </div>
      <div className="showcase-label">Secondary Buttons</div>
      <div className={styles.column}>
        <Button
          variant="secondary"
          label="Icons"
          startIcon="leftChevron"
          endIcon="rightChevron"
        />
        <Button variant="secondary" label="Default" />
      </div>
      <div className={styles.column}>
        <Button variant="secondary" label="Disabled" disabled={true} />
        <Button variant="secondary" label="Button Text" size="small" />
        <Button
          variant="secondary"
          label="Button Text"
          size="small"
          startIcon={'leftChevron'}
          endIcon={'rightChevron'}
        />
        <Button
          variant="secondary"
          label="Button Text"
          size="small"
          startIcon={'leftChevron'}
        />
        <Button
          variant="secondary"
          label="Button Text"
          size="small"
          endIcon={'rightChevron'}
        />
        <Button
          variant="secondary"
          label="Button Text"
          size="small"
          disabled={true}
        />
      </div>
      <div className="showcase-label">Neutral Buttons</div>
      <div className={styles.column}>
        <Button variant="neutral" label="Button Text" />
        <Button variant="neutral" label="Button Text" disabled={true} />
        <Button
          variant="neutral"
          label="Button Text"
          startIcon={'leftChevron'}
          endIcon={'rightChevron'}
        />
        <Button
          variant="neutral"
          label="Button Text"
          startIcon={'leftChevron'}
        />
        <Button
          variant="neutral"
          label="Button Text"
          endIcon={'rightChevron'}
        />
        <Button variant="neutral" size={'small'} label="Button Text" />
        <Button
          variant="neutral"
          size={'small'}
          label="Button Text"
          disabled={true}
        />
        <Button
          variant="neutral"
          size={'small'}
          label="Button Text"
          startIcon={'leftChevron'}
          endIcon={'rightChevron'}
        />
        <Button
          variant="neutral"
          size={'small'}
          label="Button Text"
          startIcon={'leftChevron'}
        />
        <Button
          variant="neutral"
          size="small"
          label="Button Text"
          endIcon={'rightChevron'}
        />
      </div>
      <div className={styles.row}>
        <Button variant="neutral" size={'small'} label="Button Text" />
        <Button
          variant="secondary"
          size="small"
          label="Button Text"
          startIcon="edit"
        />
      </div>
      <div className="showcase-label">Add New Buttons</div>
      <div className={styles.column}>
        <Button variant="addnew" label={'Add a New Item'} />
        <Button variant="addnew" label={'Disabled'} disabled={true} />
      </div>
      <div className="showcase-label">Filter Button</div>
      <FilterButton
        label="Filter"
        enabled={filterOn}
        onClick={() => setFilterOn(!filterOn)}
      />
      <div className="showcase-label">Tabs</div>
      <Tab label="Tab" />
      <Tab label="Tab with a long label" />
      <Tab label="Disabled tab" disabled={true} />
    </div>
  );
}
