import { Block } from '../../../components/Block';
import { Breadcrumbs } from '../../../components/Breadcrumbs';
import { AccountCard } from '../../../components/Dashboard/AccountCard';
import { Loading } from '../../../components/Loading';
import { PageContainer } from '../../../components/PageContainer';
import { PagePagination } from '../../../components/PagePagination';
import { TextInput } from '../../../components/TextInput';
import {
  SubscribersMachineSender,
  SubscribersMachineState,
} from '../../../machines/Admin/SubscribersMachine';
import { ErrorPage } from '../../ErrorPage';

import styles from './Subscribers.module.scss';

interface SubscribersViewOptions {
  state: SubscribersMachineState;
  send: SubscribersMachineSender;
}

export function SubscribersView({ state, send }: SubscribersViewOptions) {
  if (state.matches('init')) {
    return <Loading />;
  }

  if (state.matches('error')) {
    return <ErrorPage />;
  }

  return (
    <PageContainer innerClassName={styles.container}>
      <div className={styles.headerRow}>
        <Breadcrumbs
          breadcrumbs={[{ text: 'Admin Dashboard', to: '/admin' }]}
        />
        <div className={styles.input}>
          <TextInput
            id="search"
            placeholder="Search by email"
            icon="menuItems"
            value={state.context.searchTerm}
            onChange={(searchTerm) =>
              send({ type: 'SET_SEARCH_TERM', searchTerm })
            }
          />
        </div>
      </div>
      <div className={styles.row}>
        <p className={styles.heading}>Subscribers</p>
      </div>
      <Block>
        {state.context.subscribers.map((account) => (
          <AccountCard
            key={account.email}
            {...account}
            type="full"
            buttonText="Remove"
            buttonVariant="warning"
            isYou={false}
            isOwner={true}
            onClick={() => send({ type: 'REMOVE', tag: account.tag })}
          />
        ))}
      </Block>
      {state.context.numberOfPages > 1 && (
        <PagePagination
          active={state.context.currentPage}
          numberOfPages={state.context.numberOfPages}
          onPageClick={(page) => send({ type: 'SET_PAGE', page })}
        />
      )}
    </PageContainer>
  );
}
