import { presignedUrlMessageResponse } from '../../schemas/presignedUrlMessageResponse';
import { Attachment } from '../../schemas/attachment';
import { match } from 'ts-pattern';

interface BaseArgs {
  referenceId: string;
  directory: string;
  fileName: string;
}

type DeleteArgs = BaseArgs;

interface UploadArgs extends BaseArgs {
  file: File;
}

const WEB_SOCKET_ENDPOINT = `${
  import.meta.env.VITE_API_WEB_SOCKET_BASE
}/websocket/attachments`;

export async function getSignedUrl(args: BaseArgs): Promise<string> {
  return new Promise((resolve, reject) => {
    const webSocket = new WebSocket(WEB_SOCKET_ENDPOINT);

    webSocket.addEventListener('open', () => {
      webSocket.send(
        JSON.stringify({
          action: 'get',
          referenceId: args.referenceId,
          directory: args.directory,
          name: args.fileName,
        })
      );
    });

    webSocket.addEventListener('message', (event) => {
      const message = presignedUrlMessageResponse.parse(
        JSON.parse(event.data as string)
      );
      match(message)
        .with({ type: 'presigned-url' }, (msg) => {
          webSocket.close();
          resolve(msg.url);
        })
        .run();
    });

    webSocket.addEventListener('error', () => {
      webSocket.close();
      reject();
    });
  });
}

export async function uploadFile(args: UploadArgs) {
  return new Promise<Attachment>((resolve, reject) => {
    const webSocket = new WebSocket(WEB_SOCKET_ENDPOINT);

    function onError() {
      webSocket.close();
      reject();
    }

    webSocket.addEventListener('open', () => {
      webSocket.send(
        JSON.stringify({
          action: 'upload',
          referenceId: args.referenceId,
          directory: args.directory,
          name: args.fileName,
        })
      );
    });

    webSocket.addEventListener('message', (event) => {
      const message = presignedUrlMessageResponse.parse(
        JSON.parse(event.data as string)
      );

      match(message)
        .with({ type: 'presigned-url' }, (msg) => {
          fetch(msg.url, {
            method: 'PUT',
            body: args.file,
          }).catch(onError);
        })
        .with({ type: 'complete' }, (msg) => {
          resolve({
            id: msg.attachmentId,
            name: args.file.name,
            size: args.file.size,
            isOwner: true,
          });
        })
        .exhaustive();
    });

    webSocket.addEventListener('error', () => {
      webSocket.close();
      reject();
    });
  });
}

export async function deleteFile(args: DeleteArgs) {
  return new Promise<Attachment>((resolve, reject) => {
    const webSocket = new WebSocket(WEB_SOCKET_ENDPOINT);

    function onError() {
      webSocket.close();
      reject();
    }

    webSocket.addEventListener('open', () => {
      webSocket.send(
        JSON.stringify({
          action: 'delete',
          referenceId: args.referenceId,
          directory: args.directory,
          name: args.fileName,
        })
      );
    });

    webSocket.addEventListener('message', (event) => {
      const message = presignedUrlMessageResponse.parse(
        JSON.parse(event.data as string)
      );

      match(message)
        .with({ type: 'presigned-url' }, (msg) => {
          fetch(msg.url, {
            method: 'DELETE',
          }).catch(onError);
        })
        .with({ type: 'complete' }, (msg) => {
          resolve({
            id: msg.attachmentId,
            name: args.fileName,
            size: 0,
            isOwner: true,
          });
        })
        .exhaustive();
    });

    webSocket.addEventListener('error', () => {
      webSocket.close();
      reject();
    });
  });
}
