import z from 'zod';
import { parseAbsolute } from '@internationalized/date';
import { serverDateTimeSchema } from '../dateTimeSchema';

export const basicApplicationLogSchema = z.object({
  logAccountId: z.string(),
  relation: z.string(),
  logTransactionDate: serverDateTimeSchema.unwrap(),
  logRole: z.string(),
  logMessage: z.string(),
  name: z
    .string()
    .nullable()
    .transform((name) => name || '[deleted]'),
  email: z
    .string()
    .nullable()
    .transform((email) => email || 'deleted'),
});

export type BasicApplicationLog = z.infer<typeof basicApplicationLogSchema>;

export const transformBasicApplicationLog =
  (timezone: string) =>
  ({ logTransactionDate, ...rest }: BasicApplicationLog) => ({
    logTransactionDate: parseAbsolute(logTransactionDate, timezone),
    ...rest,
  });

export type ApplicationLog = ReturnType<
  ReturnType<typeof transformBasicApplicationLog>
>;
