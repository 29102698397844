import { Icon } from '../Icon';

import styles from './IconShowcase.module.scss';

export function IconShowcase() {
  return (
    <div className={styles.container}>
      <div className="showcase-label">Icons</div>
      <div className={styles.icons}>
        <Icon name="home" label="dashboard" />
        <Icon name="user" label="account" />
        <Icon name="checkCircle" label="check" />
        <Icon name="infoCircle" label="info" />
        <Icon name="helpCircle" label="help" />
      </div>
    </div>
  );
}
