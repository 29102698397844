import { FundingRoundStatusType } from '../../graphql/operations';
import { HeroSection } from '../HeroSection';
import { ScheduleTable } from '../Tables/ScheduleTable';

export function HeroSectionShowcase() {
  return (
    <>
      <div className="showcase-label">Hero Section</div>
      <HeroSection
        title="Orion Program"
        breadcrumbs={[{ text: 'Home', to: 'Orion Application' }]}
        primaryButtonText="Start Orion Application"
      >
        The Orion program is a funding stream dedicated to supporting music
        creation by members of the Canadian music industry who are Black,
        Indigenous, and People of Colour.
      </HeroSection>
      <HeroSection
        title="Welcome to the Canadian Starmaker Fund"
        rightPanel={
          <ScheduleTable
            rounds={[
              {
                title: '87',
                endDate: 'May 25, 2023',
                boardMeetingDate: 'June 28, 2023',
                status: FundingRoundStatusType.Upcoming,
              },
            ]}
          />
        }
        primaryButtonText="Start Core Funding Application"
        secondaryButtonText="Start Orion Application"
      >
        The Canadian Starmaker fund provides crucial financial support to rising
        Canadian musicians, enabling them to reach new heights on the global
        stage.
      </HeroSection>
      <HeroSection
        title="Welcome to the Canadian Starmaker Fund"
        rounded={true}
        primaryButtonText="Start Core Funding Application"
        secondaryButtonText="Start Orion Application"
      >
        The Canadian Starmaker fund provides crucial financial support to rising
        Canadian musicians, enabling them to reach new heights on the global
        stage.
      </HeroSection>
    </>
  );
}
