import { ApplicationLog } from '../../../schemas/application/applicationLogSchema';
import { Accordion } from '../../Accordion';
import { Link } from '../../Link';
import { formatZonedDateTime } from '../../../utils/formatZonedDateTime';

import styles from './ApplicationLogs.module.scss';

interface Props {
  logs: ApplicationLog[];
}

export function ApplicationLogs({ logs }: Props) {
  if (!logs.length) {
    return null;
  }

  const [first] = logs;

  return (
    <div className={styles.container}>
      <Accordion
        id="logs"
        className={styles.accordian}
        forceOpen={false}
        title={`Last updated by ${first.name} ${formatZonedDateTime(
          first.logTransactionDate
        )}`}
      >
        <ul>
          {logs.map((log, index) => (
            <li key={index}>
              <Link href={`/account/${log.logAccountId}`}>{log.name}</Link>{' '}
              <span className={styles.email}>{`<${log.email}>`}</span>
              <p>{formatZonedDateTime(log.logTransactionDate)}</p>
            </li>
          ))}
        </ul>
      </Accordion>
    </div>
  );
}
