import { useGraphQLClient } from '../../../components/GraphQLClientProvider';
import { AdminDashboardController } from './AdminDashboardController';
import { createGetAdminDashboardContextPromise } from '../../../promises/Admin/createGetAdminDashboardContext';
import { machine as DirectDepositDownloadMachine } from '../../../machines/Admin/DirectDepositDownloadMachine';
import { withDelay } from '../../../utils/withDelay';
import { createGetDirectDepositExportRowsPromise } from '../../../promises/Admin/createGetDirectDepositExportRows';

export const AdminDashboard = () => {
  const client = useGraphQLClient();

  return (
    <AdminDashboardController
      options={{
        services: {
          getAdminDashboardContextPromise: withDelay(
            createGetAdminDashboardContextPromise(client)
          ),
          directDepositExportRowMachine:
            DirectDepositDownloadMachine.withConfig({
              services: {
                getDirectDepositExportRows:
                  createGetDirectDepositExportRowsPromise(client),
              },
            }),
        },
      }}
    />
  );
};
