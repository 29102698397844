import { Icon, IconName } from './Icon';
import classNames from 'classnames';

import styles from './ProgressIcon.module.scss';

type Status = 'active' | 'completed' | 'disabled' | 'waiting';

interface ProgressIconProps {
  icon: IconName;
  status?: Status;
}

export function ProgressIcon({ icon, status = 'active' }: ProgressIconProps) {
  const circleClassName = classNames(styles.circle, {
    [styles.active]: status === 'active',
    [styles.completed]: status === 'completed',
    [styles.disabled]: status === 'disabled',
    [styles.waiting]: status === 'waiting',
  });

  return (
    <div className={styles[status]}>
      <div className={circleClassName}>
        <Icon name={icon} className={styles.icon} />
      </div>
    </div>
  );
}
