import { match } from 'ts-pattern';
import cn from 'classnames';
import { formatDate } from '../../utils/formatDate';
import { Block } from '../../components/Block';
import { Breadcrumbs } from '../../components/Breadcrumbs';
import { Feedback } from '../../components/Feedback';
import { GrayBox } from '../../components/GrayBox';
import { Sidebar } from '../../components/Sidebar';
import { ReleaseDetailsController } from '../../components/Release/ReleaseDetails/ReleaseDetailsController';
import { SalesController } from '../../components/Release/Sales/SalesController';
import { ReleaseStatusBadge } from '../../components/Release/ReleaseStatusBadge';
import { ReleaseDetailsMachineActor } from '../../machines/Release/ReleaseDetailsMachine';
import { ReleaseSalesMachineActor } from '../../machines/Release/ReleaseSalesMachine';
import {
  ReleaseMachineSender,
  ReleaseMachineState,
} from '../../machines/Release/ReleaseMachine';
import { ReleaseMasterOwnershipMachineActor } from '../../machines/Release/ReleaseMasterOwnershipMachine';
import { ReleaseMasterOwnershipController } from '../../components/Release/ReleaseMasterOwnership/ReleaseMasterOwnershipController';
import { Button } from '../../components/Button';
import { SinglesController } from '../../components/Release/Singles/SinglesController';
import { ReleaseSinglesMachineActor } from '../../machines/Release/ReleaseSinglesMachine';
import { formatNumber } from '../../utils/formatNumber';
import { useIsAdmin } from '../../hooks/useIsAdmin';
import { Link } from '../../components/Link';
import { Loading } from '../../components/Loading';
import { ErrorPage } from '../ErrorPage';
import { NotFound } from '../NotFound';

import styles from './ReleaseView.module.scss';

interface ReleaseViewProps {
  state: ReleaseMachineState;
  send: ReleaseMachineSender;
  artistId: string;
}

export function ReleaseView({ state, send, artistId }: ReleaseViewProps) {
  const { release, artist, eligibilityCutoffDate } = state.context;
  const isAdmin = useIsAdmin();
  const canEditReleases = state.context.release?.isOwner || isAdmin;

  if (
    state.matches('loadingRelease') ||
    state.matches('releaseDeleted') ||
    state.matches('deletingRelease')
  ) {
    return <Loading />;
  }

  if (state.matches('loadingError') || state.matches('deleteError')) {
    return <ErrorPage />;
  }

  if (state.matches('notFound') || !release || !artist) {
    return <NotFound />;
  }

  // TODO: We could make these error messages more dynamic. We can
  // query the track and minute limit from the DB so we can build
  // the messages with those values instead of hard coding them.
  const ineligibleReasons = [
    ...(eligibilityCutoffDate && !release.meetsDateRequirement
      ? [
          `The release date must be on or after ${formatDate(
            eligibilityCutoffDate
          )}.`,
        ]
      : []),
    ...(!release.meetsMinutesRequirement
      ? ['Releases must be at least 9 minutes in length.']
      : []),
    ...(!release.meetsTracksRequirement
      ? ['Releases must have 3 or more tracks.']
      : []),
    ...(!release.meetsSalesRequirement
      ? ['Releases must meet the sales requirement for their genre.']
      : []),
    ...(!release.inCanada ? ['Releases must be made in Canada.'] : []),
    ...(!release.isMajorityEnglish
      ? [
          'The majority of the tracks included on your album must not be in the French language.',
        ]
      : []),
  ];

  return (
    <div className={styles.container}>
      <Breadcrumbs
        breadcrumbs={[
          ...(isAdmin
            ? [
                {
                  text: 'All Artists',
                  to: `/admin/artists`,
                },
              ]
            : []),
          {
            text: isAdmin ? 'Artist Dashboard' : 'Dashboard',
            to: `/artist/${artistId}`,
          },
          {
            text: 'All Releases',
            to: `/artist/${artistId}/releases`,
          },
        ]}
      />
      <h1 className={styles.heading}>
        {release.title} <span className="text-normal">release for</span>{' '}
        {artist.name}
      </h1>
      <div className={styles.innerContainer}>
        <Sidebar
          links={[
            { title: 'Release Details', href: '#release-details' },
            { title: 'Sales', href: '#sales' },
            { title: 'Master Ownership', href: '#master-ownership' },
            { title: 'Singles', href: '#singles' },
          ]}
        >
          <ReleaseStatusBadge
            className={styles.badge}
            type={
              release.isRestricted
                ? 'Restricted'
                : release.isEligible
                ? 'Eligible'
                : 'Ineligible'
            }
          />
        </Sidebar>
        <div className={styles.content}>
          <div className={styles.feedback}>
            {match(release)
              .with({ isRestricted: true }, () => (
                <Feedback
                  type="neutral"
                  title="This release is tied to submitted application, edits are limited."
                >
                  <p>
                    As part of the application process, the releases associated
                    with your submitted application are locked.
                  </p>
                  <p>
                    You can still update sales numbers and manage singles for
                    this release.
                  </p>
                </Feedback>
              ))
              .with({ isEligible: true }, () => (
                <Feedback
                  type="positive"
                  title="This release qualifies for funding."
                />
              ))
              .otherwise(() => (
                <Feedback type="warning" title="This release is NOT eligible.">
                  {ineligibleReasons.length === 1 ? (
                    <p>{ineligibleReasons[0]}</p>
                  ) : (
                    <div>
                      <ul>
                        {ineligibleReasons.map((reason) => (
                          <li key={reason}>{reason}</li>
                        ))}
                      </ul>
                    </div>
                  )}

                  <p>
                    Learn more about the{' '}
                    <Link type="feedback" href="/requirements">
                      requirements and rules
                    </Link>
                    .{' '}
                  </p>
                </Feedback>
              ))}
          </div>
          <Block
            id="release-details"
            headerText="Release Details"
            headerButtonText="Edit"
            iconName="list"
            headerButtonOnClick={() => send('EDIT_RELEASE_DETAILS')}
            hideHeaderButton={
              state.matches('ready.releaseDetails.open') || !canEditReleases
            }
            headerButtonDisabled={Boolean(release.isRestricted)}
          >
            {state.matches('ready.releaseDetails.open') ? (
              <ReleaseDetailsController
                actor={
                  state.children
                    .releaseDetailsMachine as ReleaseDetailsMachineActor
                }
              />
            ) : (
              <GrayBox>
                <dl className={styles.detailsGrid}>
                  <div>
                    <dt>Release title: </dt>
                    <dd>{release.title}</dd>
                  </div>
                  <div>
                    <dt>Label: </dt>
                    <dd>{release.otherLabel ?? release.label?.name}</dd>
                  </div>
                  <div>
                    <dt>Distributor: </dt>
                    <dd>
                      {release.otherDistributor ?? release.distributor?.name}
                    </dd>
                  </div>
                  <div>
                    <dt>Release date: </dt>
                    <dd
                      className={cn({
                        [styles.red]: !release.meetsDateRequirement,
                      })}
                    >
                      {release.date}
                    </dd>
                  </div>
                  <div>
                    <dt>Length in minutes: </dt>
                    <dd
                      className={cn({
                        [styles.red]: !release.meetsMinutesRequirement,
                      })}
                    >
                      {release.numberOfMinutes}
                    </dd>
                  </div>
                  <div>
                    <dt>Number of tracks: </dt>
                    <dd
                      className={cn({
                        [styles.red]: !release.meetsTracksRequirement,
                      })}
                    >
                      {release.numberOfTracks}
                    </dd>
                  </div>
                </dl>
              </GrayBox>
            )}
          </Block>
          <Block
            id="sales"
            headerText="Sales"
            headerButtonText="Edit"
            iconName="dollarSign"
            headerButtonOnClick={() => send('EDIT_SALES')}
            hideHeaderButton={
              state.matches('ready.releaseSales.open') || !canEditReleases
            }
          >
            {state.matches('ready.releaseSales.open') ? (
              <SalesController
                actor={
                  state.children.releaseSalesMachine as ReleaseSalesMachineActor
                }
              />
            ) : (
              <GrayBox>
                <dl className={styles.detailsGrid}>
                  <div>
                    <dt>Genre: </dt>
                    <dd>{release.genre?.title}</dd>
                  </div>
                  <div>
                    <dt>Scanned releases: </dt>
                    <dd>
                      {formatNumber(release.sales.at(0)?.releasesScanned ?? 0)}
                    </dd>
                  </div>
                  <div>
                    <dt>Downloaded tracks: </dt>
                    <dd>
                      {formatNumber(release.sales.at(0)?.tracksDownloaded ?? 0)}
                    </dd>
                  </div>
                  <div>
                    <dt>Streamed tracks: </dt>
                    <dd>
                      {formatNumber(release.sales.at(0)?.tracksStreamed ?? 0)}
                    </dd>
                  </div>
                  <div>
                    <dt>Total units: </dt>
                    <dd
                      className={cn({
                        [styles.red]: !release.meetsSalesRequirement,
                      })}
                    >
                      {formatNumber(release.sales.at(0)?.totalUnits ?? 0)}
                    </dd>
                  </div>
                </dl>
              </GrayBox>
            )}
          </Block>
          <Block
            id="master-ownership"
            headerText="Master Ownership"
            headerButtonText="Edit"
            iconName="document"
            headerButtonOnClick={() => send('EDIT_RELEASE_MASTER_OWNERSHIP')}
            hideHeaderButton={
              state.matches('ready.releaseMasterOwnership.open') ||
              !canEditReleases
            }
            headerButtonDisabled={Boolean(release.isRestricted)}
          >
            {state.matches('ready.releaseMasterOwnership.open') ? (
              <ReleaseMasterOwnershipController
                actor={
                  state.children
                    .releaseMasterOwnershipMachine as ReleaseMasterOwnershipMachineActor
                }
              />
            ) : (
              <GrayBox>
                <dl className={styles.detailsGrid}>
                  <div>
                    <dt>Company: </dt>
                    <dd>{release.company}</dd>
                  </div>
                  <div>
                    <dt>Contact person: </dt>
                    <dd>{release.contact}</dd>
                  </div>
                  <div>
                    <dt>Address: </dt>
                    <dd>{release.address}</dd>
                  </div>
                  <div>
                    <dt>Country: </dt>
                    <dd
                      className={cn({
                        [styles.red]:
                          ineligibleReasons.includes('invalid-country'),
                      })}
                    >
                      {release.inCanada ? 'Canada' : 'Outside of Canada'}
                    </dd>
                  </div>

                  <div>
                    <dt>Phone number: </dt>
                    <dd>{release.phoneNumber}</dd>
                  </div>
                  <div>
                    <dt>Email: </dt>
                    <dd>{release.email}</dd>
                  </div>
                </dl>
                {release.notes && (
                  <dl className={styles.notes}>
                    <dt>Additional Notes: </dt>
                    <dd>{release.notes}</dd>
                  </dl>
                )}
              </GrayBox>
            )}
          </Block>
          <Block id="singles" headerText="Singles" iconName="music">
            <SinglesController
              actor={
                state.children
                  .releaseSinglesMachine as ReleaseSinglesMachineActor
              }
            />
          </Block>
          {canEditReleases && (
            <div className={styles.removeRelease}>
              <Button
                label="Remove Release"
                disabled={Boolean(release.isRestricted)}
                size="small"
                variant="warning"
                startIcon="trash"
                onClick={() => {
                  if (
                    window.confirm('Do you really want to remove this release?')
                  ) {
                    send('ATTEMPT_DELETE_RELEASE');
                  }
                }}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
